import { PageRequest } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { LimitedSchemaInfiniteQueryOptions, LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { GetFormListIO, GetFormListV2IO, GetFormListV3IO, GetFormDetailsIO } from '../../types';
import { defaultOptions } from '../config';
import { requestKeys } from '../keys';

/**
 * @param companyId - AKA locationId
 */
export const useFormList = <E = unknown, T = GetFormListIO['output']>(
  req: Compulsory<GetFormListIO['input'], 'companyId'>,
  options?: LimitedSchemaInfiniteQueryOptions<GetFormListIO, E, T>
) => {
  const queryKey = requestKeys.formList(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: string }) =>
      SchemaDigitalForms.GetFormList(
        { ...req, page: pageParam || req.page },
        { headers: { 'Location-Id': req.companyId } }
      ),
    getNextPageParam: (lastPage: GetFormListIO['output']) => lastPage.pageMetadata?.page,
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * @param companyIds - AKA locationIds
 * @deprecated Use `useFormListV3` instead
 */
export const useFormListV2 = <E = unknown, T = GetFormListV2IO['output']>(
  req: Compulsory<GetFormListV2IO['input'], 'companyIds' | 'pageRequest'>,
  options?: LimitedSchemaInfiniteQueryOptions<GetFormListV2IO, E, T>
) => {
  const queryKey = requestKeys.formListV2(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: PageRequest }) =>
      SchemaDigitalForms.GetForms({
        ...req,
        pageRequest: pageParam || req.pageRequest,
      }),
    getNextPageParam: (lastPage): PageRequest | undefined => {
      const page = lastPage.pageMetadata?.page;
      return {
        pageNumber: page ? parseInt(page, 10) : undefined,
        pageLimit: req.pageRequest.pageLimit,
      };
    },
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Fetches the list of forms in the organization.
 */
export const useFormListV3 = <E = unknown, T = GetFormListV3IO['output']>(
  req: Compulsory<GetFormListV3IO['input'], 'orgRequest' | 'pageRequest'>,
  options?: LimitedSchemaInfiniteQueryOptions<GetFormListV3IO, E, T>
) => {
  const queryKey = requestKeys.formListV3(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: PageRequest }) =>
      SchemaDigitalForms.GetFormsV3({ ...req, pageRequest: pageParam || req.pageRequest }),
    getNextPageParam: (lastPage): PageRequest | undefined => {
      const page = lastPage.pageMetadata?.page;
      return {
        pageNumber: page ? parseInt(page, 10) : undefined,
        pageLimit: req.pageRequest.pageLimit,
      };
    },
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * Fetches the details of a form.
 */
export const useGetFormDetails = <E = unknown, T = GetFormDetailsIO['output']>(
  req: Compulsory<GetFormDetailsIO['input'], 'formId' | 'orgId'>,
  options?: LimitedSchemaQueryOptions<GetFormDetailsIO, E, T>
) => {
  const queryKey = requestKeys.formDetails(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetFormDetails(req),
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};
