import { GetSoftphoneSettingsRequest } from '@weave/schema-gen-ts/dist/schemas/phone/softphones/softphones.pb';
import { UseQueryOptions } from 'react-query';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { getCurrentUserSoftphoneData, getUserSoftphone } from './api';

const queryKeys = {
  getUserSoftphone: (userId: string) => ['softphone', userId],
  getCurrentUserSoftphone: ['softphone', 'current'],
};

export const useGetUserSoftphoneQuery = (
  userId: string,
  opts?: UseQueryOptions<Awaited<ReturnType<typeof getUserSoftphone>>>
) =>
  useLocalizedQuery({
    queryKey: queryKeys.getUserSoftphone(userId),
    queryFn: () => getUserSoftphone(userId),
    ...opts,
  });

export const useGetCurrentUserSoftphoneQuery = ({
  req,
  opts,
}: {
  req?: GetSoftphoneSettingsRequest | undefined;
  opts?: UseQueryOptions<Awaited<ReturnType<typeof getCurrentUserSoftphoneData>>>;
}) =>
  useLocalizedQuery({
    queryKey: queryKeys.getCurrentUserSoftphone,
    queryFn: () => getCurrentUserSoftphoneData({ req }),
    ...opts,
  });
