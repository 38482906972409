import { useEffect, useRef, useState } from 'react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { Direction } from '@weave/schema-gen-ts/dist/shared/sms/v1/enums.pb';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// PrintDialog doesn't work for this case, since pre-loading images is not supported
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useDropzone } from 'react-dropzone';
import { useReactToPrint } from 'react-to-print';
import { DepartmentsQueries } from '@frontend/api-departments';
import { MediaApi } from '@frontend/api-media';
import { MessagesHooks, SchemaSMSSharedModels, TextWritebacksQueries, MessagesTypes } from '@frontend/api-messaging';
import { PersonsV3 } from '@frontend/api-person';
import { UsersQueries } from '@frontend/api-users';
import { BulkListProvider } from '@frontend/bulk-list-provider';
import { useTranslation } from '@frontend/i18n';
import { InboxThreadRoute, InboxType, useInboxNavigate } from '@frontend/inbox-navigation';
import { useThreadMedia } from '@frontend/integrated-messaging';
import { useMessagePopupBarManager } from '@frontend/popup-bar';
import { SchemaSMSService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { ThreadBodyComponents, ThreadBodyTypes } from '@frontend/thread-body';
import { ContentLoader, SpinningLoader, useModalControl, useAlert } from '@frontend/design-system';
import { CONVERSATION_IMG_ID_PREFIX, EXPORT_IMG_ID_PREFIX, TEMP_WRITEBACKS_RELATED_ID } from '../../constants';
import { BulkSelectionActionType } from '../../types';
import { reducePmsOptions } from '../../utils';
import { OptOutBanner } from './body/opt-out-banner';
import { ThreadViewBody } from './body/thread-view-body';
import { ExportConversationActions, TextWritebacksActions } from './bulk-actions';
import { ConversationExport } from './conversation-export';
import { InboxThreadHeader } from './headers/inbox-thread-header';
import { ThreadViewSendingArea } from './sending-area/thread-view-sending-area';
import { TextWritebacksModal } from './text-writebacks-modal';

dayjs.extend(relativeTime);

const FULL_CONVERSATION_LIMIT = 2000;

export const ThreadView = ({
  filter,
  groupId,
  threadId,
  personId,
  personPhone: providedPersonPhone,
  departmentId: providedDepartmentId,
  smsId,
  smsCreatedAt,
  click,
  isNew,
  isArchived: isArchivedSearch,
}: InboxThreadRoute) => {
  const { accessibleLocationData, getLocationName, selectedLocationIds } = useAppScopeStore();
  const locationData = accessibleLocationData[groupId];
  const isParentLocation = (locationData?.children ?? []).length > 0 && locationData?.parentId !== groupId;
  const isMultiLocation = (accessibleLocationData[locationData?.parentId ?? groupId]?.children ?? []).length > 1;
  const { data: locationUsers } = UsersQueries.useGetUsers({
    params: isParentLocation && isMultiLocation ? { showChildren: true } : {},
  });
  const { closeThread } = useInboxNavigate();
  const isArchived = filter === InboxType.ARCHIVED ? isArchivedSearch !== false : !!isArchivedSearch;
  const { removePopup } = useMessagePopupBarManager();
  const [scheduledMessageForEdit, setScheduledMessageForEdit] = useState<ScheduledSms>();
  const [hideSendingArea, setHideSendingArea] = useState<boolean>(false);

  useEffect(() => {
    setHideSendingArea(false);
  }, [threadId]);

  const focusedSms: MessagesTypes.ThreadFocusedSmsData | undefined =
    smsId && smsCreatedAt
      ? {
          id: smsId,
          createdAt: smsCreatedAt,
        }
      : undefined;
  const {
    messages,
    isLoadingFirstPage,
    isFetching,
    hasOlderMessages,
    fetchOlderMessages,
    hasNewerMessages,
    fetchNewerMessages,
    scheduledMessages,
    metadata,
    smsPreference,
    personPhone,
    mediaQueries,
  } = MessagesHooks.useThread({
    threadId: threadId ?? '',
    groupId,
    providedPersonPhone,
    focusedSms,
    isNew,
  });
  const departmentId = metadata?.departmentId || providedDepartmentId;
  const departmentsQuery = DepartmentsQueries.useListDefaultSMSQuery({ locationId: groupId });
  const resolvedPersonId = personId || metadata?.person?.personId;
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: resolvedPersonId ?? '',
      locationIds: [groupId],
    },
    {
      enabled: !!resolvedPersonId,
    }
  );
  const { t } = useTranslation('messages');
  const alert = useAlert();
  const optedOut = smsPreference?.consented === false;
  const [shouldHideMessage, setShouldHideMessage] = useState<boolean>(
    !!metadata?.isBlocked || filter === InboxType.BLOCKED
  );
  const [bulkSelectionType, setBulkSelectionType] = useState<BulkSelectionActionType>();
  const [newWritebackMessages, setNewWritebackMessages] = useState<{ actionId: string; smsIds: string[] }[]>([]);
  const exportableComponentRef = useRef(null);
  const [exportMessage, setExportMessage] = useState<string>();
  let exportFullConversation = false;
  const [fullConversation, setFullConversation] = useState<SchemaSMSSharedModels.SMS[]>();
  const [downloadedMedia, setDownloadedMedia] = useState<Record<string, string>>();
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const locationPhone =
    departmentsQuery.data?.smsNumbers?.find((dept) => dept.id === departmentId)?.smsNumber?.number ||
    messages[0]?.locationPhone ||
    '';

  const isLastMessageInbound = messages.at(-1)?.direction === Direction.DIRECTION_INBOUND;

  const handleExport = useReactToPrint({
    onBeforeGetContent: async () => {
      setExportMessage(t('Preparing export...'));
      const localConversation: SchemaSMSSharedModels.SMS[] = [];
      if (exportFullConversation) {
        setExportMessage(t('Loading your entire message history. This may take a moment...'));
        let hasAllPages = !focusedSms && !hasOlderMessages;
        if (hasAllPages && messages) {
          localConversation.push(...messages);
        }
        while (!hasAllPages) {
          const nextPage = await SchemaSMSService.GetThread({
            locationId: groupId,
            threadId,
            messageLimit: FULL_CONVERSATION_LIMIT,
            messageSkip: localConversation.length,
          });
          localConversation.push(...nextPage.thread.messages);
          if (nextPage.thread.messages.length < FULL_CONVERSATION_LIMIT) hasAllPages = true;
        }
        setFullConversation(localConversation);
      } else if (messages) {
        localConversation.push(...messages);
      }
      const mediaIds = localConversation
        .filter((message) => message.numMedia > 0)
        .flatMap((message) => message.media.map((mediaItem) => mediaItem.mediaId));

      if (mediaIds.length > 0) {
        setExportMessage(t('Loading images...'));

        // Use image sources for any already downloaded images before fetching new images
        // 1. Select all image elements in DOM
        const currentlyLoadedImagesNodes = document.querySelectorAll('img');
        const imagesSrcMap: Record<string, string> = {};
        // 2. If image element is in conversation, not export component, populate imgSrcMap with src attribute and mediaId from that element
        currentlyLoadedImagesNodes.forEach((node) => {
          const id = node.getAttribute('id');
          if (!id || id.includes(EXPORT_IMG_ID_PREFIX)) return;
          const mediaId = id.replace(CONVERSATION_IMG_ID_PREFIX, '');
          const imgSrc = node.getAttribute('src');
          if (imgSrc) {
            imagesSrcMap[mediaId] = imgSrc;
          }
        });
        // 3. Filter mediaIds array to remove already downloaded images
        const mediaIdsForDownload = mediaIds.filter((mediaId) => !Object.keys(imagesSrcMap).includes(mediaId));
        // 4. Download any media that is not already downloaded and add to imageSrcMap
        const downloadedSrcsMap = await MediaApi.getMmsMedia(mediaIdsForDownload);
        Object.keys(downloadedSrcsMap).forEach((key) => {
          const newVal = downloadedSrcsMap[key];
          if (newVal) imagesSrcMap[key] = newVal;
        });
        setDownloadedMedia(imagesSrcMap);
      }
      setExportMessage(t('Finishing up...'));
      return Promise.resolve();
    },
    onAfterPrint: () => {
      setFullConversation(undefined);
      setExportMessage(undefined);
      setDownloadedMedia(undefined);
    },
    onPrintError: () => {
      setFullConversation(undefined);
      setExportMessage(undefined);
      setDownloadedMedia(undefined);
    },
    content: () => exportableComponentRef.current,
    copyStyles: true,
  });

  const imageUploadModalControl = useModalControl();

  const { threadMedia, uploadFiles, clearMedia, removeMediaItem } = useThreadMedia({
    threadId,
    groupId,
    departmentId: departmentId ?? '',
    personPhone: personPhone ?? '',
    locationPhone,
    maxFileCount: 10,
    updateDraft: !scheduledMessageForEdit,
    onError: () => {
      alert.error(t('Error uploading image. Please try again.'));
    },
    onExceedMaxFileCount: (excessCount) => {
      alert.error(
        t('You can only send up to 10 images in a single message. {{count}} images were not uploaded.', {
          count: excessCount,
        })
      );
    },
    mediaIds: scheduledMessageForEdit?.mediaIds,
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'image/jpg': ['.jpg', '.jpeg'], 'image/png': [] },
    onDrop: (files: File[]) => {
      if (imageUploadModalControl.modalProps.show) return;
      uploadFiles(files);
    },
    noClick: true,
  });

  const pmsPersonsModalControl = useModalControl();
  const textWritebacksEnabledQuery = TextWritebacksQueries.useTextWritebacksEnabled({ locationId: groupId });
  const textWritebacksPmsPersonsQuery = TextWritebacksQueries.useListPmsPersons(
    { personPhone: personPhone ?? '', groupId },
    {
      enabled: !!personPhone && !!textWritebacksEnabledQuery.data?.canSmsWriteback,
    }
  );
  const { pmsOptions, personPetPayloadMap } = reducePmsOptions(textWritebacksPmsPersonsQuery.data?.pmsPersons);
  const handleNewWritebacks = (smsIds: string[], writebackTimestamp: string) => {
    if (messages.length)
      messages.forEach((message) => {
        if (smsIds.includes(message.id)) message.relatedIds.push(TEMP_WRITEBACKS_RELATED_ID);
      });

    const actionId = writebackTimestamp || new Date().toISOString();

    setNewWritebackMessages((prev) => [...prev, { actionId, smsIds }]);
    setTimeout(() => {
      setNewWritebackMessages((prev) => prev.filter((action) => action.actionId !== actionId));
    }, 30000);
  };

  useEffect(() => {
    if (!selectedLocationIds.includes(groupId)) closeThread();
  }, [selectedLocationIds]);

  useEffect(() => {
    if (!!selectedLocationIds.length && !selectedLocationIds.includes(groupId)) {
      closeThread();
    }
  }, [selectedLocationIds]);

  useEffect(() => {
    if (threadId) removePopup(threadId);
  }, [threadId]);

  const manageSendingArea = (type: ThreadBodyTypes.onScheduleMessageActionType, msg?: ScheduledSms): void => {
    if (type === 'view_list') {
      setScheduledMessageForEdit(undefined);
      setHideSendingArea(true);
    } else if (type === 'close') {
      setHideSendingArea(false);
    } else if (type === 'view_msg') {
      setHideSendingArea(false);
      setTimeout(() => {
        setScheduledMessageForEdit(msg);
      }, 100);
    }
  };

  if (!departmentsQuery.isError && departmentsQuery.isLoading) {
    return (
      <div css={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <SpinningLoader />
      </div>
    );
  }
  return (
    <BulkListProvider
      itemIds={messages.map((message) => message.id) ?? []}
      onClose={() => setBulkSelectionType(undefined)}
      onSelectAllChange={(newVal) => {
        if (exportFullConversation !== newVal) exportFullConversation = newVal;
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }} {...getRootProps()}>
        <ContentLoader message={exportMessage} show={!!exportMessage} size='xl' />
        <InboxThreadHeader
          key={threadId}
          groupId={groupId}
          threadId={threadId}
          personPhone={personPhone ?? providedPersonPhone ?? ''}
          personId={personId}
          departmentId={metadata?.departmentId || providedDepartmentId}
          isArchived={isArchived}
          isBlocked={!!metadata?.isBlocked}
          isNew={isNew || messages.length === 0}
          bulkSelectionType={bulkSelectionType}
          setBulkSelectionType={setBulkSelectionType}
          canSmsWriteback={!!textWritebacksEnabledQuery.data?.canSmsWriteback}
          targetSmsData={
            smsId && smsCreatedAt
              ? {
                  id: smsId,
                  createdAt: smsCreatedAt,
                }
              : undefined
          }
        />
        <ThreadViewBody
          isLoadingFirstPage={isLoadingFirstPage}
          hasNextPage={hasOlderMessages}
          loadNextPage={fetchOlderMessages}
          hasPreviousPage={hasNewerMessages}
          loadPreviousPage={fetchNewerMessages}
          shouldHideMessage={shouldHideMessage}
          setShouldHideMessage={setShouldHideMessage}
          threadId={threadId}
          threadMessages={messages}
          newWritebackMessages={newWritebackMessages.flatMap((action) => action.smsIds)}
          optedOut={optedOut}
          setScrollbarWidth={setScrollbarWidth}
          groupId={groupId}
          scheduledMessages={scheduledMessages}
          setScheduledMessageForEdit={setScheduledMessageForEdit}
          smsIdToScrollTo={smsId}
          threadIsLoading={isLoadingFirstPage}
          threadIsFetching={isFetching}
          smsIdToScrollToClickCount={click}
          selectionType={bulkSelectionType}
          mediaQueries={mediaQueries}
          personPhone={personPhone ?? providedPersonPhone ?? ''}
          personId={personId}
        />
        <ThreadBodyComponents.ScheduledMessageIndicator
          scheduledMessages={scheduledMessages}
          inEdit={scheduledMessageForEdit}
          threadId={threadId ?? ''}
          onAction={manageSendingArea}
        />
        {optedOut && <OptOutBanner />}
        {bulkSelectionType === BulkSelectionActionType.EXPORT_CONVERSATION ? (
          <ExportConversationActions onCancel={() => setBulkSelectionType(undefined)} onExport={() => handleExport()} />
        ) : bulkSelectionType === BulkSelectionActionType.TEXT_WRITEBACK ? (
          <TextWritebacksActions
            onCancel={() => setBulkSelectionType(undefined)}
            onSend={() => pmsPersonsModalControl.openModal()}
          />
        ) : !hideSendingArea ? (
          <ThreadViewSendingArea
            personPhone={personPhone ?? ''}
            groupId={groupId}
            threadId={threadId ?? ''}
            departmentId={departmentId}
            person={personQuery.data}
            isDragActive={isDragActive}
            imageUploadModalControl={imageUploadModalControl}
            disabled={optedOut}
            scrollbarWidth={scrollbarWidth}
            personId={metadata?.person?.personId ?? personId}
            scheduledMessageForEdit={scheduledMessageForEdit}
            clearScheduledMessageForEdit={() => {
              setScheduledMessageForEdit(undefined);
            }}
            taggedSmsId={smsId}
            isNew={isNew || messages.length === 0}
            threadMedia={threadMedia}
            uploadFiles={uploadFiles}
            clearMedia={clearMedia}
            removeMediaItem={removeMediaItem}
            locationPhone={locationPhone}
            isLastMessageInbound={isLastMessageInbound}
            inboxType={filter}
          />
        ) : null}
        <input {...getInputProps()} />
      </div>
      {(bulkSelectionType !== undefined || !!exportMessage) && (
        <div css={{ display: 'none' }}>
          <ConversationExport
            ref={exportableComponentRef}
            conversation={(fullConversation || messages) ?? []}
            locationName={getLocationName(groupId)}
            locationUsers={locationUsers ?? []}
            downloadedMedia={downloadedMedia ?? {}}
            personName={personQuery.data ? PersonsV3.PersonHelpers.getFullName(personQuery.data) : undefined}
          />
        </div>
      )}
      {textWritebacksPmsPersonsQuery.data && textWritebacksPmsPersonsQuery.data.pmsPersons.length > 0 && (
        <TextWritebacksModal
          groupId={groupId}
          pmsOptions={pmsOptions}
          payloadMap={personPetPayloadMap}
          onWriteback={handleNewWritebacks}
          {...pmsPersonsModalControl.modalProps}
        />
      )}
    </BulkListProvider>
  );
};
