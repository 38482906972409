import { ReactNode, useEffect, useRef } from 'react';
import { Address } from '@weave/schema-gen-ts/dist/schemas/phone/address/v1/address_service.pb';
import mitt, { Handler } from 'mitt';
import { createContext, useContextSelector } from 'use-context-selector';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { DialChar } from '@frontend/generic-dialpad-accessories';
import { ObjectEntries } from '@frontend/types';
import {
  AnyCall,
  EstablishedCall,
  IncomingCall,
  MergedCallGroup,
  OutgoingCall,
  RemoteParty,
  TerminatedCall,
} from '../types';
import { AudioEvents, CallInfoEvents, FeedbackEvents } from './softphone-metrics-provider';

type CallIdType = {
  callId: CallInfoEvents['callID'];
};

type Events = {
  'setup.autoprovision_finish': null;

  'registration.registered': Partial<CallIdType>;
  'registration.unregistered': Partial<CallIdType>;
  'registration.failure': CallIdType; //error message
  'registration.not_renewed': CallIdType; //seconds elapsed since last registration

  'do-not-disturb.enabled': null;
  'do-not-disturb.disabled': null;

  'incoming-call.received': IncomingCall;
  'incoming-call.answered': EstablishedCall;
  'incoming-call.rejected': IncomingCall;
  'incoming-call.missed': IncomingCall;
  'incoming-call.completed-elsewhere': IncomingCall;
  'incoming-call.answer-failed': string;
  'incoming-call.remote.canceled': IncomingCall;

  'outgoing-call.sent': OutgoingCall;
  'outgoing-call.answered': EstablishedCall;
  'outgoing-call.rejected': OutgoingCall;
  'outgoing-call.canceled': OutgoingCall;
  'outgoing-call.failed': null;

  'active-call.hold': EstablishedCall;
  'active-call.unhold': EstablishedCall;
  'active-call.terminated': EstablishedCall;
  'active-call.muted': EstablishedCall;
  'active-call.unmuted': EstablishedCall;
  'active-call.sent-dtmf': { call: EstablishedCall; key: DialChar };
  'active-call.parked': { call: EstablishedCall; slot: SoftphoneTypes.ParkSlot };
  'active-call.park-failed': { call: EstablishedCall; slot: SoftphoneTypes.ParkSlot; message: string };

  'transfer-call.initiated': { call: EstablishedCall };
  'blind-transfer.sent': { call: EstablishedCall };
  'blind-call.transferred': { call: EstablishedCall };
  'blind-transfer.failed': { call: EstablishedCall; message: string };

  'attended-transfer.third-party.invitation.started': { call: EstablishedCall; thirdParty: OutgoingCall };
  'attended-transfer.third-party.invitation.sent': { call: EstablishedCall; thirdParty: OutgoingCall };
  'attended-transfer.third-party.invitation.answered': { call: EstablishedCall; thirdParty: OutgoingCall };
  'attended-transfer.third-party.invitation.missed': { call: EstablishedCall; thirdParty: OutgoingCall };
  'attended-transfer.third-party.invitation.rejected': { call: EstablishedCall; thirdParty: OutgoingCall };
  'attended-transfer.third-party.invitation.cancelled': { call: EstablishedCall; thirdParty: OutgoingCall };
  'attended-transfer.third-party.invitation.failed': { call: EstablishedCall };

  'attended-transfer.after-established.merged': { call: EstablishedCall; thirdParty: EstablishedCall };
  'attended-transfer.after-established.hangup': { call: EstablishedCall; thirdParty: EstablishedCall };
  'attended-transfer.after-established.transferred': { call: EstablishedCall; thirdParty: EstablishedCall };

  'conference-call.merged': EstablishedCall[];
  'conference-call.ended': EstablishedCall[];
  'conference-call.call-removed': { call: EstablishedCall[]; removed: EstablishedCall };

  'park-slot.occupied': { slot: SoftphoneTypes.ParkSlot; remoteParty: RemoteParty };
  'park-slot.vacated': SoftphoneTypes.ParkSlot;
  'park-slot.answered': { slot: SoftphoneTypes.ParkSlot; call: OutgoingCall };
  'park-slot.answer-failed': { slot: SoftphoneTypes.ParkSlot; message: string };
  'park-slot.ringback': { slot: SoftphoneTypes.ParkSlot; call: IncomingCall };

  'e911.selected': Address;
  'e911.unseleted': null;
  'e911.confirmed': null;

  'settings.mute': null;
  'settings.unmute': null;

  'call-state.changed': {
    calls: AnyCall[];
    mergedCallGroup: MergedCallGroup | undefined;
    incomingCalls: IncomingCall[];
    outgoingCalls: OutgoingCall[];
    establishedCalls: EstablishedCall[];
    terminatedCalls: TerminatedCall[];
    primaryCall: AnyCall | undefined;
  };

  'call.feedback_requested': FeedbackEvents;
  'softphone.restart': CallIdType;
  'peripheral.information': AudioEvents;

  //TODO: add more events as needed
};
const emitter = mitt<Events>();

type SoftphoneEventsContextValue = typeof emitter;
const SoftphoneEventsContext = createContext<SoftphoneEventsContextValue>(emitter);

type Handlers = {
  [P in keyof Events]: (arg: Events[P]) => void;
};

type Props = {
  children: ReactNode;

  /** This argument should be memoized so it doesn't unsubscribe/resubscribe on every rerender */
  handlers?: Partial<Handlers>;
};

export const SoftphoneEventsProvider = ({ handlers, children }: Props) => {
  const handlersRef = useRef(handlers);
  useEffect(() => {
    if (handlers) {
      if (handlersRef.current && handlersRef.current !== handlers) {
        //unsubscribe from the previous handlers whenever this changes
        (Object.entries(handlers) as ObjectEntries<Handlers>).forEach((entry) => {
          emitter.off(entry[0], entry[1] as Handler<Events[(typeof entry)[0]]>);
        });
      }
      (Object.entries(handlers) as ObjectEntries<Handlers>).forEach((entry) => {
        emitter.on(entry[0], entry[1] as Handler<Events[(typeof entry)[0]]>);
      });
      handlersRef.current = handlers;
      return () => {
        if (handlersRef.current) {
          (Object.entries(handlers) as ObjectEntries<Handlers>).forEach((entry) => {
            emitter.off(entry[0], entry[1] as Handler<Events[(typeof entry)[0]]>);
          });
        }
      };
    }
    return;
  }, [handlers]);

  return <SoftphoneEventsContext.Provider value={emitter}>{children}</SoftphoneEventsContext.Provider>;
};

export const useSoftphoneEventsEmitter = () => {
  return useContextSelector(SoftphoneEventsContext, (ctx) => ctx);
};

export const useSoftphoneEventSubscription = <
  Action extends keyof Events,
  Handler extends (arg: Events[Action]) => void
>(
  action: Action | Action[],
  handler: Handler,
  deps?: any[]
) => {
  const emitter = useSoftphoneEventsEmitter();
  const handlerRef = useRef<Handler>();

  useEffect(() => {
    const actions = Array.isArray(action) ? action : [action];
    if (handlerRef.current) {
      actions.forEach((action) => emitter.off(action, handlerRef.current));
    }
    handlerRef.current = handler;
    actions.forEach((action) => emitter.on(action, handler));
    return () => {
      if (handlerRef.current) {
        actions.forEach((action) => emitter.off(action, handlerRef.current));
      }
    };
  }, [JSON.stringify(action), ...(deps ?? [])]);
};
