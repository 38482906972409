import { createContext, useContextSelector } from 'use-context-selector';
import { useSoftphoneEventSubscription } from './softphone-events-provider';

export type MetricEventsRequest = FeedbackEvents &
  AudioEvents &
  CallInfoEvents & {
    event: MetricsEvents;
  };

export type FeedbackEvents = {
  /* call_feedback events only */
  callFeedback?: string;
  callFeedbackAppUnresponsive?: boolean;
  callFeedbackCallDropped?: boolean;
  callFeedbackNoSound?: boolean;
  callFeedbackOther?: boolean;
  callFeedbackPoorSoundQuality?: boolean;
  callRating?: 'positive' | 'negative' | 'dismiss';
};

export type AudioEvents = {
  /* peripheral_information events only */
  audioInputDevice?: string;
  audioOutputDevice?: string;
};

export type CallInfoEvents = {
  /*  any event specific to a call, including call_feedback
   *  any event specific to a session, e.g. registered, register_failed - we can use Call-Id header from registration signal
   */
  callID?: string;
};

//https://linear.app/getweave/issue/PD_947/send_softphone_events_to_phone_device_events
type MetricsEvents =
  | 'registration_not_renewed'
  | 'setup_autoprovision_finish'
  | 'registered'
  | 'unregistered'
  | 'register_failed'
  | 'incoming_call'
  | 'missed_call'
  | 'reject_incoming_call'
  | 'call_completed_elsewhere'
  | 'answer_new_incoming_call'
  | 'outgoing_call'
  | 'cancel_call_out'
  | 'remote_busy'
  | 'call_remote_canceled'
  | 'call_established'
  | 'call_terminated'
  | 'transfer_call'
  | 'blind_transfer_call'
  | 'attended_transfer_call'
  | 'transfer_finished'
  | 'transfer_failed'
  | 'dnd_on'
  | 'dnd_off'
  | 'hold'
  | 'unhold'
  | 'mute'
  | 'unmute'
  | 'softphone_restart'
  | 'call_feedback'
  | 'peripheral_information';

type SoftphoneMetricsContextValue = {
  postEvent: (event: MetricEventsRequest) => void;
};
const SoftphoneMetricsContext = createContext({} as SoftphoneMetricsContextValue);

type Props = {
  postEvent: (event: MetricEventsRequest) => void;
  children: React.ReactNode;
};

export const SoftphoneMetricsProvider = ({ postEvent, children }: Props) => {
  const postMetricsEvent = (req: MetricEventsRequest) => postEvent(req);

  useSoftphoneEventSubscription('registration.registered', ({ callId }) => {
    postMetricsEvent({ event: 'registered', callID: callId });
  });
  useSoftphoneEventSubscription('registration.unregistered', ({ callId }) => {
    postMetricsEvent({ event: 'unregistered', callID: callId });
  });
  useSoftphoneEventSubscription(['registration.failure', 'registration.not_renewed'], ({ callId }) => {
    postMetricsEvent({ event: 'register_failed', callID: callId });
  });
  useSoftphoneEventSubscription('do-not-disturb.enabled', () => {
    postMetricsEvent({ event: 'dnd_on' });
  });
  useSoftphoneEventSubscription('do-not-disturb.disabled', () => {
    postMetricsEvent({ event: 'dnd_off' });
  });

  useSoftphoneEventSubscription('transfer-call.initiated', ({ call }) => {
    postMetricsEvent({ event: 'transfer_call', callID: call.session.dialog?.callId });
  });
  useSoftphoneEventSubscription(
    ['blind-call.transferred', 'attended-transfer.after-established.transferred'],
    ({ call }) => {
      postMetricsEvent({ event: 'transfer_finished', callID: call.session.dialog?.callId });
    }
  );
  useSoftphoneEventSubscription('attended-transfer.third-party.invitation.sent', ({ call }) => {
    postMetricsEvent({ event: 'attended_transfer_call', callID: call.session.dialog?.callId });
  });
  useSoftphoneEventSubscription('blind-transfer.sent', (call) => {
    postMetricsEvent({ event: 'blind_transfer_call', callID: call.call.session.dialog?.callId });
  });
  useSoftphoneEventSubscription(
    ['blind-transfer.failed', 'attended-transfer.third-party.invitation.failed'],
    ({ call }) => {
      postMetricsEvent({ event: 'transfer_failed', callID: call.session.dialog?.callId });
    }
  );
  useSoftphoneEventSubscription('incoming-call.received', (call) => {
    postMetricsEvent({ event: 'incoming_call', callID: call.invitation.dialog?.callId });
  });
  useSoftphoneEventSubscription('incoming-call.answered', (call) => {
    postMetricsEvent({ event: 'answer_new_incoming_call', callID: call.session.dialog?.callId });
  });
  useSoftphoneEventSubscription('incoming-call.rejected', (call) => {
    postMetricsEvent({ event: 'reject_incoming_call', callID: call.invitation.dialog?.callId });
  });
  useSoftphoneEventSubscription('incoming-call.missed', (call) => {
    postMetricsEvent({ event: 'missed_call', callID: call.invitation.dialog?.callId });
  });
  useSoftphoneEventSubscription('incoming-call.remote.canceled', (call) => {
    postMetricsEvent({ event: 'call_remote_canceled', callID: call.invitation.dialog?.callId });
  });
  useSoftphoneEventSubscription('outgoing-call.sent', (call) => {
    postMetricsEvent({ event: 'outgoing_call', callID: call.inviter.request?.callId });
  });
  useSoftphoneEventSubscription('outgoing-call.canceled', (call) => {
    postMetricsEvent({ event: 'cancel_call_out', callID: call.inviter.request?.callId });
  });
  useSoftphoneEventSubscription(['incoming-call.answered', 'outgoing-call.answered'], (call) => {
    postMetricsEvent({ event: 'call_established', callID: call.session.dialog?.callId });
  });
  useSoftphoneEventSubscription('active-call.terminated', (call) => {
    postMetricsEvent({ event: 'call_terminated', callID: call.session.dialog?.callId });
  });
  useSoftphoneEventSubscription('active-call.hold', (call) => {
    postMetricsEvent({ event: 'hold', callID: call.session.dialog?.callId });
  });
  useSoftphoneEventSubscription('active-call.unhold', (call) => {
    postMetricsEvent({ event: 'unhold', callID: call.session.dialog?.callId });
  });
  useSoftphoneEventSubscription('settings.mute', () => {
    postMetricsEvent({ event: 'mute' });
  });
  useSoftphoneEventSubscription('settings.unmute', () => {
    postMetricsEvent({ event: 'unmute' });
  });
  useSoftphoneEventSubscription('call.feedback_requested', (feedback) => {
    postMetricsEvent({ event: 'call_feedback', ...feedback });
  });
  useSoftphoneEventSubscription('softphone.restart', ({ callId }) => {
    postMetricsEvent({ event: 'softphone_restart', callID: callId });
  });
  useSoftphoneEventSubscription('peripheral.information', ({ audioInputDevice, audioOutputDevice }) => {
    postMetricsEvent({ event: 'peripheral_information', audioInputDevice, audioOutputDevice });
  });
  //TODO: add more as needed

  const value = {
    postEvent: (event: MetricEventsRequest) => postEvent(event),
  } satisfies SoftphoneMetricsContextValue;

  return <SoftphoneMetricsContext.Provider value={value}>{children}</SoftphoneMetricsContext.Provider>;
};

export const useSoftphoneMetrics = <T extends any>(selector: (value: SoftphoneMetricsContextValue) => T) => {
  return useContextSelector(SoftphoneMetricsContext, selector);
};
