import dayjs from 'dayjs';
import { i18next } from '@frontend/i18n';

/**
 * DS guidelines:
 * Same day – Today 00:00 AM
 * Previous day – Yesterday 00:00 AM
 * All days before yesterday – April 1, 00:00 AM
 * Outside current year – April 1, 2023, 00:00 AM
 */
export const humanizeDateTime = (dateTime: string) => {
  const today = dayjs();
  const date = dayjs(dateTime);

  // Today
  const isToday = today.isSame(date, 'day');
  if (isToday) {
    return i18next.t('Today {{time}}', { time: date.format('hh:mm A') });
  }

  // Yesterday
  const yesterday = today.subtract(1, 'day');
  const isYesterday = yesterday.isSame(date, 'day');
  if (isYesterday) {
    return i18next.t('Yesterday {{time}}', { time: date.format('hh:mm A') });
  }

  // All days before yesterday (within this year)
  if (today.year() === date.year()) {
    return date.format('MMM DD, hh:mm A');
  }

  // Outside current year
  return date.format('MMM DD, YYYY, hh:mm A');
};
