import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { Modal, useModalControl } from '@frontend/design-system';
import { PaymentReceiptModal } from '../PaymentReceiptModal';
import { RefundReceiptModal } from '../RefundReceiptModal';

export type BaseReceiptModalProps = {
  invoice: InvoiceModel;
  receiptType?: 'payment' | 'refund';
  refundId?: string;
};

type ViewReceiptModalProps = ReturnType<typeof useModalControl>['modalProps'] & BaseReceiptModalProps;

export const ViewReceiptModal = ({ invoice, receiptType = 'payment', refundId, ...props }: ViewReceiptModalProps) => {
  const { t } = useTranslation('payments');
  if (receiptType === 'refund' && (!invoice.payment?.paymentId || !refundId)) {
    throw new Error(t("Can't get refund receipt on invoices with no payment"));
  }
  return (
    <Modal
      css={css`
        min-width: 500px;
      `}
      {...props}
    >
      {receiptType === 'payment' && <PaymentReceiptModal invoice={invoice} />}
      {receiptType === 'refund' && invoice.payment?.paymentId && refundId && (
        <RefundReceiptModal paymentId={invoice.payment!.paymentId} refundId={refundId} />
      )}
    </Modal>
  );
};
