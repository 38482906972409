import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { LocationsApi } from '@frontend/api-locations';
import { authnClient } from '@frontend/auth';
import {
  clearLastVisitedPage,
  getDecodedWeaveToken,
  getLoginData,
  getUser,
  getWeaveToken,
  isSessionTokenActive,
  isWeaveTokenActive,
  isWeaveTokenBufferActive,
} from '@frontend/auth-helpers';
import { useFetch } from '@frontend/fetch';
import { useSyncShellHistory } from '@frontend/history';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useOnboardingRedirect } from '@frontend/onboarding';
import { useMerchant } from '@frontend/payments-hooks';
import { useInitializePhones } from '@frontend/phone';
import { useAppScopeStore } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { sentry } from '@frontend/tracking';
import { useAlert } from '@frontend/design-system';
import { signOut } from '../helpers/sign-out';
import { useConfigureLocation, determineOrgID } from '../utils/configure-location';
import { useMatchUser } from '../utils/routes/use-match-user';
import { setCookie } from '../utils/set-cookie';
import { getShellThemeFromLocalStorage } from './shell-theme-switcher/helper';
import { usePrivilegedQueryLocations } from './use-privileged-query-locations';
import { useQueryLocations } from './use-query-locations';
import { useSetLocation } from './use-set-location';

export type AppStateType =
  | {
      state: 'ONBOARDING';
      meta: {
        onboardingRedirectUrl: string;
      };
    }
  | {
      state: 'LOADING';
    }
  | {
      state: 'LOCATION_SELECTION';
    }
  | {
      state: 'INITIALIZED';
    };

const useRegisterLocations = ({ ids }: { ids: string[] }) => {
  const { setRegisteredLocationIds } = useLocationDataShallowStore('setRegisteredLocationIds');
  const { setAccessibleLocationIds } = useAppScopeStore();
  const decodedWeaveToken = getDecodedWeaveToken();
  const user = getUser();

  const { isInitialized: isPrivilegedInitialized } = usePrivilegedQueryLocations();
  const { isInitialized } = useQueryLocations({ user: user!, locationIds: ids });
  useEffect(() => {
    if (decodedWeaveToken?.type !== 'weave') {
      setRegisteredLocationIds(ids);
      setAccessibleLocationIds(ids);
    }
  }, [ids]);

  return { isRegistered: isInitialized || isPrivilegedInitialized };
};

export const useHttpSetup = ({
  token,
  locationId,
  isSessionTokenEnabled,
}: {
  token?: string;
  locationId: string;
  isSessionTokenEnabled: boolean;
}) => {
  const [isHttpReady, setIsHttpReady] = useState(false);
  const isSignOutTriggered = useRef(false);
  const { t } = useTranslation();
  const alerts = useAlert();
  const user = useMatchUser();
  const previousLocationIdRef = useRef('');
  const orgIdRef = useRef('');
  const isUserAddedToOrg = useRef(true);
  const isSessionTokenEnabledRef = useRef(false);

  const { setAuthorizationHeader, useMiddleware, clearMiddleware, useErrorMiddleware, clearErrorMiddleware } =
    useFetch();
  const { configureLocationData } = useConfigureLocation();

  const triggerInactiveSignOut = () => {
    if (!isSignOutTriggered.current) {
      alerts.error(t('You have been signed out due to inactivity. Please sign in again.'));
      isSignOutTriggered.current = true;
      setTimeout(() => {
        clearLastVisitedPage();
        signOut();
        isSignOutTriggered.current = false;
      }, 2000);
    }
  };

  const refreshTokenMiddleware = async (req: Request) => {
    // If this request does not need a token, just continue with the request
    if (!req.headers.has('Authorization')) {
      return req;
    }

    /**
     *
     * OKTA
     * If okta and weave are active, then follow through with request
     *
     * If okta active and weave inactive, then renew weave token
     *
     * If okta inactive and weave active - sign out
     *
     * If okta inactive and weave inactive - sign out
     *
     *
     * LEGACY
     *
     * if weave token active - follow through
     * if weave token inactive - renew
     */

    if (!authnClient.isUserAuthenticated() && !isWeaveTokenBufferActive()) {
      triggerInactiveSignOut();
    }

    // If the token has expired, refresh it and update the request header and http instance
    if (!isWeaveTokenActive() && isWeaveTokenBufferActive()) {
      try {
        const newToken = await authnClient.refreshWeaveToken();
        req.headers.set('Authorization', `Bearer ${newToken}`);
        setAuthorizationHeader(newToken);
      } catch (error) {
        alerts.error(t("You've been signed out"));
        clearLastVisitedPage();
        return signOut().then(() => req);
      }
    }

    if (!isSessionTokenActive() && orgIdRef.current !== '' && isUserAddedToOrg.current) {
      try {
        // check for this feature flag
        if (isSessionTokenEnabledRef.current) {
          authnClient
            .assureSessionToken(orgIdRef.current)
            .then((res) => {
              if (res.includes('403')) {
                //it means the user is not part of this organization so do not call session api for this organization
                isUserAddedToOrg.current = false;
              }
            })
            .catch((error) => {
              sentry.error({
                error: 'Failed to create a new session token',
                topic: 'session',
              });
              console.error('Failed to get session token', error);
            });
        }
      } catch (error) {
        sentry.error({
          error: 'Failed to create a new session token',
          topic: 'session',
        });
        console.error('Failed to get session token', error);
      }
    }
    return req;
  };

  async function handle401Error(response: Response): Promise<Response> {
    if (!response.ok) {
      if (response.status === 401) {
        triggerInactiveSignOut();
        return response;
      }
    }
    return Promise.resolve(response);
  }

  useEffect(() => {
    if (locationId && locationId !== '' && previousLocationIdRef.current !== locationId) {
      previousLocationIdRef.current = locationId;
      LocationsApi.getLocation(locationId)
        .then((locationInfo) => {
          const newOrgId = determineOrgID(locationInfo);
          if (orgIdRef.current !== newOrgId) {
            orgIdRef.current = newOrgId;
            isUserAddedToOrg.current = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [locationId]);

  useEffect(() => {
    useMiddleware(refreshTokenMiddleware);
    setAuthorizationHeader(token ?? '');
    useErrorMiddleware(handle401Error);

    return () => {
      clearMiddleware();
      clearErrorMiddleware();
    };
  }, []);

  useEffect(() => {
    isSessionTokenEnabledRef.current = isSessionTokenEnabled;
  }, [isSessionTokenEnabled]);

  // On first load, initialize http component
  useEffect(() => {
    const lastLocationId = user ? getLoginData(user.userID)?.lastLocationIds?.[0] : undefined;
    // configure location data if an id exists
    if (lastLocationId) {
      configureLocationData({
        newLocationId: lastLocationId,
        onSuccess: () => {
          setIsHttpReady(true);
        },
        onError: () => {
          /**
           * A failure here could indicate that the location is no longer active.
           * We need a way to handle this case. Right now we will just let them into the app, but none of their data will load.
           */
          setIsHttpReady(true);
        },
      });
    } else {
      setIsHttpReady(true);
    }
  }, [user?.userID]);

  return isHttpReady;
};

export const useInitializeApp = (): AppStateType => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const decodedWeaveToken = getDecodedWeaveToken();
  const keys = Object.keys(decodedWeaveToken?.ACLS ?? {});

  const locationId = selectedLocationIdsWithParents[0];
  const isSessionTokenEnabled = useHasFeatureFlag('use-session-token');
  const weaveToken = getWeaveToken();

  const isHttpReady = useHttpSetup({ token: weaveToken, locationId, isSessionTokenEnabled });
  const { isReady: isLocationReady, parentLocationProcessed } = useSetLocation(isHttpReady);

  useSyncShellHistory();
  const { isRegistered } = useRegisterLocations({
    ids: decodedWeaveToken?.type === 'weave' ? selectedLocationIdsWithParents : keys,
  });
  useMerchant();
  useInitializePhones({ ready: isLocationReady });
  setCookie({ name: 'isWeaveCustomer', value: 'true' });
  const { shouldShowOnboardingLoader, onboardingRedirectUrl } = useOnboardingRedirect({ isLocationReady });

  useLayoutEffect(() => {
    getShellThemeFromLocalStorage();
  }, []);

  useEffect(() => {
    document.querySelectorAll('.app-loader').forEach((loader) => loader.remove());
  }, []);

  let appState: AppStateType = {
    state: 'LOADING',
  };
  if (shouldShowOnboardingLoader || onboardingRedirectUrl) {
    appState = {
      state: shouldShowOnboardingLoader ? 'LOADING' : 'ONBOARDING',
      meta: {
        onboardingRedirectUrl,
      },
    };
  } else if (isLocationReady && !locationId) {
    appState = {
      state: 'LOCATION_SELECTION',
    };
  } else if (isLocationReady && parentLocationProcessed && locationId && isRegistered) {
    appState = {
      state: 'INITIALIZED',
    };
  }
  return appState;
};
