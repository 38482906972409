import { useEffect, useMemo } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { usePracticeAnalyticsShallowStore } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { getPracticeAnalyticsQueryVariables } from './helpers';
import { RecapturedMetricMulti, RecapturedMetricSingle } from '.';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      recapturedPatients: scheduledPatients {
        historicalData(start: $start, end: $end, step: $step) {
          label
          leadTime
          production
          timestamp
          total
        }
        totals(start: $start, end: $end, step: $step) {
          leadTime
          patients
          production
        }
      }
    }
  }
`;

const colors = {
  leadTime: theme.colors.critical40,
  patients: theme.colors.warning50,
  production: theme.colors.primary20,
};

export const RecapturedMetric = (props: PracticeAnalyticsTypes.MetricProps) => {
  const { isDrillDownPage, onFetchStateChange } = props;

  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;
  const showLocationComparison = isDrillDownPage && multipleLocationsSelected;

  const labels = {
    leadTime: multipleLocationsSelected ? t('Avg Lead Time (Days)') : t('Lead Time (Days)'),
    patients: t('Recaptured Patients'),
    production: t('Recaptured Production ($)'),
  };

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(
      `recapturedPatientsSummary-${isDemoAccount}-${JSON.stringify(filters)}`
    ),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.RecapturedPatientsTrend>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.recapturedPatients : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const aggregatedData = useMemo(() => {
    return PracticeAnalyticsAggregations.recapturedPatientsSummary(data);
  }, [data?.data]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return showLocationComparison ? (
    <RecapturedMetricMulti {...props} colors={colors} data={aggregatedData} isLoading={isLoading} labels={labels} />
  ) : (
    <RecapturedMetricSingle {...props} colors={colors} data={aggregatedData} isLoading={isLoading} labels={labels} />
  );
};
