import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { Outlet, useNavigate } from '@tanstack/react-location';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FeatureSubscriptionGuard } from '@frontend/self-upgrade';
import { useHasFeatureFlag } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { PracticeAnalyticsURLs } from '../components/practice/constants';
import { usePracticeAnalyticsShallowStore } from '../hooks';

export const PracticeAnalyticsPage: FC = () => {
  const navigate = useNavigate();
  const showPADemoModal = useHasFeatureFlag('practice-analytics-demo-inside-modal');

  const { isDemoModal, setIsDemoModal } = usePracticeAnalyticsShallowStore('isDemoModal', 'setIsDemoModal');
  const { setMode } = useSlidePanelShallowStore('setMode');

  const handleDemoModalClose = () => {
    setIsDemoModal(false);
    navigate({ to: PracticeAnalyticsURLs.MAIN });
  };

  useEffect(() => {
    // Show all side panel as tray if inside demo modal
    setMode(isDemoModal ? 'modal' : 'auto');
  }, [isDemoModal]);

  useEffect(() => {
    // reset demo modal state when unmounting
    return () => {
      setIsDemoModal(false);
      setMode('auto');
    };
  }, []);

  useEffect(() => {
    // Redirect /practice to /practice/main
    const oldPARoute = /\/practice(\?.*)?$/;
    if (oldPARoute.test(window.location.href)) {
      const searchParams = new URLSearchParams(window.location.search);
      const searchObject = Object.fromEntries(searchParams.entries());
      navigate({ replace: true, to: `${PracticeAnalyticsURLs.MAIN}`, search: searchObject });
    }
  }, []);

  if (!showPADemoModal) {
    return <Outlet />;
  }

  return (
    <FeatureSubscriptionGuard
      feature={Feature.PRACTICE_ANALYTICS}
      demoModalBodyStyles={demoModalBodyStyles}
      demoModalContent={<Outlet />}
      onDemoModalOpen={() => setIsDemoModal(true)}
      onDemoModalClose={handleDemoModalClose}
    >
      <Outlet />
    </FeatureSubscriptionGuard>
  );
};

const demoModalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'min(60vw, 1200px)',
  minHeight: '80vh',
});
