import { useEffect, useState, useMemo } from 'react';
import { DialQueries } from '@frontend/api-dialpad';
import { useMutation } from '@frontend/react-query-helpers';
import { SchemaPhoneUserService } from '@frontend/schema';
import { OutboundSelectedPhoneNumberProps } from '..';

export const useOutboundCallerIdNumbers = ({ phoneConfigTenantId }: { phoneConfigTenantId: string }) => {
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<OutboundSelectedPhoneNumberProps | undefined>();
  const { data: phoneNumbers, refetch: refetchNumbers } = DialQueries.useVoiceNumbersQuery(phoneConfigTenantId, {
    enabled: !!phoneConfigTenantId,
    select: (numbers) =>
      numbers?.sort((a, b) => {
        if (a.location?.name && b.location?.name) {
          return a.location?.name?.localeCompare(b.location?.name);
        }
        return 0;
      }),
  });
  const numbers = useMemo(() => {
    return (
      phoneNumbers?.reduce((acc, cur) => {
        return [
          ...acc,
          ...(cur.phoneNumbers?.map((phoneNumbers) => ({
            number: phoneNumbers.number?.nationalNumber?.toString() ?? '',
            locationName: cur.location?.name ?? '',
            isPreferred: phoneNumbers.isPreferred ?? false,
            phoneNumberId: phoneNumbers.id ?? '',
          })) ?? []),
        ];
      }, [] as OutboundSelectedPhoneNumberProps[]) ?? []
    );
  }, [phoneNumbers]);

  const { mutate: setPreferredNumber } = useMutation({
    mutationFn: (phoneNumberId: string) => SchemaPhoneUserService.SetPreferredPhoneNumber({ phoneNumberId }),
    onSuccess: () => {
      refetchNumbers();
    },
  });

  useEffect(() => {
    if (numbers.length === 1) return setSelectedPhoneNumber(numbers[0]);
  }, [numbers]);

  useEffect(() => {
    const isANumPreferred = numbers?.some((num) => !!num.isPreferred);
    const defaultPhoneNum = numbers?.find((num) => !!num.phoneNumberId);
    /* This is to backfill the backend data, if no number in the numbers array is preferred, 
      we want to set the first item in the array with a phoneNumberId  as the 
      preferred number
    */
    if (!isANumPreferred && !!defaultPhoneNum) {
      setPreferredNumber(defaultPhoneNum.phoneNumberId);
    }
  }, [numbers]);

  useEffect(() => {
    const preferredNumber = numbers.find((num) => num.isPreferred);
    if (!!preferredNumber && !selectedPhoneNumber) {
      setSelectedPhoneNumber(preferredNumber);
    }
  }, [selectedPhoneNumber, numbers]);

  return {
    selectedPhoneNumber,
    numbers,
    setSelectedPhoneNumber,
  };
};
