import { useEffect } from 'react';
import { css } from '@emotion/react';
import DOMPurify from 'dompurify';
import { InvoiceModel } from '@frontend/api-invoices';
import { theme } from '@frontend/theme';
import { Modal, useModalLoadingState } from '@frontend/design-system';
import { useFetchPaymentReceipt } from '../receipt-data-fetching';
import { ReceiptError } from '../ReceiptError';

type PaymentReceiptModalProps = {
  invoice: InvoiceModel;
};

export const PaymentReceiptModal = ({ invoice }: PaymentReceiptModalProps) => {
  const { setLoading } = useModalLoadingState();

  const paymentReceipt = useFetchPaymentReceipt(invoice);

  useEffect(() => {
    setLoading(paymentReceipt.isLoading);
  }, [paymentReceipt.isLoading]);

  return (
    <Modal.Body
      css={css`
        min-height: 300px;
      `}
    >
      {!paymentReceipt.isLoading && paymentReceipt.data && (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(paymentReceipt.data) }}
          css={css`
            border: 1px solid ${theme.colors.neutral10};
            & th {
              border: 0;
              background-color: unset;
            }
          `}
        />
      )}
      {!paymentReceipt.isLoading && paymentReceipt.isError && paymentReceipt.error ? (
        <ReceiptError error={paymentReceipt.error} />
      ) : null}
      {paymentReceipt.isLoading && (
        <div
          css={css`
            border: 1px solid ${theme.colors.neutral10};
            width: 450px;
            height: 362px;
          `}
        >
          <div
            css={css`
              background-color: ${theme.colors.neutral20};
              width: 100%;
              height: 84px;
            `}
          />
        </div>
      )}
    </Modal.Body>
  );
};
