import { CSSProperties, FC, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { AnalyticsCommonTypes, CallIntelMockData, CallIntelligenceTypes } from '@frontend/api-analytics';
import { LoadingSkeleton } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  CheckIcon,
  Chip,
  FeedbackBadIcon,
  FeedbackIcon,
  IconProps as DesignSystemIconProps,
  ModalControlModalProps,
  TableFilters,
  Text,
  XIcon,
} from '@frontend/design-system';
import { FiltersTray } from '..';
import { useCallIntelLocations, useCallIntelShallowStore, useTimePeriodFilterLabels } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { defaultDateRangeMap, getMatchingDateRangeKey } from '../../utils';
import { filtersStyles } from '../../views/common-styles';
import { CustomCheckboxField } from '../custom-check-box-field';
import {
  CheckListPopoverSelector,
  CheckListSelector,
  CustomPeriodGroups,
  DatesRangeSelector,
  DropDownMultiSelector,
  LocationsSelector,
  RadioFilterSelector,
  TimePeriodPopoverSelector,
  TimePeriodSelector,
} from '../filter-selectors';

export type CallIntelTab = 'overview' | 'records';

type IconProps = DesignSystemIconProps & {
  isFilterActive?: boolean;
};

type Props =
  | {
      activeTab: CallIntelTab;
      hideFilters?: (keyof CallIntelligenceTypes.Filters)[];
      isDemoAccount?: boolean;
      isLoadingData?: boolean;
      nonAnalyzedCallsCount?: number;
      onApplyingFilters?: () => void;
      onSearchReset?: () => void;
    } & (
      | {
          modalProps?: never;
          officeNumbers?: never;
          officeUsers?: AnalyticsCommonTypes.StringRecord;
          onlyChipFilters?: boolean;
          onlyTrayFilters?: never;
          setShowNotificationBadge?: never;
          wrapperStyles?: never;
        }
      | {
          modalProps: ModalControlModalProps;
          officeNumbers?: string[];
          officeUsers?: AnalyticsCommonTypes.StringRecord;
          onlyChipFilters?: never;
          onlyTrayFilters?: boolean;
          setShowNotificationBadge: (show: boolean) => void;
          wrapperStyles?: CSSProperties;
        }
    );

const DEFAULT_DAY = 'this-week';
const limitDefaultTimePeriods: (keyof typeof defaultDateRangeMap)[] = [
  'today',
  'yesterday',
  'last-7-days',
  'last-30-days',
  'this-week',
  'this-month',
];

const sentimentCodes: CallIntelligenceTypes.SentimentEnum[] = [
  CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE,
  CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL,
  CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE,
];

const defaultDates = defaultDateRangeMap[DEFAULT_DAY];
const demoLocations = CallIntelMockData.dummyLocationNames();

const renderOpportunityOption = (
  mainText: string,
  subText: string,
  Icon: FC<IconProps>,
  variant: 'primary' | 'neutral'
) => (
  <>
    <div css={styles.chipLabel}>
      <Chip css={styles.chip} variant={variant}>
        <Icon size={16} />
      </Chip>
      <Text>{mainText}</Text>
    </div>
    <Text color='subdued' size='small'>
      {subText}
    </Text>
  </>
);

const LocationIcon: React.FC<IconProps> = ({ isFilterActive }) => (
  <Icon name='location-small' color={isFilterActive ? 'white' : 'default'} />
);

const CalendarIcon: React.FC<IconProps> = ({ isFilterActive }) => (
  <Icon name='calendar-small' color={isFilterActive ? 'white' : 'default'} />
);

const ContactsIcon: React.FC<IconProps> = ({ isFilterActive }) => (
  <Icon name='contacts-small' color={isFilterActive ? 'white' : 'default'} />
);

const UserIcon: React.FC<IconProps> = ({ isFilterActive }) => (
  <Icon name='user-small' color={isFilterActive ? 'white' : 'default'} />
);

export const CallIntelFilters = ({
  activeTab,
  hideFilters,
  isDemoAccount,
  isLoadingData,
  modalProps,
  nonAnalyzedCallsCount = 0,
  officeNumbers,
  officeUsers,
  onApplyingFilters,
  onlyChipFilters,
  onSearchReset: handleSearchReset,
  onlyTrayFilters,
  setShowNotificationBadge,
  wrapperStyles,
}: Props) => {
  const { t } = useTranslation('analytics');
  const { selectedOrgId: locationId } = useAppScopeStore();

  const {
    availableAppointmentTypes,
    availableCategories,
    dataLabels,
    defaultFilters: defaultCallIntelFilters,
    filters,
    initialQueryParamsFilters,
    locationId: callIntelLocationId,
    resetStore,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setInitialQueryParamsFilters,
    setLocationId,
    subView,
  } = useCallIntelShallowStore(
    'availableAppointmentTypes',
    'availableCategories',
    'dataLabels',
    'defaultFilters',
    'filters',
    'initialQueryParamsFilters',
    'locationId',
    'resetStore',
    'setDefaultFilters',
    'setFilterHintText',
    'setFilters',
    'setInitialQueryParamsFilters',
    'setLocationId',
    'subView'
  );

  const [trayFilters, setTrayFilters] = useState<CallIntelligenceTypes.Filters>({});
  const [showFilteredBadge, setShowFilteredBadge] = useState<boolean>(false);

  const timeFilterLabels = useTimePeriodFilterLabels();
  const {
    isLoading: isLoadingLocations,
    isMultiLocation,
    locations,
  } = useCallIntelLocations({
    demoLocations: isDemoAccount ? demoLocations : undefined,
  });
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const defaultFilters: CallIntelligenceTypes.Filters = useMemo(
    () => ({
      appointmentTypes: [],
      callDirection: undefined,
      categories: [],
      contactTypes: [],
      endDate: defaultDates.endDate,
      hideNonAnalyzedCalls: false,
      locations: locations ? Object.keys(locations) : [],
      officeNumbers: [],
      officeUsers: [],
      schedulingOpportunity: '',
      schedulingOutcomes: [],
      sentiments: [],
      startDate: defaultDates.startDate,
    }),
    [locations]
  );

  const customTimePeriodGroups: CustomPeriodGroups = {
    datesMap: Object.entries(defaultDateRangeMap).reduce(
      (acc, [key, value]) =>
        limitDefaultTimePeriods.includes(key as keyof typeof defaultDateRangeMap) ? { ...acc, [key]: value } : acc,
      {}
    ),
    groups: [
      {
        period: limitDefaultTimePeriods,
      },
    ],
    labels: timeFilterLabels,
  };

  const handleUpdateTrayFilters = (newFilters: Partial<CallIntelligenceTypes.Filters>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const applyDefaultFilters = (hardReset = false) => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setErrors({});
    if (!hardReset && Object.keys(initialQueryParamsFilters).length) {
      const newFilters = { ...defaultFilters, ...initialQueryParamsFilters };

      setFilters(newFilters);
      setInitialQueryParamsFilters(initialQueryParamsFilters);
    } else {
      setFilters(defaultFilters);
    }
  };

  const applyActiveFiltersToTrayState = () => {
    handleUpdateTrayFilters(filters);
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    !!onApplyingFilters && onApplyingFilters();
    setFilters(trayFilters);
  };

  const handleUpdateErrors = (error: AnalyticsCommonTypes.filterSelectorError) => {
    setErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  const handleResetFilters = () => {
    applyDefaultFilters(true);
    !!handleSearchReset && handleSearchReset();
  };

  const hasErrors = useMemo(() => Object.values(errors).some((error) => error), [errors]);

  // We are not using directly hasCustomFilters to show filters badge as not all tabs has the same set of applied filters
  const areRecordsFiltered = (): boolean => {
    const areDatesEqual =
      dayjs(filters.startDate).isSame(dayjs(defaultFilters.startDate), 'date') &&
      dayjs(filters.endDate).isSame(dayjs(defaultFilters.endDate), 'date');

    const areLocationsEqual = isEqual(filters.locations?.sort(), defaultFilters.locations?.sort());

    if (activeTab === 'overview') {
      return !areDatesEqual || !areLocationsEqual;
    } else if (activeTab === 'records') {
      return (
        !areDatesEqual ||
        !areLocationsEqual ||
        !!filters.appointmentTypes?.length ||
        !!filters.callDirection ||
        !!filters.categories?.length ||
        !!filters.contactTypes?.length ||
        filters.hideNonAnalyzedCalls ||
        !!filters.officeNumbers?.length ||
        !!filters.officeUsers?.length ||
        !!filters.schedulingOpportunity ||
        !!filters.schedulingOutcomes?.length ||
        !!filters.sentiments?.length
      );
    }

    return false;
  };

  const schedulingOutcomesOptions = {
    [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED]: (
      <div css={styles.chipLabel}>
        <Chip css={styles.chip} variant='success'>
          <CheckIcon size={16} />
        </Chip>
        <Text>{t('Scheduled Appointment')}</Text>
      </div>
    ),
    [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED]: (
      <div css={styles.chipLabel}>
        <Chip css={styles.chip} variant='warn'>
          <XIcon size={16} />
        </Chip>
        <Text>{t('Unscheduled Appointment')}</Text>
      </div>
    ),
  };

  const contactTypeOptions = {
    [CallIntelligenceTypes.ContactTypeEnum.CONTACT_NEW_PATIENT]: (
      <Chip css={styles.chip} variant='warn'>
        {t('New Patient')}
      </Chip>
    ),
    [CallIntelligenceTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT]: (
      <Chip css={styles.chip} variant='primary'>
        {t('Existing Patient')}
      </Chip>
    ),
    [CallIntelligenceTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT]: (
      <Chip css={styles.chip} variant='neutral'>
        {t('Not a Patient')}
      </Chip>
    ),
  };

  const schedulingOpportunityOptions = {
    [CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]: renderOpportunityOption(
      t('Identified Scheduling Opportunity'),
      t('There was potential to schedule an appointment during the call.'),
      FeedbackIcon,
      'primary'
    ),
    [CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED]: renderOpportunityOption(
      t('No Scheduling Opportunity'),
      t("There wasn't potential to schedule an appointment during the call."),
      FeedbackBadIcon,
      'neutral'
    ),
  };

  const callDirectionOptions = {
    [CallIntelligenceTypes.CallDirectionEnum.DIRECTION_INBOUND]: t('Inbound Calls'),
    [CallIntelligenceTypes.CallDirectionEnum.DIRECTION_OUTBOUND]: t('Outbound Calls'),
  };

  const handleContactTypeChange = (value: CallIntelligenceTypes.ContactTypeFilterEnum[], directApply?: boolean) => {
    const filters: Partial<CallIntelligenceTypes.Filters> = {
      contactTypes: value,
    };

    !!onApplyingFilters && onApplyingFilters();
    directApply ? setFilters(filters) : handleUpdateTrayFilters(filters);
  };

  const handleOfficeUsersChange = (value: string[], directApply?: boolean) => {
    const filters: Partial<CallIntelligenceTypes.Filters> = {
      officeUsers: value,
    };

    !!onApplyingFilters && onApplyingFilters();
    directApply ? setFilters(filters) : handleUpdateTrayFilters(filters);
  };

  const handleSchedulingOutcomeChange = (
    value: CallIntelligenceTypes.SchedulingOutcomeEnum[],
    directApply?: boolean
  ) => {
    const filters: Partial<CallIntelligenceTypes.Filters> = {
      schedulingOutcomes: value,
    };
    // Scheduling outcomes can be filtered only when scheduling opportunity is identified
    // Hence automatically select the identified scheduling opportunity option
    if (value.length) {
      filters.schedulingOpportunity = CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED;
    }

    if (directApply && !value.length) {
      // Direct apply is used when user selects a value from the chip filter popover
      filters.schedulingOpportunity = '';
    }

    !!onApplyingFilters && onApplyingFilters();
    directApply ? setFilters(filters) : handleUpdateTrayFilters(filters);
  };

  const handleSectionClearClick = (key: keyof CallIntelligenceTypes.Filters, value: any = undefined) => {
    if (key === 'schedulingOpportunity') {
      handleUpdateTrayFilters({
        schedulingOpportunity: undefined,
        schedulingOutcomes: [],
      });
    } else {
      handleUpdateTrayFilters({ [key]: value });
    }
  };

  const renderChipFilters = () => (
    <>
      {isMultiLocation && !hideFilters?.includes('locations') && (
        <CheckListPopoverSelector<string>
          customStyles={{ chipDropdownFilter: { width: '100%' } }}
          defaultValue={defaultCallIntelFilters.locations}
          disabled={isLoadingData}
          label={t('Location')}
          onChange={(locations) => {
            !!onApplyingFilters && onApplyingFilters();
            setFilters({ locations });
          }}
          options={locations}
          renderIcon={(isFilterActive) => <LocationIcon isFilterActive={isFilterActive} />}
          value={filters.locations}
          trackingId={trackingIds.callIntel.locationsFilter}
        />
      )}

      {!hideFilters?.includes('startDate') && !hideFilters?.includes('endDate') && (
        <TimePeriodPopoverSelector
          defaultPeriod={DEFAULT_DAY}
          endDate={filters.endDate}
          limitDefaultTimePeriods={limitDefaultTimePeriods}
          onChange={({ endDate, startDate }) => {
            !!onApplyingFilters && onApplyingFilters();
            setFilters({ endDate, startDate });
          }}
          startDate={filters.startDate}
          trackingId={trackingIds.callIntel.timeFilter}
        />
      )}

      {!hideFilters?.includes('contactTypes') && (
        <CheckListPopoverSelector<CallIntelligenceTypes.ContactTypeFilterEnum>
          customStyles={{ chipDropdownFilter: { width: '100%' } }}
          defaultValue={defaultFilters.contactTypes}
          disabled={isLoadingData}
          label={t('Contact Type')}
          onChange={(value) => handleContactTypeChange(value, true)}
          options={contactTypeOptions}
          renderIcon={(isFilterActive) => <ContactsIcon isFilterActive={isFilterActive} />}
          trackingId={trackingIds.callIntel.contactTypesSelector}
          value={filters.contactTypes}
        />
      )}

      {activeTab === 'overview' && !hideFilters?.includes('officeUsers') && (
        <CheckListPopoverSelector<string>
          customStyles={{ chipDropdownFilter: { width: '100%' } }}
          defaultValue={defaultFilters.officeUsers}
          disabled={isLoadingData}
          label={t('Office User')}
          onChange={(value) => handleOfficeUsersChange(value, true)}
          options={officeUsers ?? {}}
          renderIcon={(isFilterActive) => <UserIcon isFilterActive={isFilterActive} />}
          trackingId={trackingIds.callIntel.officeUsersSelector}
          value={filters.officeUsers}
        />
      )}

      {activeTab === 'records' && (
        <>
          <CheckListPopoverSelector<CallIntelligenceTypes.SchedulingOutcomeEnum>
            customStyles={{ chipDropdownFilter: { width: '100%' } }}
            defaultValue={defaultFilters.schedulingOutcomes}
            disabled={
              isLoadingData ||
              filters.schedulingOpportunity ===
                CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED
            }
            label={t('Scheduling Outcome')}
            onChange={(value) => handleSchedulingOutcomeChange(value, true)}
            options={schedulingOutcomesOptions}
            renderIcon={(isFilterActive) => <CalendarIcon isFilterActive={isFilterActive} />}
            trackingId={trackingIds.callIntel.schedulingOutcomesFilter}
            value={filters.schedulingOutcomes}
          />
        </>
      )}
    </>
  );

  const renderTrayFilters = () => (
    <FiltersTray
      disableApply={hasErrors}
      disableTray={isLoadingData || isLoadingLocations}
      headerLabel={t('Filter Call Intelligence')}
      hideFiltersIcon={!!modalProps}
      modalProps={modalProps}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={handleResetFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={showFilteredBadge}
      trackingId={trackingIds.callIntel.filtersOpen}
    >
      {isMultiLocation && (
        <TableFilters.Section sectionHeaderLabel={t('Location')}>
          <div css={filtersStyles.traySection}>
            <LocationsSelector
              disabled={isLoadingLocations}
              locations={locations}
              onChange={(value) => handleUpdateTrayFilters({ locations: value })}
              onError={handleUpdateErrors}
              trackingId={trackingIds.callIntel.locationsSelector}
              value={trayFilters.locations}
            />
          </div>
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Date Range')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodSelector
            customPeriodGroups={customTimePeriodGroups}
            defaultPeriod={DEFAULT_DAY}
            endDate={trayFilters.endDate}
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({ endDate, startDate });
            }}
            startDate={trayFilters.startDate}
            trackingId={trackingIds.callIntel.timeSelector}
          />
          <DatesRangeSelector
            endDate={trayFilters.endDate}
            onChange={({ endDate, startDate }) => handleUpdateTrayFilters({ endDate, startDate })}
            startDate={trayFilters.startDate}
          />
        </div>
      </TableFilters.Section>

      {activeTab === 'records' && (
        <>
          <TableFilters.Section
            onClearClick={() => handleSectionClearClick('contactTypes', [])}
            sectionHeaderLabel={t('Contact Type')}
          >
            <div css={filtersStyles.traySection}>
              <CheckListSelector<CallIntelligenceTypes.ContactTypeFilterEnum>
                label={t('Contact Type')}
                onChange={handleContactTypeChange}
                options={contactTypeOptions}
                showLabel={false}
                trackingIdBase={trackingIds.callIntel.contactTypesFilter}
                value={trayFilters.contactTypes}
              />
            </div>
          </TableFilters.Section>

          <TableFilters.Section
            onClearClick={() => handleSectionClearClick('callDirection')}
            sectionHeaderLabel={t('Call Direction')}
          >
            <div css={filtersStyles.traySection}>
              <RadioFilterSelector<CallIntelligenceTypes.CallDirectionEnum>
                label={t('Call Direction')}
                onChange={(value) => handleUpdateTrayFilters({ callDirection: value })}
                options={callDirectionOptions}
                showLabel={false}
                trackingId={trackingIds.callIntel.callDirectionFilter}
                value={trayFilters?.callDirection}
                withBorder={false}
              />
            </div>
          </TableFilters.Section>
          {officeUsers && Object.keys(officeUsers).length && (
            <TableFilters.Section
              onClearClick={() => handleSectionClearClick('officeUsers')}
              sectionHeaderLabel={t('Office User')}
            >
              <div css={filtersStyles.traySection}>
                <DropDownMultiSelector
                  label={t('Select Office User')}
                  onChange={(value) => handleUpdateTrayFilters({ officeUsers: value })}
                  options={Object.entries(officeUsers).map(([id, label]) => ({
                    id,
                    label,
                  }))}
                  trackingId={trackingIds.callIntel.officeUsersFilter}
                  value={trayFilters.officeUsers}
                />
              </div>
            </TableFilters.Section>
          )}

          <TableFilters.Section
            onClearClick={() => handleSectionClearClick('schedulingOpportunity')}
            sectionHeaderLabel={t('Scheduling Opportunity')}
          >
            <div css={filtersStyles.traySection}>
              <RadioFilterSelector<string>
                label={t('Scheduling Opportunity')}
                onChange={(value) => {
                  if (value === CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED) {
                    handleSchedulingOutcomeChange([]);
                  }
                  handleUpdateTrayFilters({ schedulingOpportunity: value });
                }}
                options={schedulingOpportunityOptions}
                showLabel={false}
                subContent={{
                  [CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]: (
                    <>
                      <Text style={{ marginBottom: theme.spacing(2) }} weight='semibold'>
                        {t('Scheduling Outcome')}
                      </Text>
                      <CheckListSelector<CallIntelligenceTypes.SchedulingOutcomeEnum>
                        label={t('Scheduling Outcome')}
                        onChange={handleSchedulingOutcomeChange}
                        options={schedulingOutcomesOptions}
                        showLabel={false}
                        trackingIdBase={trackingIds.callIntel.schedulingOpportunitiesIdentifiedFilter}
                        value={trayFilters.schedulingOutcomes}
                      />
                    </>
                  ),
                }}
                trackingId={trackingIds.callIntel.schedulingOpportunitiesFilter}
                value={trayFilters.schedulingOpportunity}
              />
            </div>
          </TableFilters.Section>

          <TableFilters.Section
            onClearClick={() => handleSectionClearClick('sentiments')}
            sectionHeaderLabel={t('Customer Sentiment')}
          >
            <div css={filtersStyles.traySection}>
              <DropDownMultiSelector
                css={styles.sentimentDropDown}
                label={t('Select Customer Sentiments')}
                onChange={(value) => handleUpdateTrayFilters({ sentiments: value })}
                options={sentimentCodes.map((sentiment) => ({
                  id: sentiment,
                  label: dataLabels.sentimentsWithEmoji?.[sentiment] || sentiment,
                }))}
                trackingId={trackingIds.callIntel.customerSentimentFilter}
                value={trayFilters.sentiments}
              />
            </div>
          </TableFilters.Section>

          <TableFilters.Section
            onClearClick={() => handleSectionClearClick('categories')}
            sectionHeaderLabel={t('Category')}
          >
            <div css={filtersStyles.traySection}>
              <DropDownMultiSelector
                label={t('Select Categories')}
                onChange={(value) => handleUpdateTrayFilters({ categories: value })}
                options={availableCategories
                  .filter((type) => type !== CallIntelligenceTypes.CategoryEnum.CATEGORY_UNKNOWN)
                  .map((category) => ({
                    id: category,
                    label: dataLabels.categories?.[category] || category,
                  }))}
                trackingId={trackingIds.callIntel.categoriesFilter}
                value={trayFilters.categories}
              />
            </div>
          </TableFilters.Section>

          <TableFilters.Section
            onClearClick={() => handleSectionClearClick('appointmentTypes')}
            sectionHeaderLabel={t('Appointment Type')}
          >
            <div css={filtersStyles.traySection}>
              <DropDownMultiSelector
                label={t('Select Appointment Types')}
                onChange={(value) => handleUpdateTrayFilters({ appointmentTypes: value })}
                options={availableAppointmentTypes
                  .filter((type) => type !== CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN)
                  .map((appointmentTypes) => ({
                    id: appointmentTypes,
                    label: dataLabels.appointmentTypes?.[appointmentTypes] || appointmentTypes,
                  }))}
                trackingId={trackingIds.callIntel.appointmentTypesFilter}
                value={trayFilters.appointmentTypes}
              />
            </div>
          </TableFilters.Section>

          {officeNumbers?.length && (
            <TableFilters.Section
              onClearClick={() => handleSectionClearClick('officeNumbers')}
              sectionHeaderLabel={t('Office Number')}
            >
              <div css={filtersStyles.traySection}>
                <DropDownMultiSelector
                  label={t('Select Office Number')}
                  onChange={(value) => handleUpdateTrayFilters({ officeNumbers: value })}
                  options={officeNumbers.map((number) => ({
                    id: number,
                    label: formatPhoneNumber(number),
                  }))}
                  trackingId={trackingIds.callIntel.officeNumbersFilter}
                  value={trayFilters.officeNumbers}
                />
              </div>
            </TableFilters.Section>
          )}

          <TableFilters.Section sectionHeaderLabel={t('Hide Calls')}>
            <div css={filtersStyles.traySection}>
              <CustomCheckboxField
                label={t('Hide calls with no analysis ({{count}})', { count: nonAnalyzedCallsCount })}
                name='toggle-analyzed-calls'
                onChange={(value) => {
                  handleUpdateTrayFilters({ hideNonAnalyzedCalls: value });
                }}
                value={trayFilters.hideNonAnalyzedCalls}
                trackingId={trackingIds.callIntel.hideNonAnalyzedCallsFilter}
              />
            </div>
          </TableFilters.Section>
        </>
      )}
    </FiltersTray>
  );

  useEffect(() => {
    // Update local state when main filters has changed
    applyActiveFiltersToTrayState();

    const timeFilter = getMatchingDateRangeKey({
      datesMap: defaultDateRangeMap,
      endDate: filters.endDate,
      startDate: filters.startDate,
    }) as keyof typeof defaultDateRangeMap;

    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    setFilterHintText(
      (timeFilter && limitDefaultTimePeriods.includes(timeFilter) && timeFilterLabels[timeFilter || '']) ||
        `${dayjs(filters.startDate).format('MMM DD, YYYY')} - ${dayjs(filters.endDate).format('MMM DD, YYYY')}`
    );
  }, [filters]);

  useEffect(() => {
    setShowNotificationBadge?.(showFilteredBadge);
  }, [showFilteredBadge]);

  useEffect(() => {
    setShowFilteredBadge(areRecordsFiltered());
  }, [defaultFilters, filters]);

  useEffect(() => {
    // Reset when location is switched
    if (callIntelLocationId && locationId !== callIntelLocationId) {
      resetStore();
    }

    if (locationId) {
      setLocationId(locationId);
    }
  }, [locationId, callIntelLocationId]);

  useEffect(() => {
    if (defaultFilters && !subView.id) {
      setFilters(defaultFilters);
    }
  }, []);

  useEffect(() => {
    // Drill down pages (subview) will retain a few filters from the main page
    if (!subView.id && defaultFilters && !isLoadingLocations) {
      applyDefaultFilters();
    }
  }, [defaultFilters, isLoadingLocations, subView?.id]);

  return onlyChipFilters ? (
    renderChipFilters()
  ) : onlyTrayFilters ? (
    renderTrayFilters()
  ) : (
    <div css={filtersStyles.mainWrapper} style={wrapperStyles}>
      {renderChipFilters()}
      {renderTrayFilters()}
      {isLoadingLocations && (
        <LoadingSkeleton css={styles.loadingSkeleton}>
          <Text color='subdued'>{t('Loading Locations...')}</Text>
        </LoadingSkeleton>
      )}
    </div>
  );
};

const styles = {
  sentimentDropDown: css`
    > div:last-of-type {
      max-height: none;
    }
  `,

  chip: css`
    max-width: none;
  `,

  loadingSkeleton: css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  `,

  withInfoText: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,

  chipLabel: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,
};
