import { memo, useMemo } from 'react';
import { capitalize } from 'lodash-es';
import { PracticeAnalyticsAggregations, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { Chart, sequentialChartColors } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { usePracticeAnalyticsShallowStore } from '../../hooks';
import { formatters } from '../../utils';
import { DemoChip } from '../demo-chip';
import { collectAllInsuranceLabels } from './active-patients-metric-multi';

type Props = PracticeAnalyticsTypes.MetricProps & {
  data?: ReturnType<typeof PracticeAnalyticsAggregations.activePatientsDetails>;
  isLoading?: boolean;
};

export const ActivePatientsMetricSingle = memo(({ data, isLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const { showDemoChipAndBanner } = usePracticeAnalyticsShallowStore('showDemoChipAndBanner');

  const chartData = useMemo(() => {
    const { patientDemographics } = data?.aggregatedData || {};
    const insuranceLabels = [...new Set(collectAllInsuranceLabels(data))];

    const ageGender = {
      groups:
        patientDemographics?.buckets?.map(({ label, subbuckets }) => ({
          name: label,
          values: subbuckets.reduce(
            (acc, { label, total }) =>
              label.toLowerCase() === 'other' ? acc : { ...acc, [label.toLowerCase()]: total },
            {}
          ),
        })) || [],
    };

    const insurance = {
      groups:
        patientDemographics?.details?.insuranceProviders.map(({ label, total }) => ({
          name: label,
          values: {
            [label]: total,
          },
        })) || [],
    };

    return {
      ageGender,
      insurance,
      insuranceColors: insuranceLabels.reduce(
        (acc, label, index) => ({ ...acc, [label]: sequentialChartColors[index] || theme.colors.neutral5 }),
        {}
      ),
      insuranceLabels: insuranceLabels.reduce((acc, label) => ({ ...acc, [label]: capitalize(label) }), {}),
    };
  }, [data]);

  const commonAppearance = useMemo(() => {
    return {
      collectiveTooltip: true,
      showGridLines: true,
      showXAxis: true,
      showYAxis: true,
    };
  }, []);

  return (
    <>
      <Chart
        colors={{
          female: theme.colors.warning50,
          male: theme.colors.secondary.seaweed30,
        }}
        isLoading={isLoading}
        labels={{
          female: t('Female'),
          male: t('Male'),
        }}
      >
        <Chart.Header leftElement={showDemoChipAndBanner ? <DemoChip /> : null} title={t('Patients by Age/Gender')} />
        <Chart.BarChart
          appearance={commonAppearance}
          data={chartData.ageGender}
          formatValue={formatters.value.format}
        />
        <Chart.Legends />
      </Chart>

      <Chart
        colors={chartData.insuranceColors}
        commonTooltipLabel={t('Patients')}
        isLoading={isLoading}
        labels={chartData.insuranceLabels}
      >
        <Chart.Header leftElement={showDemoChipAndBanner ? <DemoChip /> : null} title={t('Patients by Insurance')} />
        <Chart.BarChart
          appearance={commonAppearance}
          data={chartData.insurance}
          formatValue={formatters.value.format}
        />
      </Chart>
    </>
  );
});

ActivePatientsMetricSingle.displayName = 'ActivePatientsMetricSingle';
