import { ReactNode, useRef, useEffect, MutableRefObject, KeyboardEventHandler } from 'react';
import { AnimatePresence, LayoutProps, motion, type MotionProps } from 'framer-motion';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { TextButton, useOnClickOutside } from '@frontend/design-system';
import { HEADER_SHADOW } from '../styles';

export type ThreadDrawerProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  keepMounted?: boolean;
  closeLabel?: string;
  hideCloseButton?: boolean;
  closeOnClickOutside?: boolean;
  closeButtonRef?: MutableRefObject<any>;
  layout?: LayoutProps['layout'];
  padding?:
    | string
    | {
        open: string;
        closed: string;
      };
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
};

/**
 * `ThreadDrawer` is a drawer that can be used to display additional content related to the thread.
 * It has smooth animations and can be opened and closed by setting the `open` prop to `true` or `false`.
 * This is best used as a child of the `ThreadHeader` component.
 *
 * @param open - Whether the drawer is open or not.
 * @param onClose - A callback function that will be called when the drawer is closed.
 * @param children - The content to display inside the drawer.
 * @param keepMounted (optional) - Whether the drawer should remain mounted when closed. Defaults to `false`.
 * @param closeLabel (optional) - The label to display on the close button. Defaults to 'Close'.
 * @param hideCloseButton (optional) - Whether the close button in the drawer should be hidden. Defaults to `false`.
 * @param closeOnClickOutside (optional) - Whether the drawer should close when clicking outside of it. Defaults to `true`.
 */
export const ThreadDrawer = ({
  open,
  onClose,
  children,
  keepMounted,
  closeLabel,
  hideCloseButton,
  closeOnClickOutside = true,
  closeButtonRef,
  layout = 'size',
  padding,
  onKeyDown,
  ...rest
}: MotionProps & ThreadDrawerProps) => {
  const { t } = useTranslation('thread-header');
  const focusRef = useRef<HTMLDivElement>(null);

  const openPadding = typeof padding === 'string' ? padding : padding?.open;
  const closedPadding = typeof padding === 'string' ? padding : padding?.closed;

  useOnClickOutside({
    active: open && closeOnClickOutside,
    ref: focusRef,
    handler: onClose,
    ignoreRefs: closeButtonRef ? [closeButtonRef] : undefined,
  });

  useEffect(() => {
    if (open) {
      focusRef.current?.focus();
    }
  }, [open]);

  return (
    <AnimatePresence>
      {(open || keepMounted) && (
        <motion.div
          ref={focusRef}
          layout={layout}
          initial={{
            height: 0,
            boxShadow: 'none',
            padding: closedPadding,
          }}
          animate={{
            height: open ? 'auto' : 0,
            boxShadow: open ? HEADER_SHADOW : 'none',
            padding: open ? openPadding : closedPadding,
          }}
          exit={{
            height: 0,
            boxShadow: 'none',
            padding: closedPadding,
          }}
          css={{
            position: 'absolute',
            backgroundColor: theme.colors.white,
            top: '100%',
            left: 0,
            right: 0,
            overflowX: 'hidden',
            overflowY: 'auto',
            width: '100%',
            zIndex: theme.zIndex.dropdown,
            maxHeight: '50vh',
          }}
          onKeyDown={(e) => {
            if (open && e.key === 'Escape') {
              onClose();
            }
            onKeyDown?.(e);
          }}
          transition={{
            top: {
              type: false,
            },
            left: {
              type: false,
            },
            right: {
              type: false,
            },
          }}
          {...rest}
        >
          {children}
          {!hideCloseButton && (
            <div
              css={{
                position: 'sticky',
                bottom: 0,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.colors.white,
              }}
            >
              <TextButton css={{ width: '100%', color: theme.font.colors.primary }} onClick={onClose}>
                {closeLabel || t('Hide')}
              </TextButton>
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
