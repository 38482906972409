import { useCallback, useMemo } from 'react';
import { useSoftphoneCallState } from '..';
import { EstablishedCall, isEstablishedCall } from '../../../types';
import {
  getMergedStream,
  getSessionReceiversMediaStream,
  toggleReceiverTracks,
  toggleSenderTracks,
} from '../../../utils/stream-utils';
import { useSoftphoneAudio } from '../../softphone-audio-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { useReInvite } from './use-reinvite';

/**
 *
 * Updates the session description handler to hold or unhold the call, and then sends a re-invite
 */
export const useToggleHold = () => {
  const { reInviteCall } = useReInvite();
  const updateCall = useSoftphoneCallState((ctx) => ctx.updateCall);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const isMuted = useSoftphoneCallState((ctx) => ctx.isMuted);
  const stream = useSoftphoneAudio((ctx) => ctx.stream);
  const { emit } = useSoftphoneEventsEmitter();
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);
  const { reInviteCalls } = useReInvite();
  const setPrimaryCall = useSoftphoneCallState((ctx) => ctx.setPrimaryCall);

  const toggleHold = useCallback(
    (call: EstablishedCall, hold: boolean) => {
      const session = call.session;
      session.sessionDescriptionHandlerOptionsReInvite = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore: this is valid, and necessary for the re-invite
        hold,
      };

      return reInviteCall(call).then((_res) => {
        toggleReceiverTracks(call.session, !hold);
        toggleSenderTracks(call.session, !hold && !isMuted);
        if (!hold) {
          const mediaStream = getSessionReceiversMediaStream(session);
          stream('remote', mediaStream);
          emit('active-call.unhold', call);
        } else {
          emit('active-call.hold', call);
        }
        return updateCall(call, { onHoldSince: hold ? call.onHoldSince ?? new Date() : undefined });
      });
    },
    [calls]
  );

  const toggleHoldAll = useCallback(
    async (hold: boolean) => {
      const res: EstablishedCall[] = [];
      for (const call of establishedCalls) {
        res.push(await toggleHold(call, hold));
      }
      return res;
    },
    [toggleHold, establishedCalls]
  );

  const toggleHoldMergedCalls = useCallback(
    async (hold: boolean) => {
      if (!mergedCallGroup?.length) {
        return;
      }
      const calls = mergedCallGroup.filter(isEstablishedCall);
      const sessions = calls.map((call) => call.session);
      await toggleHoldAll(hold);
      const mediaStream = getMergedStream(sessions);
      stream('remote', mediaStream);
      await reInviteCalls(calls).then(() => {
        for (const call of calls) {
          updateCall(call, { onHoldSince: hold ? call.onHoldSince ?? new Date() : undefined });
        }
      });
    },
    [mergedCallGroup]
  );

  const unHoldAndSetAsPrimary = useCallback(
    (call: EstablishedCall) => {
      toggleHoldAll(true).then(() => {
        toggleHold(call, false);
        setPrimaryCall(call.id);
      });
    },
    [toggleHold, toggleHoldAll]
  );

  return useMemo(
    () => ({ toggleHold, toggleHoldAll, toggleHoldMergedCalls, unHoldAndSetAsPrimary }),
    [toggleHold, toggleHoldAll, toggleHoldMergedCalls, unHoldAndSetAsPrimary]
  );
};
