import { forwardRef } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Accordion, Chip, NakedUl, Text } from '@frontend/design-system';
import { Accept, Thumb } from '..';
import { analyzingCallLottie } from '../../assets';
import { useCallIntelShallowStore } from '../../hooks';
import { callIntelligenceUtils } from '../../utils';
import { AnalyzingCallLoader } from './analyzing-call-loader';
import { CallAnalyzeStatus } from './call-analyze-status';

type Props = {
  call: CallIntelligenceTypes.Call;
  isFailedCall?: boolean;
  isPoorAudio?: boolean;
  isSkippedCall?: boolean;
};

export const CallAnalysis = forwardRef<HTMLDivElement, Props>(
  ({ call, isFailedCall, isPoorAudio, isSkippedCall }, ref) => {
    const { t } = useTranslation('analytics');
    const { chipVariants, dataLabels } = useCallIntelShallowStore('chipVariants', 'dataLabels');
    const { appointmentTypes, categories, schedulingOutcome, schedulingOpportunity, sentiment } = call;
    const isCallInProcessing = callIntelligenceUtils.isCallInProcessing(call.status);

    return (
      <>
        <Accordion.Header title={t('Call Analysis')} />
        <Accordion.Body ref={ref} style={styles.accordionBody}>
          {isCallInProcessing ? (
            <AnalyzingCallLoader
              lottie={analyzingCallLottie}
              message={t('Analyzing call recording...')}
              messageCss={styles.loadingMessage}
            />
          ) : (
            <>
              <CallAnalyzeStatus
                isFailedCall={isFailedCall}
                isPoorAudio={isPoorAudio}
                isSkippedCall={isSkippedCall}
                type='analysis'
              />
              <NakedUl css={styles.list}>
                <li>
                  <Text className='data-label'>{t('Contact Type')}</Text>
                  {!isFailedCall && !isSkippedCall && typeof schedulingOpportunity === 'boolean' ? (
                    <Chip variant={chipVariants[call.contactType ?? 'neutral']}>
                      {dataLabels.contactType?.[call.contactType ?? 'Unknown']}
                    </Chip>
                  ) : (
                    '-'
                  )}
                </li>
                <li>
                  <Text className='data-label'>{t('Scheduling Opportunity')}</Text>
                  {!isFailedCall && !isSkippedCall && typeof schedulingOpportunity === 'boolean' ? (
                    <Thumb up={schedulingOpportunity} />
                  ) : (
                    '-'
                  )}
                </li>
                <li>
                  <Text className='data-label'>{t('Scheduling Outcome')}</Text>
                  {isFailedCall || isSkippedCall ? (
                    '-'
                  ) : schedulingOpportunity ? (
                    <Accept accepted={schedulingOutcome} />
                  ) : (
                    t('N/A')
                  )}
                </li>
                <li>
                  <Text className='data-label'>{t('Customer Sentiment')}</Text>
                  {!isFailedCall && !isSkippedCall && sentiment ? (
                    <Text>{dataLabels.sentimentsWithEmoji?.[sentiment] || sentiment}</Text>
                  ) : (
                    '-'
                  )}
                </li>
                <li>
                  <Text className='data-label'>{t('Category')}</Text>
                  <div css={styles.chipsWrapper}>
                    {!isFailedCall && !isSkippedCall && categories?.length
                      ? categories.map((category) => (
                          <Chip key={category} variant={chipVariants?.[category] || 'neutral'}>
                            {dataLabels.categories?.[category] || category}
                          </Chip>
                        ))
                      : '-'}
                  </div>
                </li>
                <li>
                  <Text className='data-label'>{t('Appointment Type')}</Text>
                  <div css={styles.chipsWrapper}>
                    {!isFailedCall && !isSkippedCall && appointmentTypes?.length
                      ? appointmentTypes.map((appointmentType) => (
                          <Chip key={appointmentType} variant={chipVariants?.[appointmentType] || 'neutral'}>
                            {dataLabels.appointmentTypes?.[appointmentType] || appointmentType}
                          </Chip>
                        ))
                      : '-'}
                  </div>
                </li>
              </NakedUl>
            </>
          )}
        </Accordion.Body>
      </>
    );
  }
);

CallAnalysis.displayName = 'CallAnalysis';

const styles = {
  accordionBody: css`
    background-color: ${theme.colors.white};
  `,

  list: css`
    li {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: ${theme.spacing(2)};

      .data-label {
        color: ${theme.colors.neutral50};
      }
    }

    li:last-child {
      margin-bottom: ${theme.spacing(0)};
    }
  `,

  chipsWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    justify-content: flex-end;
  `,

  loadingMessage: css`
    margin-top: ${theme.spacing(-3)};
  `,
};
