import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { CallGroupApi } from '@frontend/api';
import { i18next } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { CallGroupPayload, InstructionFieldsProps } from '../types';
import { InstructionFields } from './base';

export const CallGroupInstructionField = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<CallGroupPayload>['onChange'];
  option: CallGroupPayload;
}) => {
  const { singleLocationId, accessibleLocationData } = useAppScopeStore();
  const location = accessibleLocationData[singleLocationId];
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';

  const { data = [], isLoading } = useQuery({
    queryKey: [queryKeys.callGroups(), location.id],
    queryFn: () => CallGroupApi.listCallGroups({ tenantId: tenantId }).then((res) => res.callGroups),
    select: (data) => {
      return (data ?? [])
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((cg) => ({
          value: cg.id,
          label: cg.name,
        }));
    },
  });

  useEffect(() => {
    if (!option.callGroupId && data.length > 0) {
      onChange({ callGroupId: data[0].value });
    }
  }, [option.callGroupId, data]);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<CallGroupPayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          callGroupId: data ?? [],
        },
        value: {
          callGroupId: option.callGroupId || data[0].value,
          callerName: option.callerName,
        },
      }}
    />
  );
};

const schema = {
  type: 'callGroup',
  fields: {
    callGroupId: {
      label: i18next.t('Call Group', { ns: 'phone' }),
      name: 'callGroupId',
      type: 'dropdown',
    },
    callerName: {
      label: i18next.t('Caller Label', { ns: 'phone' }),
      name: 'callerName',
      type: 'text',
      helperText: i18next.t('Label displays on Weave phone screen while call is ringing', { ns: 'phone' }),
    },
  },
} as const;
