import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { Sort_Enum } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';

export enum InboxStatus {
  UNREAD = 'new',
  UNREPLIED = 'unreplied',
  READ = 'read',
  REPLIED = 'replied',
  ERROR = 'error',
}
export type InboxFilters = {
  statuses: InboxStatus[];
  tags: string[];
  departments: string[];
};
export type InboxFilter = keyof InboxFilters;

export type ScheduledFilters = {
  statuses: OutboundMessageStatus[];
  tags: string[];
  departments: string[];
};
export type ScheduledFilter = keyof ScheduledFilters;

export type DraftsFilters = {
  [key: string]: unknown; // Once we have the filters, we can add the type here
};
export type DraftsStoreValueType = {
  sort: Sort_Enum;
  filters: DraftsFilters;
};
