import { PaymentPlanFrequency } from '@frontend/api-payment-plans';
import { formatCentsToCurrency } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { UseCardOnFileParams, useCardOnFile } from './use-card-on-file';

const MIN_PAYMENT_PLAN_ACH_AMOUNT = 2500;

export const usePaymentPlanCardOnFile = (
  params: Omit<UseCardOnFileParams, 'minACHAmount' | 'achDisabledByCadence'> & {
    oneTimePayment?: boolean;
    paymentFrequency: PaymentPlanFrequency;
  }
) => {
  const { t } = useTranslation('payments');
  const minACHAmount = MIN_PAYMENT_PLAN_ACH_AMOUNT;
  const { oneTimePayment = false, paymentFrequency } = params;
  const achDisabledByCadence = !oneTimePayment && paymentFrequency === PaymentPlanFrequency.Weekly;
  const { patientId, paymentAmount, disableACHPaymentMethods, invalidAmount } = useCardOnFile({
    ...params,
    minACHAmount,
    achDisabledByCadence,
  });

  const minACHAmountString = formatCentsToCurrency(minACHAmount);
  const achDisabledMessage = oneTimePayment
    ? t(`ACH only available when one time payments are over {{minimumAmount}} on payment plans.`, {
        minimumAmount: minACHAmountString,
      })
    : invalidAmount
    ? t(`ACH only available when term payments are over {{minimumAmount}} on payment plans.`, {
        minimumAmount: minACHAmountString,
      })
    : t(`ACH only available for bi-weekly and monthly cadence on payment plans.`);

  return {
    disableACHPaymentMethods,
    editBillingMethodProps: {
      patientId,
      paymentAmount,
      minACHAmount,
      achDisabledMessage,
      achDisabledByCadence,
    },
    paymentMethodItemProps: {
      disableACHPaymentMethods,
      achDisabledMessage,
      variant: 'light' as const,
    },
  };
};
