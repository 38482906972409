import { i18next, useTranslation } from '@frontend/i18n';
import {
  ChecklistField,
  Modal,
  ModalControlModalProps,
  Text,
  TextareaField,
  useFormField,
} from '@frontend/design-system';

type FeedbackOptions =
  | 'Poor sound quality'
  | 'No sound'
  | 'Call dropped'
  | 'Slow response, app freezing'
  | 'Other (explain below)';

const options: Record<string, FeedbackOptions> = {
  POOR_SOUND_QUALITY: i18next.t('Poor sound quality', { ns: 'phone' }),
  NO_SOUND: i18next.t('No sound', { ns: 'phone' }),
  CALL_DROPPED: i18next.t('Call dropped', { ns: 'phone' }),
  SLOW_RESPONSE: i18next.t('Slow response, app freezing', { ns: 'phone' }),
  OTHER: i18next.t('Other (explain below)', { ns: 'phone' }),
};

export const FeedbackModal = ({ ...modalProps }: ModalControlModalProps) => {
  const { t } = useTranslation('phone');

  const feedbackFieldProps = useFormField({
    type: 'checklist',
    value: [''],
  });

  const messageFieldProps = useFormField({
    type: 'text',
  });

  return (
    <Modal {...modalProps} maxWidth={600} minWidth={600}>
      <Modal.Header onClose={modalProps.onClose}>{t('Provide Additional Feedback')}</Modal.Header>
      <Modal.Body>
        <Text>{t('Please tell us what went wrong.')}</Text>
        <ChecklistField {...feedbackFieldProps} label='' name='softphone-feedback'>
          {Object.values(options).map((option) => (
            <ChecklistField.Option key={option} name={option}>
              {option}
            </ChecklistField.Option>
          ))}
        </ChecklistField>
        <TextareaField label={t('Feedback (Optional)')} name='softphone-call-feedback' {...messageFieldProps} />
      </Modal.Body>
      <Modal.Actions disablePrimary={!feedbackFieldProps.value} primaryLabel={t('Submit')} />
    </Modal>
  );
};
