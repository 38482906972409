import { CallIntelligenceTypes } from '../../call-intelligence';
import { generateRandomValue } from './overview';

type LocationsComparisonStatsProps = {
  appointmentType?: CallIntelligenceTypes.AppointmentTypeEnum;
  category?: CallIntelligenceTypes.CategoryEnum;
  locations?: string[];
  sentiment?: CallIntelligenceTypes.SentimentEnum;
  taskType?: CallIntelligenceTypes.TaskTypeEnum;
};

const locationCount = 8;

export const dummyLocationNames = () => {
  const data: { [key: string]: string } = {};

  for (let i = 1; i <= locationCount; i++) {
    data[`demo-00${i}`] = `Location ${i}`;
  }

  return data;
};

const generateLocationAppointmentTypes = (appointmentType?: CallIntelligenceTypes.AppointmentTypeEnum) => {
  if (appointmentType) {
    return {
      inferences: {
        [appointmentType]: generateRandomValue(5, 30),
      },
    };
  }

  return {
    inferences: {
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_PREVENTIVE]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_RESTORATIVE]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_SURGERY]: generateRandomValue(5, 30),
    },
  };
};

const generateLocationCategories = (category?: CallIntelligenceTypes.CategoryEnum) => {
  if (category) {
    return {
      inferences: {
        [category]: generateRandomValue(5, 30),
      },
    };
  }

  return {
    inferences: {
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_MEDICATION]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_OTHER]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.CategoryEnum.CATEGORY_VENDOR]: generateRandomValue(5, 30),
    },
  };
};

const generateLocationSentiments = (sentiment?: CallIntelligenceTypes.SentimentEnum) => {
  if (sentiment) {
    return {
      inferences: {
        [sentiment]: generateRandomValue(5, 30),
      },
    };
  }

  return {
    inferences: {
      [CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL]: generateRandomValue(5, 30),
      [CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE]: generateRandomValue(5, 30),
    },
  };
};

const generateLocationTaskTypes = (taskType?: CallIntelligenceTypes.TaskTypeEnum) => {
  if (taskType) {
    return {
      inferences: {
        [taskType]: generateRandomValue(0, 5),
      },
    };
  }

  return {
    inferences: {
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_SCHEDULING]: generateRandomValue(0, 5),
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_WAITLIST]: generateRandomValue(0, 5),
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_PATIENT_CARE]: generateRandomValue(0, 5),
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_INSURANCE]: generateRandomValue(0, 5),
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_BILLING]: generateRandomValue(0, 5),
      [CallIntelligenceTypes.TaskTypeEnum.TYPE_OTHER]: generateRandomValue(0, 5),
    },
  };
};

const mapLocations = <T>(callback: () => T, locations?: string[]): CallIntelligenceTypes.LocationStat<T> => {
  const data: CallIntelligenceTypes.LocationStat<T> = {};

  for (let i = 1; i <= locationCount; i++) {
    if (locations && !locations.includes(`demo-00${i}`)) {
      continue;
    }

    data[`00${i}`] = {
      ...callback(),
    };
  }

  return data;
};

const generateOpportunities = (locationFilter?: string[]) => {
  const data: CallIntelligenceTypes.LocationsStats['opportunitiesByLocation'] = {};

  for (let i = 1; i <= locationCount; i++) {
    if (locationFilter && !locationFilter.includes(`demo-00${i}`)) {
      continue;
    }

    data[`00${i}`] = {
      unscheduledOpportunities: generateRandomValue(5, 30),
      totalOpportunities: generateRandomValue(40, 60),
    };
  }

  return data;
};

export const locationsComparisonStats = ({
  locations,
  appointmentType,
  category,
  sentiment,
  taskType,
}: LocationsComparisonStatsProps): CallIntelligenceTypes.LocationsStats => ({
  appointmentTypesByLocation: mapLocations(() => generateLocationAppointmentTypes(appointmentType), locations),
  categoriesByLocation: mapLocations(() => generateLocationCategories(category), locations),
  opportunitiesByLocation: generateOpportunities(locations),
  sentimentsByLocation: mapLocations(() => generateLocationSentiments(sentiment), locations),
  taskTypesByLocation: mapLocations(() => generateLocationTaskTypes(taskType), locations),
});
