import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { PrimaryButton, SecondaryButton, Text, TextButton } from '@frontend/design-system';
import { FeatureUpgradeModalTrackingIds } from '../../../constants/tracking-ids';
import { useFeatureSubscription } from '../../../providers/FeatureSubscriptionProvider';
import { useFeatureUpgradeDetailsModalContext } from '../context';

const ACTION_BOX_HEIGHT = 36;

export const ActionFooter: FC = () => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'feature-upgrade-details-modal' });
  const { isUpgradeEligible, isFreeTrialEligible, hasAdminAccessInAtleastOne, canSelfSubscribe } =
    useFeatureSubscription();

  const {
    actionPosition,
    isMultiUpgrade,
    onDismiss,
    onScheduleCallClick,
    openFreeTrial,
    openRequestUpgrade,
    openSelfSubscribe,
  } = useFeatureUpgradeDetailsModalContext();

  return (
    <div css={[actionContainerStyles, { minHeight: actionPosition === 'raised' ? 0 : ACTION_BOX_HEIGHT }]}>
      <TextButton
        trackingId={FeatureUpgradeModalTrackingIds.dismissAction}
        css={[dismissButtonStyles, actionPosition === 'raised' && dismissButtonRaisedStyles]}
        onClick={onDismiss}
      >
        <Text color='primary' weight='bold'>
          {t('Maybe Later')}
        </Text>
      </TextButton>
      <div css={[actionButtonsStyles, actionPosition === 'raised' && actionButtonRaisedStyles]}>
        {hasAdminAccessInAtleastOne || isMultiUpgrade ? (
          <>
            {isFreeTrialEligible && (
              <SecondaryButton onClick={openFreeTrial} trackingId={FeatureUpgradeModalTrackingIds.freeTrialAction}>
                <Text weight='bold' color='light' css={{ textWrap: 'nowrap' }}>
                  {t('Start 30-Day free trial')}
                </Text>
              </SecondaryButton>
            )}
            {isUpgradeEligible && canSelfSubscribe ? (
              <PrimaryButton onClick={openSelfSubscribe} trackingId={FeatureUpgradeModalTrackingIds.upgradeAction}>
                {t('Upgrade Now')}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                onClick={onScheduleCallClick}
                trackingId={FeatureUpgradeModalTrackingIds.scheduleCallAction}
              >
                {t('Schedule a Call')}
              </PrimaryButton>
            )}
          </>
        ) : (
          <PrimaryButton onClick={openRequestUpgrade} trackingId={FeatureUpgradeModalTrackingIds.requestUpgradeAction}>
            {t('Request Upgrade')}
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

const actionContainerStyles = css({
  transition: 'min-height 0.5s ease',
  backgroundColor: theme.colors.white,
});

const dismissButtonStyles = css({
  position: 'absolute',
  cursor: 'pointer',
  left: theme.spacing(3),
  bottom: 0,
  transition: 'all 0.5s ease',
});

const dismissButtonRaisedStyles = css({
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: 'calc(12% - 48px)',
});

const actionButtonsStyles = css({
  position: 'absolute',
  right: theme.spacing(3),
  bottom: 0,
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  transition: 'all 0.5s ease',
});

const actionButtonRaisedStyles = css({
  right: '50%',
  transform: 'translateX(50%)',
  bottom: '12%',
});
