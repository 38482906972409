import { ReactNode, memo, useCallback, useMemo } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { CallIntelMockData } from '@frontend/api-analytics';
import { Chart, YAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { DemoChip, LocationChip } from '../..';
import { URLs } from '../../../constants';
import {
  CallIntelSubViewType,
  useCallIntelDemoFlags,
  useCallIntelLocations,
  useCallIntelShallowStore,
} from '../../../hooks';
import { formatters } from '../../../utils';

type Props = {
  categorisedComparison?: boolean;
  data?: Record<string, Record<string, number>>;
  internalPage?: boolean;
  isLoading?: boolean;
  title: string;
} & (
  | {
      colors?: never;
      dataType: CallIntelSubViewType;
      labels?: never;
    }
  | {
      colors: Record<string, string>;
      dataType?: never;
      labels: Record<string, ReactNode>;
    }
);

export const CallIntelLocationComparisonStackChart = memo(
  ({
    categorisedComparison,
    colors: propColors,
    data = {},
    dataType,
    internalPage,
    isLoading,
    labels: propLabels,
    title,
  }: Props) => {
    const { t } = useTranslation('analytics');
    const navigate = useNavigate();
    const { showDemoChipAndBanner } = useCallIntelDemoFlags();
    const { dataColors, dataLabels, filters, filterHintText, isDemoAccount, setFiltersToRestore, setSubView, subView } =
      useCallIntelShallowStore(
        'dataColors',
        'dataLabels',
        'filters',
        'filterHintText',
        'isDemoAccount',
        'setFiltersToRestore',
        'setSubView',
        'subView'
      );
    const { locations } = useCallIntelLocations({
      demoLocations: isDemoAccount ? CallIntelMockData.dummyLocationNames() : undefined,
    });

    const chartProps = useMemo(() => {
      return {
        colors:
          propColors ||
          (dataType === 'appointment-type'
            ? dataColors.appointmentTypes
            : dataType === 'category'
            ? dataColors.categories
            : dataColors.sentiments),

        labels:
          dataType === 'appointment-type'
            ? dataLabels.appointmentTypes
            : dataType === 'category'
            ? dataLabels.categories
            : dataLabels.sentimentsWithEmoji,
      };
    }, [dataColors, dataLabels, dataType, propColors]);

    const chartData = useMemo(() => {
      return {
        groups: Object.entries(data).map(([locationName, dataset]) => ({
          name: locationName,
          values: {
            ...dataset,
          },
        })),
      };
    }, [data]);

    const customTooltipTitle = useCallback(
      ({ groupName, hoveredSegment }: { groupName: string; hoveredSegment?: string | null }) => {
        return (
          <div>
            {hoveredSegment &&
              (propLabels ? (
                <div style={{ marginBottom: theme.spacing(1) }}>{propLabels[hoveredSegment]}</div>
              ) : (
                <Text color='subdued' size='small' style={{ marginBottom: theme.spacing(1) }}>
                  {internalPage &&
                    `${
                      {
                        ...dataLabels.appointmentTypes,
                        ...dataLabels.categories,
                        ...dataLabels.sentimentsWithEmoji,
                      }[subView?.id || '']
                    }: `}
                  {chartProps.labels?.[hoveredSegment]}
                </Text>
              ))}
            <LocationChip locationName={groupName} noTruncating />
          </div>
        );
      },
      [dataLabels, internalPage, subView?.id, chartProps.labels]
    );

    const handleBarClick = useCallback(
      ({ barId, group }: { barId: string; group: string }) => {
        const locationId = Object.entries(locations).find(([_key, value]) => value === group)?.[0];
        setFiltersToRestore(filters);
        setSubView({
          id: barId,
          type: dataType,
        });
        navigate({
          search: {
            type: dataType,
            locationId,
          },
          to: `${URLs.CALL_INTEL_BASE}/${barId.toLowerCase()}`,
        });
      },
      [locations, dataType]
    );

    return (
      <Chart
        colors={chartProps.colors || {}}
        commonTooltipLabel={t('Calls')}
        isLoading={isLoading}
        labels={chartProps.labels}
      >
        <Chart.Header
          bottomElement={
            internalPage ? null : (
              <Text color='subdued' size='medium'>
                {categorisedComparison ? t('Click each bar to learn more.') : t('Click each segment to learn more.')}
              </Text>
            )
          }
          leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
          subtitle={filterHintText}
          title={title}
        />
        {categorisedComparison ? (
          <Chart.CategoryBarChart
            appearance={{
              customCategoryNameRenderer:
                propLabels &&
                ((categoryId: string) => {
                  return propLabels[categoryId];
                }),
              customTooltipTitle,
              customYAxisTick: ({ labels, groupName, ...rest }) => {
                return <YAxisLabelValueTick {...rest} clipLength={22} label={labels?.[groupName] || groupName} />;
              },
            }}
            data={data}
            onClick={internalPage ? undefined : handleBarClick}
          />
        ) : (
          <>
            <Chart.Legends style={{ marginTop: theme.spacing(2) }} />
            <Chart.BarChart
              appearance={{
                customTooltipTitle,
                customYAxisTick: ({ labels, groupName, ...rest }) => {
                  return <YAxisLabelValueTick {...rest} clipLength={22} label={labels?.[groupName] || groupName} />;
                },
                groupsGap: 24,
                layout: 'vertical',
                margin: { top: 10, right: 20, left: 120 },
                maxHeight: 254,
                mode: 'stacked',
                showGridLines: true,
                showXAxis: true,
                showYAxis: true,
              }}
              data={chartData}
              onClick={internalPage ? undefined : handleBarClick}
              formatValue={formatters.value.format}
            />
          </>
        )}
        {!isLoading && (
          <Text color='subdued' size='medium' textAlign='center' weight='bold'>
            {t('Count of Calls')}
          </Text>
        )}
      </Chart>
    );
  }
);

CallIntelLocationComparisonStackChart.displayName = 'CallIntelLocationComparisonStackChart';
