import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { DialApi, DialUtils, DialpadTypes } from '@frontend/api-dialpad';
import { PersonHelpers, PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { removeNonDigits } from '@frontend/phone-numbers';
import { useSoftphoneInnerManager } from '@frontend/weave-softphone-manager';
import { theme } from '@frontend/theme';
import { DropdownField, Modal, ModalControlModalProps, Text, useFormField, useAlert } from '@frontend/design-system';

interface ContactNumberSelectionModalProps {
  person?: PersonTypes.Person;
  useOutboundNumber: boolean;
  defaultOutboundNumber: ReturnType<typeof DialUtils.getDefaultVoiceData>;
  modalProps: ModalControlModalProps;
}

export const getContactLabelValue = (contact: { number: string; label: string }) => {
  return `${contact?.number ?? ''} ${contact?.label ? `(${contact.label})` : ''}`;
};

export const ContactNumberSelection = ({
  person,
  defaultOutboundNumber,
  modalProps,
}: ContactNumberSelectionModalProps) => {
  const alerts = useAlert();
  const { t } = useTranslation('phone-call');
  const personName = PersonHelpers.getFullName(person);
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  const softphoneIsConnected = useSoftphoneInnerManager((ctx) => ctx.isConnected);

  const multipleContacts = PersonHelpers.getAllPhoneNumbers(person) ?? [];
  const defaultContact = multipleContacts?.find((phone) => !!phone.number);

  const fieldProps = useFormField(
    {
      type: 'dropdown',
      value: defaultContact ? getContactLabelValue(defaultContact) : '',
    },
    []
  );

  const { mutate: makeACall } = useMutation({
    mutationFn: (req: DialpadTypes.Dial['input']) => DialApi.dialUser(req),
    onSuccess: () => {
      if (!softphoneIsConnected) {
        alerts.success(t(`Call sent to desk phone.`));
      }
    },
  });

  return (
    <Modal {...modalProps} maxWidth={400}>
      <Modal.Header onClose={modalProps.onClose}>{t('Select Contact Number')}</Modal.Header>
      <Modal.Body>
        <section css={styles.attribute}>
          <Text as='span'>{t('Select the contact number you want to call.')}</Text>
        </section>

        <section css={styles.attribute}>
          <Text color='light' size='small' css={styles.heading}>
            {t('Contact')}
          </Text>
          <Text size='medium' css={styles.descStyles}>
            {personName}
          </Text>
        </section>

        <section css={styles.attribute}>
          <DropdownField {...fieldProps} css={styles.dropdownField} label={t('Contact Number')} name='contactNumber'>
            {multipleContacts?.map((phone) => {
              return (
                <DropdownField.OptionGroup key={phone?.number} label={phone?.label ?? ''}>
                  <DropdownField.Option
                    key={phone?.number}
                    value={getContactLabelValue(phone)}
                    displayValue={getContactLabelValue(phone)}
                  >
                    {phone?.number ?? ''}
                  </DropdownField.Option>
                </DropdownField.OptionGroup>
              );
            })}
          </DropdownField>
        </section>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Dial')}
        onPrimaryClick={() => {
          makeACall({
            fromName: defaultOutboundNumber.locationName,
            fromNumber: defaultOutboundNumber.defaultNumber,
            toNumber: removeNonDigits(fieldProps.value),
            sipProfileId: phoneConfig?.sipProfileId ?? '',
          });
          modalProps.onClose();
        }}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={modalProps.onClose}
      />
    </Modal>
  );
};

const styles = {
  heading: css`
    text-transform: capitalize;
    color: ${theme.colors.neutral50};
    font-size: ${theme.fontSize(12)};
    margin-bottom: ${theme.spacing(0.5)};
    line-height: ${theme.spacing(2)};
  `,

  attribute: css`
    margin-top: ${theme.spacing(2)};
    max-width: 400px;

    @media (max-width: 400px) {
      max-width: auto;
    }
  `,

  descStyles: css`
    line-height: ${theme.spacing(2)};
  `,

  dropdownField: css`
    margin-top: ${theme.spacing(3)};
  `,
};
