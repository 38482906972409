import dayjs from 'dayjs';
import { PracticeAnalyticsTypes } from '../../practice';
import { getRandomElement } from '../call-intel';

export type Name = { FirstName: string; LastName: string };

const firstNames = [
  'Abby',
  'Lacey',
  'Lucia',
  'Felix',
  'Anisa',
  'Jessie',
  'Frankie',
  'Amber',
  'Lisa',
  'Sylvia',
  'Isabella',
  'Sophie',
  'Felicity',
  'Aliyah',
  'Jamie',
];

const lastNames = [
  'Riley',
  'Arnold',
  'Briggs',
  'Faulkner',
  'Mcfarlane',
  'Dunn',
  'Daniel',
  'Barrett',
  'Castillo',
  'Mann',
  'Porter',
  'Zimmerman',
  'Allen',
  'Walsh',
  'Carson',
];

export const requiredPatientInfoFields = {
  Birthdate: '',
  Email: '',
  Gender: '',
  HomePhone: '',
  MobilePhone: '',
  nextAppointmentDate: '',
  PMID: '',
  PreferredName: '',
  WorkPhone: '',
};

export const sumHistoricalDataPatients = (data: PracticeAnalyticsTypes.HistoricalDataTotal[]) =>
  data.reduce((acc, cur) => acc + cur.total, 0);

export const generateHistoricalData = (totals: number[]): PracticeAnalyticsTypes.HistoricalDataTotal[] => {
  const startDate = dayjs().subtract(totals.length, 'day').toDate();

  return totals.map((_, idx) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + idx);

    return {
      label: '',
      leadTime: getRandomInt(0, 10),
      production: getRandomInt(100, 1000),
      timestamp: dayjs(date).format('YYYYMMDD'),
      total: totals[idx],
    };
  });
};

const getLast12Months = (): string[] => {
  const last12Months = [];
  for (let i = 0; i < 12; i++) {
    const date = dayjs().subtract(i, 'month');
    last12Months.push(date.format('MMMM YYYY'));
  }
  return last12Months;
};

const zipObject = (keys: string[], values: number[]) => {
  const result: Record<string, number> = {};
  for (let i = 0; i < keys.length; i++) {
    result[keys[i]] = values[i];
  }
  return result;
};

export const getTrends = <T>(keys: string[], values: number[][]) => {
  const labels = getLast12Months();
  return values.map((v, idx) => {
    return { label: labels[idx], ...zipObject(keys, v) };
  }) as T;
};

export const generateNames = (count: number): Name[] => {
  const names: Name[] = [];
  for (let i = 0; i < count; i++) {
    names.push({
      FirstName: firstNames[i % 15],
      LastName: lastNames[i % 15],
    });
  }
  return names;
};

export const generateUniqueNames = (count: number): Name[] => {
  const names: Name[] = [];
  const usedCombinations = new Set<string>();

  while (names.length < count) {
    const firstName = getRandomElement(firstNames);
    const lastName = getRandomElement(lastNames);
    const fullName = `${firstName} ${lastName}`;

    if (!usedCombinations.has(fullName)) {
      usedCombinations.add(fullName);
      names.push({ FirstName: firstName, LastName: lastName });
    }
  }

  return names;
};

export const getRandomInt = (min: number, max = 0): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomGender = () => {
  const genders = ['Female', 'Male', 'Other'];
  return genders[getRandomInt(0, 2)];
};

export const getRandomDate = (start: number, end: number) =>
  dayjs().add(getRandomInt(start, end), 'days').format('MM/DD/YYYY');

export const getRandomDateTime = (start: number, end: number) =>
  dayjs().add(getRandomInt(start, end), 'days').format('MM/DD/YYYY hh:mm A');

export const getRandomBoolean = () => {
  const values = [true, false];
  return values[getRandomInt(0, 1)];
};
