import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CallIntelMockData, CallIntelligenceTypes } from '@frontend/api-analytics';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { SecondaryButton, Tabs, useModalControl } from '@frontend/design-system';
import { FeedbackButton, NWXNavigationModal } from '../components';
import {
  CallIntelDemoBanner,
  CallIntelFilters,
  CallIntelOverviewPanel,
  CallIntelTab,
  CallRecordsTable,
  ExploreLocationsTray,
  SummaryCard,
} from '../components/call-intelligence';
import {
  useCallIntelLocations,
  useCallIntelShallowStore,
  useIsCallIntelDemoAccount,
  useUpdateDefaultCallIntelStore,
} from '../hooks';
import { trackingIds } from '../tracking-ids';
import { callIntelStyles, filtersStyles, pageStyles, stickyTabsStyles } from './common-styles';

const DEFAULT_TAB: CallIntelTab = 'overview';

export const CallIntelligenceAnalyticsPOC = () => {
  const { t } = useTranslation('analytics');
  const stickyTabsRef = useRef<HTMLDivElement | null>(null);
  const { modalProps, triggerProps } = useModalControl();
  const [activeTab, setActiveTab] = useState<CallIntelTab>(DEFAULT_TAB);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const search = useSearch();
  const navigate = useNavigate();

  const {
    activeMultiView = 'summary',
    defaultFilters,
    filterHintText,
    filters,
    filtersToRestore,
    setActiveMultiView,
    setFilters,
    setFiltersToRestore,
    setInitialQueryParamsFilters,
    setSubView,
    setIsDemoAccount,
  } = useCallIntelShallowStore(
    'activeMultiView',
    'defaultFilters',
    'filterHintText',
    'filters',
    'filtersToRestore',
    'setActiveMultiView',
    'setFilters',
    'setFiltersToRestore',
    'setInitialQueryParamsFilters',
    'setIsDemoAccount',
    'setSubView'
  );

  const [isLoadingAggregatedData, setIsLoadingAggregatedData] = useState<boolean>(false);

  const isDemoAccount = useIsCallIntelDemoAccount();
  const { isMultiLocation, locations } = useCallIntelLocations({
    demoLocations: isDemoAccount ? CallIntelMockData.dummyLocationNames() : undefined,
  });

  useUpdateDefaultCallIntelStore();

  const getPageSubtitleText = () =>
    `${
      isMultiLocation && filters.locations?.length
        ? filters.locations.length === Object.keys(locations).length
          ? t('all locations, ')
          : filters.locations.length === 1
          ? t('1 location, ')
          : t('{{count}} locations, ', { count: filters.locations.length })
        : ''
    }${filterHintText}`;

  const handleTabChange = useCallback(
    (tab: string) => {
      setFiltersToRestore(filters);
      setActiveTab(tab as CallIntelTab);
    },
    [filters]
  );

  const handleClickOnSummaryCard = useCallback(
    (card: SummaryCard, locationId?: string) => {
      let filtersMod = { ...filters };

      if (card === 'callsAnalyzed') {
        filtersMod = { ...defaultFilters };
        filtersMod.locations = locationId ? [locationId] : filters.locations;
        filtersMod.startDate = filters.startDate;
        filtersMod.endDate = filters.endDate;
      } else {
        filtersMod.schedulingOpportunity =
          CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED;

        if (locationId) {
          filtersMod.locations = [locationId];
        }

        if (card === 'unscheduled') {
          filtersMod.schedulingOutcomes = [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED];
        } else if (card === 'conversionRate') {
          filtersMod.schedulingOutcomes = [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED];
        }
      }

      setFilters(filtersMod);
      setFiltersToRestore(filtersMod);
      setActiveTab('records');
    },
    [filters]
  );

  useEffect(() => {
    if (search?.filters) {
      const queryFilters = search.filters;

      if (queryFilters) {
        try {
          const decodedFilters = atob(queryFilters as string);
          const appliedFilters = JSON.parse(decodedFilters);
          setActiveTab('records');
          setInitialQueryParamsFilters(appliedFilters);

          const { filters: _, ...newSearch } = search;

          navigate({ search: newSearch, replace: true });
        } catch (error) {
          console.error('Failed to process filters:', error);
        }
      }
    } else if (search['submit-feedback']) {
      setShowFeedbackModal(true);
      const { 'submit-feedback': _, ...newSearch } = search;
      navigate({ search: newSearch, replace: true });
    }
  }, [search, navigate]);

  useEffect(() => {
    // On main call intel dashboard we need to restore the filters to its original state when user navigates back from the sub view (drill down pages)
    if (filtersToRestore) {
      setFilters(filtersToRestore);
    } else if (
      (!filters.endDate || !filters.startDate || !filters.locations?.length) &&
      defaultFilters.startDate &&
      defaultFilters.endDate &&
      defaultFilters.locations?.length
    ) {
      // If user was auto redirected from sub view, we need to reset the filters so data can be loaded
      setFilters(defaultFilters);
    }
  }, [defaultFilters]);

  useEffect(() => {
    // Always reset sub view state
    setSubView({});

    // TODO :: This is temporary and need to be updated by allowing passing ref to the Page component
    const scrollableParent = stickyTabsRef.current?.parentElement?.parentElement?.parentElement;

    const handleScroll = () => {
      if (scrollableParent && stickyTabsRef.current) {
        const { top: stickyTop } = stickyTabsRef.current.getBoundingClientRect();
        const { top: pageTop } = scrollableParent.getBoundingClientRect();

        if (pageTop === stickyTop) {
          stickyTabsRef.current.classList.add('sticky-shadow');
        } else {
          stickyTabsRef.current.classList.remove('sticky-shadow');
        }
      }
    };

    scrollableParent?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableParent?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  return (
    <>
      <NWXNavigationModal />
      <CallIntelDemoBanner />
      <Page
        action={
          <FeedbackButton
            productCode={Feature.CALL_INTELLIGENCE}
            showModal={showFeedbackModal}
            trackingId={trackingIds.callIntel.generalFeedbackMainPage}
          />
        }
        css={[styles.page, activeTab === 'records' ? pageStyles.tableFullHeight : undefined]}
        subtitle={t('Showing results for {{filtersInfo}}', { filtersInfo: getPageSubtitleText() })}
        title={t('Call Intelligence')}
      >
        <Tabs controlledTab={activeTab} initialTab={DEFAULT_TAB} onChange={handleTabChange}>
          <div css={stickyTabsStyles} ref={stickyTabsRef}>
            <Tabs.Bar>
              <Tabs.Tab controls='overview-panel' id='overview' trackingId={trackingIds.callIntel.overviewTab}>
                {t('Overview')}
              </Tabs.Tab>
              <Tabs.Tab controls='records-panel' id='records' trackingId={trackingIds.callIntel.recordsTab}>
                {t('All Call Recordings')}
              </Tabs.Tab>
            </Tabs.Bar>
            {activeTab === 'overview' && (
              <div
                css={filtersStyles.mainWrapper}
                style={{ marginBottom: 0, marginTop: theme.spacing(3), gap: theme.spacing(2) }}
              >
                <CallIntelFilters
                  activeTab='overview'
                  hideFilters={['officeUsers']}
                  isDemoAccount={isDemoAccount}
                  isLoadingData={isLoadingAggregatedData}
                  onlyChipFilters
                />

                {isMultiLocation && (
                  <div css={callIntelStyles.multiComparison}>
                    <SecondaryButton
                      {...triggerProps}
                      size='large'
                      style={{ fontSize: theme.fontSize(14), textWrap: 'nowrap' }}
                    >
                      <span className='icon'>
                        <Icon color='light' name='locations' />
                      </span>
                      {t('Explore Location Data')}
                    </SecondaryButton>
                    <ExploreLocationsTray {...modalProps} onChange={setActiveMultiView} value={activeMultiView} />
                  </div>
                )}
              </div>
            )}
          </div>

          <Tabs.Panel controller='overview' css={styles.chartsTabPanel} id='overview-panel'>
            {activeTab === 'overview' && (
              <CallIntelOverviewPanel
                onChangeLoadingState={setIsLoadingAggregatedData}
                onClickSummaryCard={handleClickOnSummaryCard}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel controller='records' css={styles.recordsTablePanel} id='records-panel'>
            {activeTab === 'records' && <CallRecordsTable isDemoAccount={isDemoAccount} marginTop={0} />}
          </Tabs.Panel>
        </Tabs>
      </Page>
    </>
  );
};

const styles = {
  page: css`
    max-width: 100%;
    min-height: auto;
    height: auto;
  `,

  chartsTabPanel: css`
    padding: ${theme.spacing(1, 0)};
  `,

  recordsTablePanel: css`
    flex-grow: 1;
    padding-top: ${theme.spacing(1)};
  `,
};
