export const queryKeys = {
  base: ['phone'] as const,
  settings: {
    base: 'phone-settings' as const,
    audioLibrary: () => [...queryKeys.base, 'audioLibrary'],
    audioLibraryUsage: (id: string) => [...queryKeys.base, 'audioLibraryUsage', id],
    listCallQueue: () => [queryKeys.settings.base, 'call-queue'],
    readCallQueue: (id: string) => [queryKeys.settings.base, 'call-queue', id],
    listCallQueueDevices: () => [queryKeys.settings.base, 'available-devices'],
    listPhoneTrees: () => [queryKeys.settings.base, 'phone-tree'],
    readPhoneTree: (id: string) => [queryKeys.settings.base, 'phone-tree', id],
    listVoicemailBoxUnavailableExtensions: () => [queryKeys.settings.base, 'voicemail-box-unavailable-extensions'],
    listVoicemailBox: () => [queryKeys.settings.base, 'voicemail-box-list'],
    readVoicemailBox: (id: string) => [queryKeys.settings.base, 'voicemail-box', id],
    listVoicemailBoxGreetings: (id: string) => [queryKeys.settings.base, 'voicemail-box-greetings', id],
    listVoicemailGreetings: () => [queryKeys.settings.base, 'voicemail-greetings'],
    listVoicemailBoxUsage: (id: string) => [queryKeys.settings.base, 'voicemail-box-usage', id],
    listVoicemailExtensions: () => [queryKeys.settings.base, 'voicemail-extensions'],
    countVoicemails: (id: string) => [queryKeys.settings.base, 'voicemail-count', id],
    listSystemMedia: () => [queryKeys.settings.base, 'system-media'],
    listLocationHoldMusic: () => [queryKeys.settings.base, 'location-hold-music'],
  },
  audioPicker: (options?: Record<string, any>) => [...queryKeys.base, 'audioPicker', options],
  callBlocking: (id: string) => [...queryKeys.base, 'callBlocking', id],
  callBlockingList: () => [...queryKeys.base, 'callBlockingList'],
  callGroup: (id: string) => [...queryKeys.base, 'callGroup', id],
  callGroups: () => [...queryKeys.base, 'callGroups'],
  callGroupUsage: (id: string) => [...queryKeys.base, 'callGroupsUsage', id],
  callQueue: (id: string) => [...queryKeys.base, 'callQueue', id],
  callQueues: () => [...queryKeys.base, 'callQueues'],
  callRecords: (query?: string) => [...queryKeys.base, 'callRecords', query],
  department: (id: string) => [...queryKeys.base, 'department', id],
  departmentForwardingOverride: (id: string) => [...queryKeys.base, 'departmentForwardingOverride', id],
  departmentVMOverride: (id: string) => [...queryKeys.base, 'departmentVMOverride', id],
  departmentVoicemailBox: (id: string) => [...queryKeys.base, 'departmentVoicemailBox', id],
  deviceById: (id: string) => [...queryKeys.base, 'deviceById', id],
  devices: () => [...queryKeys.base, 'devices'],
  devicesWithAddresses: () => [...queryKeys.base, 'devicesWithAddresses'],
  familyLocations: (id: string) => [...queryKeys.base, 'familyLocations', id],
  forwardingNumbers: () => [...queryKeys.base, 'forwardingNumbers'],
  holdMusic: () => [...queryKeys.base, 'holdMusic'],
  mainlineDepartment: () => [...queryKeys.base, 'mainlineDepartment'],
  listCallGroups: (tenantId: string) => [...queryKeys.base, 'listCallGroups', tenantId],
  listCallRecording: (tenantId: string) => [...queryKeys.base, 'listCallRecording', tenantId],
  listDepartments: () => [...queryKeys.base, 'listDepartments'],
  listDepartmentsWithSchedules: () => [...queryKeys.base, 'listDepartmentsWithSchedules'],
  listDepartmentForwardNumbers: (id: string) => [...queryKeys.base, 'listDepartmentForwardNumbers', id],
  listDepartmentPhoneNumbers: (id: string) => [...queryKeys.base, 'listDepartmentPhoneNumbers', id],
  listDepartmentSchedules: (id: string) => [...queryKeys.base, 'listDepartmentSchedules', id],
  listLegacyLocationSchedules: (id: string) => [...queryKeys.base, 'listLegacyLocationSchedules', id],
  overrideList: () => [...queryKeys.base, 'listOverride'],
  phoneMedia: () => [...queryKeys.base, 'phoneMedia'],
  phoneTree: (id: string) => [...queryKeys.base, 'phoneTree', id],
  phoneTrees: () => [...queryKeys.base, 'phoneTrees'],
  ringtones: (id: string) => [...queryKeys.base, 'ringtones', id],
  selectedHoldMusic: () => [...queryKeys.base, 'selectedHoldMusic'],
  timezone: (locationId: string) => [...queryKeys.base, 'timezone', locationId],
  voiceMailbox: (id: string) => [...queryKeys.base, 'voiceMailbox', id],
  voiceMailboxes: () => [...queryKeys.base, 'voiceMailboxes'],
  voiceMailGreetings: (id: string) => [...queryKeys.base, 'voiceMailGreetings', id],
  voiceMailGreetingMap: (id: string) => [...queryKeys.base, 'voiceMailGreetingMap', id],
  voicemailOverride: () => [...queryKeys.base, 'voicemailOverride'],
  voicemailMessage: (mailboxId: string, messageId: string) => [
    ...queryKeys.base,
    'voicemailMessage',
    mailboxId,
    messageId,
  ],
  voicemailTranscription: (id: string) => [...queryKeys.base, 'voicemailTranscription', id],
  voicemailExtensions: (tenantId: string) => [...queryKeys.base, 'voicemailExtensions', tenantId],
  listTenantVoicemailBoxes: (tenantId: string) => [...queryKeys.base, 'listTenantVoicemailBoxes', tenantId],
  tenantVoicemailBox: (tenantId: string) => [...queryKeys.base, 'tenantVoicemailBox', tenantId],
};
