import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyles = css`
  border-radius: ${theme.borderRadius.small};
  min-width: 280px;
  max-width: 350px;
  padding: ${theme.spacing(1.5, 0)};
`;

export const searchFieldStyles = css`
  margin: ${theme.spacing(0, 1.5)};
`;

export const buttonsContainerStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
  margin-top: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 1.5)};
`;

export const checkboxListContainerStyles = css`
  max-height: 300px;
  overflow: auto;
`;

export const locationCheckboxStyles = css`
  margin-bottom: ${theme.spacing(1.5)};
  padding: ${theme.spacing(0, 1.5)};

  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const selectedLocationCheckboxStyles = [
  locationCheckboxStyles,
  css`
    &:last-of-type {
      border-bottom: 1px solid ${theme.colors.neutral20};
      padding-bottom: ${theme.spacing(1.5)};
    }
  `,
];

export const footerStyles = css`
  display: flex;
  margin-top: ${theme.spacing(2)};
`;

export const applyButtonStyles = css`
  margin: ${theme.spacing(0, 1.5)};
  flex: 1;
`;
