import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { compareDesc } from 'date-fns';
import { InvoiceModel, InvoiceStatus } from '@frontend/api-invoices';
import { UsersApi } from '@frontend/api-users';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { UsersTypes } from '@frontend/user-helpers';
import { theme } from '@frontend/theme';
import { EmailIcon, Heading, PreviewIcon, PrintIcon, Table, useModalControl } from '@frontend/design-system';
import { usePrintReceipt } from '../../../hooks';
import { PaymentsPageVariant } from '../../../providers';
import { InvoiceRefundStatus, HistoryRow } from '../../../types';
import { isPartialRefund } from '../../../utils';
import { SendReceiptModal, ViewReceiptModal } from '../../Receipts';
import { generateColumns } from './generate-columns';

type PaymentHistoryCardProps = {
  invoice: InvoiceModel;
  variant?: PaymentsPageVariant;
};
const failedPaymentType = 'Failed Payment';

export const PaymentHistoryCard = ({ invoice, variant = 'portal' }: PaymentHistoryCardProps) => {
  const { t } = useTranslation('payments');
  const [userMap, setUserMap] = useState<UsersTypes.User[]>([]);
  const [row, setRow] = useState<HistoryRow | undefined>(undefined);
  const columns = useMemo(() => generateColumns(t), []);
  const viewModalProps = useModalControl();
  const sendModalProps = useModalControl();
  const isPaymentsAppVariant = variant === 'payments-app';

  const print = usePrintReceipt();

  const isRefund = (row: HistoryRow | undefined) =>
    row?.type === InvoiceRefundStatus.PartialRefund || row?.type === InvoiceRefundStatus.Refunded;
  const isCanceled = invoice.status === InvoiceStatus.Canceled;

  const handlePrintClick = (data: HistoryRow) => {
    print(invoice, {
      receiptType: isRefund(data) ? 'refund' : 'payment',
      refundId: data.refundId,
    });
  };

  const openView = (data: HistoryRow) => {
    setRow(data);
    viewModalProps.openModal();
  };

  const openSend = (data: HistoryRow) => {
    setRow(data);
    sendModalProps.openModal();
  };

  const lookupUser = (userId: string) => {
    const user = userMap?.find((user: UsersTypes.User) => user.UserID === userId);
    return user ? `${user.FirstName} ${user.LastName}` : '';
  };

  const getUsers = async () => {
    try {
      const users = await UsersApi.list.users();
      setUserMap(users);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const data: HistoryRow[] = useMemo(
    () =>
      invoice?.payment?.refunds?.map((refund) => {
        return {
          type: isPartialRefund(invoice) ? InvoiceRefundStatus.PartialRefund : InvoiceRefundStatus.Refunded,
          date: refund.createdAt,
          time: refund.createdAt,
          user: lookupUser(refund.refundedBy),
          amount: -refund.amount,
          refundId: refund.stripeId,
        };
      }) ?? [],
    [invoice.payment?.refunds?.length, lookupUser, isPartialRefund]
  );

  if (invoice.payments) {
    invoice.payments?.forEach(
      (payment) =>
        payment &&
        data.push({
          type: payment.status === 'FAILED' ? failedPaymentType : 'Payment',
          date: payment.paidAt,
          time: payment.paidAt,
          user: '', // This should be the office user
          amount: payment.paidAmount,
        })
    );
  } else if (invoice.payment) {
    data.push({
      type: 'Payment',
      date: invoice.payment.paidAt,
      time: invoice.payment.paidAt,
      user: '', // This should be the office user
      amount: invoice.payment.paidAmount,
    });
  }

  data.push({
    type: 'Requested',
    date: invoice.billedAt,
    time: invoice.billedAt,
    user: '', // This should be the location user when available
    amount: invoice.billedAmount,
  });

  data.sort((a, b) => compareDesc(new Date(a.date), new Date(b.date)));

  const hideRowAction = (data: HistoryRow): boolean => data.type === failedPaymentType;

  return (
    <>
      <Heading level={isPaymentsAppVariant ? 3 : 2} css={{ margin: 0 }}>
        {t('History')}
      </Heading>
      {!isPaymentsAppVariant && <Divider />}
      <Table
        colConfig={columns}
        data={data}
        emptyStateConfig={{
          type: 'payments',
          header: t('No data to display'),
        }}
        styleConfig={
          isPaymentsAppVariant
            ? {
                columns: [
                  {
                    id: '*',
                    cellStyler: css`
                      padding: ${theme.spacing(1, 1)};
                    `,
                    headerStyler: css`
                      padding: ${theme.spacing(1, 1)};
                    `,
                  },
                ],
              }
            : undefined
        }
        hasResponsiveColWidths
        rowActions={
          isCanceled || !(invoice?.payment || invoice?.payments)
            ? {}
            : {
                actions: [
                  {
                    label: t('Send Receipt'),
                    Icon: EmailIcon,
                    onClick: (data: HistoryRow) => openSend(data),
                    hide: hideRowAction,
                  },
                  {
                    label: t('View Receipt'),
                    Icon: PreviewIcon,
                    onClick: (data: HistoryRow) => openView(data),
                    hide: hideRowAction,
                  },
                  {
                    label: t('Print Receipt'),
                    Icon: PrintIcon,
                    onClick: (data: HistoryRow) => handlePrintClick(data),
                    hide: hideRowAction,
                  },
                ],
              }
        }
      />
      <ViewReceiptModal
        invoice={invoice}
        receiptType={isRefund(row) ? 'refund' : 'payment'}
        refundId={row?.refundId}
        {...viewModalProps.modalProps}
      />
      <SendReceiptModal
        invoice={invoice}
        receiptType={isRefund(row) ? 'refund' : 'payment'}
        refundId={row?.refundId}
        {...sendModalProps.modalProps}
      />
    </>
  );
};
