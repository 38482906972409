import { ElementType, ReactNode, useMemo } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { PersonStatus_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { differenceInYears } from 'date-fns';
import { PersonsV3 } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef, Text } from '@frontend/design-system';

export type PersonSecondaryInfoProps<E extends ElementType = 'span'> = PolymorphicComponentPropWithoutRef<
  E,
  {
    personId: string;
    person?: Person;
    groupIds?: string[];
  }
>;

/**
 * `PersonSecondaryInfo` is a component that displays secondary information about a person.
 * The information displayed includes the person's sex, age, and status.
 *
 * @param personId - The ID of the person to display information for.
 * @param person (optional) - The person to display information for. If provided, this will be used instead of fetching
 * the person.
 * @param groupIds (optional) - The IDs of the groups to which the person belongs. If provided, this will be used to
 * fetch the person. If not provided, the component will use the currently selected location IDs.
 */
export const PersonSecondaryInfo = <E extends ElementType = 'span'>({
  as,
  personId,
  person: providedPerson,
  groupIds,
  children,
  ...rest
}: PersonSecondaryInfoProps<E>) => {
  const { t } = useTranslation('thread-header');
  const { selectedLocationIds } = useAppScopeStore();
  const Component = as || 'span';

  const { data: fetchedPerson } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId,
      locationIds: groupIds?.length ? groupIds : selectedLocationIds,
    },
    {
      enabled: !providedPerson && !!personId && (!!groupIds?.length || !!selectedLocationIds.length),
    }
  );
  const person = providedPerson || fetchedPerson;
  const elementStrings = useMemo(() => {
    const results: string[] = [];
    if (person?.gender) results.push(person.gender);
    if (person?.birthdate && person.birthdate > '0001-01-01T00:00:00Z')
      results.push(`${differenceInYears(new Date(), new Date(person.birthdate))}`);
    if (person?.status !== undefined) {
      switch (person.status) {
        case PersonStatus_Enum.ACTIVE:
          results.push(t('Active'));
          break;
        case PersonStatus_Enum.ARCHIVED:
          results.push(t('Archived'));
          break;
        case PersonStatus_Enum.INACTIVE:
          results.push(t('Inactive'));
          break;
        case PersonStatus_Enum.DECEASED:
          results.push(t('Deceased'));
          break;
        case PersonStatus_Enum.NON_PATIENT:
          results.push(t('Non-Patient'));
          break;
        case PersonStatus_Enum.PROSPECTIVE:
          results.push(t('Prospective'));
          break;
      }
    }
    return results;
  }, [JSON.stringify(person)]);

  const elementsWithDividers = elementStrings.reduce<ReactNode[]>((acc, str, index) => {
    if (index !== 0) {
      acc.push(
        <Text key={`person-secondary-info-divider-${index}`} size='small' color='disabled'>
          •
        </Text>
      );
    }

    acc.push(
      <Text
        key={`person-secondary-info-item-${str}`}
        size='medium'
        css={{ color: theme.colors.neutral70, overflowX: 'hidden', textOverflow: 'ellipsis' }}
      >
        {str}
      </Text>
    );

    return acc;
  }, []);

  return (
    <Component
      css={{
        display: 'flex',
        gap: theme.spacing(0.5),
        alignItems: 'center',
        lineHeight: 1,
        textOverflow: 'ellipsis',
      }}
      {...rest}
    >
      {elementsWithDividers}
      {children}
    </Component>
  );
};
