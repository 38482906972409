import { useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { CountUnreadVoicemailsResponse } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/phone_records_api.pb';
import { LocationVoicemailBox } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/mailbox.pb';
import { MailBoxType, ViewStatus } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/voicemail.pb';
import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import dayjs from 'dayjs';
import { omit } from 'lodash-es';
import { InfiniteData, useQueryClient } from 'react-query';
import { Row } from 'react-table';
import { PhoneCallsQueries } from '@frontend/api';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { TagsV2, TagsUtils } from '@frontend/api-tag';
import { VoicemailApi, VoicemailTypes as VmTypes } from '@frontend/api-voicemails';
import { Chips } from '@frontend/chips';
import { EmptyStateConfig } from '@frontend/components';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation, i18next } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { formatPhoneNumber, isPartialPhoneNumber, isPhoneNumber, sanitizePhoneNumber } from '@frontend/phone-numbers';
import { useMutation } from '@frontend/react-query-helpers';
import { useAppScopeStore, useScopedInfiniteQuery, useScopedQuery } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  Chip,
  DownloadIcon,
  IconButton,
  LabelIcon,
  ListsIcon,
  MarkReadIcon,
  MarkUnreadIcon,
  MessageIcon,
  ModalControlModalProps,
  PhoneIcon,
  Table,
  TableInstance,
  TextLink,
  TrashIcon,
  UserIcon,
  VoicemailOverrideIcon,
  useAlert,
  useModalControl,
} from '@frontend/design-system';
import {
  AllCallsTable,
  PaginationSettings,
  VoicemailFiltersType,
  usePhonePageSettingShallowStore,
} from '../../hooks/use-phone-config-state-store';
import { usePhoneScopeStore } from '../../hooks/use-phone-scope-store';
import { useVoicemailTagMutation } from '../../hooks/use-voicemail-tag-mutations';
import { transcriptionTrackingId } from '../../pendo-tracking';
import { queryKeys } from '../../query-keys';
import { getFormattedCallerName, getFullName } from '../../utils';
import { CachedAudioScrubber } from '../all-calls/cached-audio-scrubber';
import { ProfileCard } from '../all-calls/profile-card';
import { CustomActionCell } from '../all-calls/shared-buttons';
import { MessageStatus, VoicemailQueryData } from '../all-calls/types';
import { TranscriptionContext } from '../transcription/types';
import { getTranscriptionUniqueKey } from '../transcription/utils';
import { AddTag, VoicemailTagSelector } from './voicemail-tag-selector';
import { downloadVoicemail, generateMediaPath } from './voicemail-utils';
import { Forwardvoicemail } from './voicemails-forward';
import { VoicemailsMultiFilters } from './voicemails-multi-filter.component';

export type ForwardVoicemailDynamicData = {
  rowIndex: number;
  vmId: string;
  currentVoicemailBoxName: string;
  currentVoicemailBoxId: string;
  mediaPath: string;
  mediaId: string;
};

export type ForwardVoicemailStaticData = {
  voicemailBoxList: LocationVoicemailBox[];
  onDelete: (req: VmTypes.DeletePayload) => void;
};

export type ForwardVoicemailModalData = ForwardVoicemailDynamicData & ForwardVoicemailStaticData;

const defaultForwardVoicemailModalData = {};

type SetPageConfigInput = Partial<PaginationSettings> | ((_: PaginationSettings) => Partial<PaginationSettings>);

type VoicemailTableDataRow = {
  contactName: string;
  contactId: string;
  channelId: string;
  mailboxId: string;
  forwardedMessageId: string | undefined;
  locationId: string;
  dateTime: string;
  readAt: string | undefined;
  mediaFilePath: string;
  mediaId: string;
  contactNumber: string;
  voiceMailBoxName: string;
  voicemailId: string;
  createdAt: string;
  tags: Tag[];
};

const defaultEmptyState = {
  type: 'voicemail',
  header: i18next.t('No Items to Display'),
  description: i18next.t('Voicemails will display here.'),
};

const useTags = () => {
  const { selectedOrgId, selectedLocationIds } = useAppScopeStore();
  const { data: tagData, isLoading } = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: selectedLocationIds },
  });
  const mappedTags = useMemo(() => {
    return (
      tagData?.tags.reduce((acc, tag) => {
        acc[tag.id] = tag;
        return acc;
      }, {} as Record<string, (typeof tagData)['tags'][number]>) ?? {}
    );
  }, [tagData]);

  return { mappedTags, isLoading };
};

const getFallbackCallerName = (contactNumber: string): string => {
  const sanitizedNumber = sanitizePhoneNumber(contactNumber ?? '');
  return !!sanitizedNumber ? getFormattedCallerName(sanitizedNumber) : i18next.t('Unknown', { ns: 'calls' });
};

export const MultiVoicemailsTable = () => {
  const alert = useAlert();
  const { t } = useTranslation('calls', { keyPrefix: 'voicemails' });
  const queryClient = useQueryClient();

  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { show, panelType, context, setShow } = useSlidePanelShallowStore<TranscriptionContext>(
    'setShow',
    'context',
    'panelType',
    'show'
  );

  const { selectedLocationIdsExcludingParent, selectedLocationIds } = usePhoneScopeStore();
  const { getLocationName } = useAppScopeStore();

  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');
  const {
    config,
    setPageSize,
    setPageNumber,
    setFilters: setVoicemailPageFilters,
  } = usePhonePageSettingShallowStore('config', 'setPageSize', 'setPageNumber', 'setFilters');

  const [emptyState, setEmptyState] = useState<EmptyStateConfig>(defaultEmptyState as EmptyStateConfig);

  const { isLoading: isLoadingVMTranscription, isFeatureHiddenInAllLocations: isVmTranscriptionHiddenInAllLocations } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: selectedLocationIds,
      enabled: !!selectedLocationIds.length,
      customizationFlag: Feature.VOICEMAIL_TRANSCRIPTION,
    });

  const isVMTranscriptionEnabled = !isLoadingVMTranscription && !isVmTranscriptionHiddenInAllLocations;

  const setFilters = (filters: VoicemailFiltersType) => {
    setVoicemailPageFilters(AllCallsTable.Voicemails, filters);
  };

  const defaultFilters: VoicemailFiltersType = useMemo(
    () => ({
      locationIds: selectedLocationIdsExcludingParent,
      mailboxLevelOptions: { mailboxIds: [], syncedDeviceSipProfileId: phoneConfig?.sipProfileId },
      messageLevelOptions: { status: MessageStatus.MESSAGE_STATUS_ALL },
    }),
    [selectedLocationIdsExcludingParent]
  );

  const pageNumber = config[AllCallsTable.Voicemails].pageNumber;
  const pageSize = config[AllCallsTable.Voicemails].pageSize;
  const filters = config[AllCallsTable.Voicemails].filters ?? defaultFilters;

  useEffect(() => {
    // initialize the filters
    if (!filters) {
      setFilters(defaultFilters);
    }
  }, []);

  const setPageConfig = (input: SetPageConfigInput) => {
    const data = typeof input === 'function' ? input({ pageSize, pageNumber }) : input;
    if (data.pageSize) setPageSize(AllCallsTable.Voicemails, data.pageSize);
    if (data.pageNumber) setPageNumber(AllCallsTable.Voicemails, data.pageNumber);
  };

  const searchQuery = useMemo(() => `${JSON.stringify({ ...filters })}-${pageSize}`, [filters, pageSize]);
  const tableInstanceRef = useRef<TableInstance<VoicemailTableDataRow[]>>();

  const { data: voicemailBoxList, isLoading: isVoicemailBoxListLoading } = useScopedQuery({
    queryKey: queryKeys.listVoicemailBox(phoneConfig?.sipProfileId ?? ''),
    queryFn: async () => {
      const { voicemailBoxes: data } = await VoicemailApi.listVoicemailBox({
        locationIds: selectedLocationIdsExcludingParent,
        syncedDeviceSipProfileId: phoneConfig?.sipProfileId,
        includeNonSharedMailboxes: true,
      });
      return data;
    },
  });

  /**
   * This is a map of locationId to a map of mailboxId to mailboxName.
   */

  const mailboxMap = useMemo(() => {
    return voicemailBoxList?.reduce((mailboxDetailsMap, curr) => {
      const { locationId, voicemailBoxes } = curr;
      if (!!locationId) {
        mailboxDetailsMap[locationId] = (voicemailBoxes ?? []).reduce((mailboxMap, mailbox) => {
          const { mailbox: mailboxData, mailboxType, deptInfo } = mailbox;
          if (!!mailboxData?.id) {
            if (mailboxType === MailBoxType.MAILBOX_TYPE_DEPARTMENT) {
              mailboxMap[mailboxData?.id] = deptInfo?.isMainline ? 'Main Line' : deptInfo?.departmentName ?? '--';
            } else {
              mailboxMap[mailboxData.id] = mailboxData.name ?? '--';
            }
          }
          return mailboxMap;
        }, {} as Record<string, string>);
      }
      return mailboxDetailsMap;
    }, {} as Record<string, Record<string, string>>);
  }, [voicemailBoxList]);

  const { mappedTags, isLoading: isTagDataLoading } = useTags();

  const {
    data: voicemailsData,
    isFetching,
    fetchNextPage,
    refetch,
  } = useScopedInfiniteQuery<VoicemailQueryData, unknown>({
    queryKey: queryKeys.multiVoicemails(searchQuery),
    queryFn: async ({ pageParam }) => {
      const CANARY = 1;
      const { hydratedVoicemails: data = [] } = await VoicemailApi.listMultiVoicemails({
        locationIds: selectedLocationIdsExcludingParent,
        lastVoicemailRecordId: pageParam?.lastId,
        endTime: pageParam?.createdAt,
        messageLevelOptions: filters.messageLevelOptions,
        mailboxLevelOptions: filters.mailboxLevelOptions,
        // We need to fetch more than the requested page size to determine if there is a next page
        limit: pageSize + CANARY,
      });

      const parsedData: VoicemailTableDataRow[] = data.map((voicemail) => {
        const { person, message, tagIds } = voicemail;

        return {
          // get the fullname from the person object if it exists. Get the callerName if it's an internal message
          contactName: getFullName(person?.firstName, person?.lastName, message?.isInternal ? message?.callerName : ''),
          contactId: person?.personId ?? '',
          locationId: message?.locationId ?? '',
          channelId: message?.channelId ?? '',
          mailboxId: message?.mailboxId ?? '',
          forwardedMessageId: message?.forwardedMessageId,
          readAt: message?.readAt,
          dateTime: dayjs(message?.createdAt).format('MMM DD YYYY, hh:mm A'),
          mediaFilePath: generateMediaPath(message, message?.locationId ?? ''),
          mediaId: message?.forwardedMessageId || message?.mediaId || '',
          contactNumber: formatPhoneNumber(message?.callerNumber),
          voiceMailBoxName: mailboxMap?.[message?.locationId ?? '']?.[message?.mailboxId ?? ''] ?? '--',
          voicemailId: message?.voicemailId ?? '',
          createdAt: message?.createdAt ?? '',
          tags: tagIds?.map((tagId) => mappedTags[tagId]).filter(Boolean) ?? [],
        };
      });

      const lastRowIndex = pageSize + CANARY > data.length ? data.length - 1 : data.length - 1 - CANARY;
      const lastRowData = parsedData[lastRowIndex];

      if (!pageParam?.lastId) {
        // whenever 1st page of voicemail data is fetched, we need to update the unread count as well to be consistent
        queryClient.refetchQueries([
          selectedLocationIds,
          ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? ''),
        ]);
      }

      return {
        data: parsedData.slice(0, pageSize),
        meta: {
          lastId: lastRowData?.voicemailId,
          createdAt: lastRowData?.createdAt,
          hasNext: parsedData.length > pageSize,
        },
      };
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.meta;
    },
    retry: false,
    refetchOnWindowFocus: false,
    // Parsing the data depends on the mailboxMap
    enabled: !!voicemailBoxList && !isTagDataLoading && !isVoicemailBoxListLoading,
  });

  useEffect(() => {
    setPageConfig({ pageNumber: 1 });
  }, [filters.locationIds, filters.mailboxLevelOptions, filters.messageLevelOptions]);

  useEffect(() => {
    const filterStillApplicableForSelectedLocations = filters.locationIds.every((locationId) =>
      selectedLocationIdsExcludingParent.includes(locationId)
    );

    if (filterStillApplicableForSelectedLocations) {
      setFilters({
        ...filters,
        locationIds: selectedLocationIdsExcludingParent,
        mailboxLevelOptions: {
          ...filters.mailboxLevelOptions,
          syncedDeviceSipProfileId: phoneConfig?.sipProfileId,
        },
      });
    } else {
      setPageConfig({ pageNumber: 1 });
      setFilters(defaultFilters);
    }
  }, [selectedLocationIdsExcludingParent, phoneConfig?.sipProfileId]);

  const getHasNext = () => {
    const pageMeta = voicemailsData?.pages[pageNumber - 1]?.meta;
    return pageMeta?.hasNext;
  };

  const getLocationChip = (locationId: string | undefined) => {
    if (!!locationId) {
      return <Chips.LocationChip>{getLocationName(locationId)}</Chips.LocationChip>;
    }
    return <span>{'--'}</span>;
  };

  const { mutate: onDelete } = useMutation((req: VmTypes.DeletePayload) => VoicemailApi.deleteVoicemailMedia(req), {
    onMutate: async ({ index }) => {
      queryClient.cancelQueries([selectedLocationIdsExcludingParent, ...queryKeys.multiVoicemails(searchQuery)]);

      const data = queryClient.getQueryData<InfiniteData<VoicemailQueryData>>([
        selectedLocationIdsExcludingParent,
        ...queryKeys.multiVoicemails(searchQuery),
      ]);

      if (data) {
        /**
         * When a row is deleted from a page, we want to shift everything past the deleted row up, and fill in the remaining space with the next page's data.
         *
         * If we're on the last page of known available data (data that we've already fetched), we might need to prefetch the next available page to
         * get the next row to fill in the gap. If there is no next page, then we don't need to fetch anything.
         */
        const pageIndexWithDeletedData = pageNumber - 1;
        const targetPageSlice = data.pages[pageIndexWithDeletedData];
        const needToPrefetch = data.pages.length === pageIndexWithDeletedData + 1 && targetPageSlice.meta.hasNext;

        /**
         * This is the data we will be referencing from henceforth.
         *
         * If we need to fetch a new page, we will use the new data as reference.
         */
        const refData = needToPrefetch
          ? await fetchNextPage({
              pageParam: {
                lastId: targetPageSlice.meta.lastId,
                createdAt: targetPageSlice.meta.createdAt,
              },
            }).then((res) => res.data ?? { pages: [], pageParams: [] })
          : data;

        const overallDeleteIndex = pageSize * (pageNumber - 1) + index;
        const allData = refData.pages.map((page) => page.data).flat();

        allData.splice(overallDeleteIndex, 1);

        /**
         * We now have a flat list of all rows (with the deleted item removed). These rows are not separated into pages.
         *
         * We will rebuild the pages from this list.
         */
        const newAllData = [];

        /**
         * Split the data into chunks of pageSize. This will be the data for our new pages
         */
        for (let i = 0; i < allData.length; i += pageSize) {
          const chunk = allData.slice(i, i + pageSize);
          newAllData.push(chunk);
        }

        /**
         * Build the page objects from the new page data chunks
         */
        const newPages = newAllData.map((slice, i, list) => {
          /**
           * We can determine that the last page in this new list has no next page if refData has 1 more page than the new list.
           * Marking this correctly will disable the pagination button to advance to the next page.
           */
          const thereUsedToBeMoreRows = i === list.length - 1 && data?.pages.length === list.length + 1;

          const hasNext = thereUsedToBeMoreRows ? false : refData.pages[i]?.meta?.hasNext;

          return {
            data: slice,
            meta: {
              lastId: slice[slice.length - 1]?.voicemailId,
              createdAt: slice[slice.length - 1]?.createdAt,
              hasNext,
            },
          };
        });

        const newPageData = {
          pages: newPages,
          pageParams: newPages.map((page, i) => (i === 0 ? undefined : page.meta)),
        };

        /**
         * Note: We are not invalidating any queries, just making optimistic updates.
         * The queries on this page will be refetched when the user navigates to this page.
         *
         * If there is an error before `setQueryData` is called, we would just show the error alert, and the cached data will be untouched.
         */
        queryClient.setQueryData(
          [selectedLocationIdsExcludingParent, ...queryKeys.multiVoicemails(searchQuery)],
          newPageData
        );

        if (!newPages[pageIndexWithDeletedData]) {
          /**
           * If the page we are on is now empty, we need to navigate to the previous page.
           *
           * pageNumber is 1-indexed, pageIndexWithDeletedData is 0-indexed
           */
          setPageConfig({ pageNumber: pageIndexWithDeletedData });
        }

        alert.success(t('voicemailDeletedSuccess'));
      }
    },
    onError: () => {
      alert.error(t('voicemailDeletedError'));
    },
    onSuccess: (_, { voicemailId }) => {
      if (panelType === 'voicemailTranscription' && context?.currentRowId === voicemailId) {
        setShow(false);

        /**
         * The code below is for progressing the transcription panel to the next row after a row is deleted.
         * This is no longer the direction we're going with, but keeping the code here for now in case we want to revisit this.
         */
        // const data = queryClient.getQueryData<InfiniteData<VoicemailQueryData>>([
        //   selectedLocationIdsExcludingParent,
        //   ...queryKeys.multiVoicemails(searchQuery),
        // ]);

        // if (data) {
        //   const rows = data.pages[pageNumber - 1] ? data.pages[pageNumber - 1].data : [];

        //   if (rows.length > index && context) {
        //     setShow(true, panelType, {
        //       ...context,
        //       // Keep the same index (which means we will progress down the list)
        //       currentRowId: rows[index].channelId ?? '',
        //       rows: data.pages[pageNumber - 1].data.map((row) => ({ ...row })),
        //     });
        //   } else {
        //     resetTranscriptPanel(setShow, context);
        //   }
        // }
      }
    },
  });

  // toggle voicemail status
  const { mutate: updateReadStatus, mutateAsync: updateReadStatusAsync } = useMutation(
    (payload: VmTypes.StatusPayload) => {
      const req = omit(payload, 'index');
      return VoicemailApi.setVoicemailViewStatus(req);
    },
    {
      onSuccess: (response, { index, status }) => {
        const data = queryClient.getQueryData<InfiniteData<VoicemailQueryData>>([
          selectedLocationIdsExcludingParent,
          ...queryKeys.multiVoicemails(searchQuery),
        ]);

        if (data && data.pages[pageNumber - 1]?.data) {
          const message = data.pages[pageNumber - 1].data?.[index];
          const totalUnreads = queryClient.getQueryData([
            selectedLocationIds,
            ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? ''),
          ]) as CountUnreadVoicemailsResponse;
          if (message) {
            message.readAt =
              status === ViewStatus.VIEW_STATUS_READ && response.voicemail?.readEpoch
                ? // @ts-ignore - This type is wrong, the schema says it should be a number but it isn't
                  new Date(parseInt(response.voicemail.readEpoch)).toISOString()
                : undefined;

            if (panelType === 'voicemailTranscription' && context) {
              setShow(show, panelType, {
                ...context,
                rows: data.pages[pageNumber - 1].data.map((row) => ({ ...row })),
              });
            }
            queryClient.setQueryData(
              [...selectedLocationIdsExcludingParent, ...queryKeys.multiVoicemails(searchQuery)],
              data
            );
          }
          const mailboxId = response.voicemail?.mailboxId ?? '';
          if (!totalUnreads?.countPerMailbox) {
            totalUnreads.countPerMailbox = {};
          }
          if (status === ViewStatus.VIEW_STATUS_READ) {
            const currentUnreads = totalUnreads?.countPerMailbox?.[mailboxId ?? ''] ?? 0;
            if (currentUnreads > 0) {
              totalUnreads.countPerMailbox[mailboxId] = currentUnreads - 1;
            }
          } else {
            const currentUnreads = totalUnreads?.countPerMailbox?.[mailboxId];
            totalUnreads.countPerMailbox[mailboxId] = (currentUnreads || 0) + 1;
          }
          queryClient.setQueryData(
            [
              selectedLocationIdsExcludingParent,
              ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(phoneConfig?.sipProfileId ?? ''),
            ],
            totalUnreads
          );
        }
      },
    }
  );
  const generateManualFilters = (
    modalProps: ModalControlModalProps,
    setShowNotificationBadge: (showNotification: boolean) => void
  ) => {
    return modalProps.show ? (
      <VoicemailsMultiFilters
        defaultFilters={defaultFilters}
        filters={filters}
        mailboxes={voicemailBoxList}
        modalProps={modalProps}
        setShowNotificationBadge={setShowNotificationBadge}
        onChange={setFilters}
      />
    ) : null;
  };

  const tableData = voicemailsData?.pages[pageNumber - 1]?.data || [];

  useEffect(() => {
    /**
     * This effect is to update the slide panel context with the new rows from the new table instance when the table instance changes.
     * The table instance will change if the user navigates away from the voicemail table, while having the transcription panel open.
     *
     * If we don't do this, each row's `toggleRowSelected` will not work as expected, since they are tied to the stale table instance.
     */
    if (show && panelType === 'voicemailTranscription' && tableInstanceRef.current) {
      const existingRow = context?.currentRowId ? tableInstanceRef.current?.rowsById[context.currentRowId] : null;

      if (existingRow) {
        if (!existingRow.isSelected) {
          existingRow.toggleRowSelected(true);
        }
        setShow(true, 'voicemailTranscription', {
          updateReadStatus: updateReadStatusAsync,
          deleteVoicemail: onDelete,
          currentRowId: existingRow.id,
          instanceId: tableInstanceRef.current?.tableId ?? '',
          rows: tableData.map((row) => ({ ...row })),
          toggleRowSelected: tableInstanceRef.current.toggleRowSelected,
          voicemailsQueryKey: queryKeys.multiVoicemails(searchQuery).filter(Boolean),
          openForwardVoicemailModal: openForwardVoicemailModal,
        });
      } else {
        resetTranscriptPanel(setShow, context);
      }
    }
  }, [tableInstanceRef.current?.tableId, tableData]);

  const [forwarVoicemailModalData, setForwardVoicemailModalData] = useState<ForwardVoicemailModalData>(
    defaultForwardVoicemailModalData as ForwardVoicemailModalData
  );
  const doubleActionControl = useModalControl();

  const openForwardVoicemailModal = (data: ForwardVoicemailDynamicData) => {
    setForwardVoicemailModalData({ ...data, voicemailBoxList: voicemailBoxList || [], onDelete: onDelete });
    doubleActionControl.openModal();
  };

  const getShortHandName = (callInfo: VoicemailTableDataRow): string => {
    const { contactName, contactNumber } = callInfo;
    return contactName !== 'Unknown' ? contactName : getFallbackCallerName(contactNumber);
  };

  return (
    <>
      <Forwardvoicemail modalProps={doubleActionControl.modalProps} forwardData={forwarVoicemailModalData} />
      <div css={styles.wrapper}>
        <Table
          uniqueRowId={(row, index) => {
            return getTranscriptionUniqueKey(row) ?? index;
          }}
          tableInstanceId='multi-voicemails'
          instanceRef={tableInstanceRef}
          globalTrackingId='phone-portal-weave2.0-voicemails'
          data={tableData}
          keepPreviousDataWhileLoading={tableData.length > 0}
          isLoading={isFetching || isVoicemailBoxListLoading}
          fullHeight
          isPaginated
          hasResponsiveColWidths
          hasResizeColumns
          hasFilterColumns
          hasGlobalSearch
          manualFilters
          manualFiltersOptions={{
            initialBadgeState:
              filters.mailboxLevelOptions.mailboxIds && filters.mailboxLevelOptions.mailboxIds.length > 0,
          }}
          manualFiltersRender={generateManualFilters}
          globalSearchConfig={{
            initialValue: filters.messageLevelOptions.callerNumber || filters.messageLevelOptions.patientName || '',
            searchHandler: (searchText) => {
              const sanitizedNumber = sanitizePhoneNumber(searchText);
              const numberSearch =
                isPhoneNumber(sanitizedNumber) || isPartialPhoneNumber(sanitizedNumber) ? sanitizedNumber : '';
              const nameSearch =
                !isPhoneNumber(sanitizedNumber) && !isPartialPhoneNumber(sanitizedNumber) ? searchText : '';

              if (!!isPartialPhoneNumber(searchText)) {
                setEmptyState({
                  type: 'voicemail',
                  header: t('Phone Number Searches Must be Exactly 10 Digits'),
                  description: t('Phone number searches must be exactly 10 digits in order to return a match.'),
                });
              } else {
                setEmptyState(defaultEmptyState as EmptyStateConfig);
              }

              setFilters({
                ...filters,
                messageLevelOptions: {
                  ...filters.messageLevelOptions,
                  callerNumber: numberSearch,
                  patientName: nameSearch,
                },
              });
            },
            placeholder: t('Search name or 10-digit number'),
            debounceDelay: 1000,
          }}
          emptyStateConfig={emptyState}
          colConfig={[
            {
              Header: t('Contact Name'),
              accessor: (row) => row,
              cellRenderer: (_, row) =>
                row ? (
                  <ProfileCard
                    trackingId={`phone-portal-weave2.0-voicemails-profilebtn-tablerowaction-viewprofile`}
                    personName={row.contactName}
                    locationId={row.locationId}
                    personID={row.contactId}
                    markedUnread={!row.readAt}
                  />
                ) : null,
              disableSortBy: true,
              id: 'contactName',
              mobileLayoutConfig: {
                order: -1,
                spanFullWidth: false,
                hideHeader: true,
                leftShift: 48,
              },
              width: 160,
            },
            {
              Header: t('Time'),
              accessor: 'dateTime',
              disableSortBy: true,
              id: 'dateTime',
              mobileLayoutConfig: {
                spanFullWidth: true,
              },
              width: 200,
            },
            {
              Header: t('Voicemail'),
              cellRenderer: (_, rowData, row) =>
                rowData ? (
                  <>
                    {rowData.mediaFilePath ? (
                      <CachedAudioScrubber
                        onPlay={() => {
                          // There should always be a row
                          if (rowData && row?.index !== undefined) {
                            updateReadStatus({
                              index: row.index,
                              voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                              status: ViewStatus.VIEW_STATUS_READ,
                            });
                          }
                        }}
                        filePath={rowData.mediaFilePath}
                        mediaId={rowData.mediaId}
                      />
                    ) : (
                      <span css={styles.noRecordingText}>{'--'}</span>
                    )}
                  </>
                ) : null,
              disableSortBy: true,
              id: 'voicemailMessage',
              mobileLayoutConfig: {
                spanFullWidth: true,
              },
              minWidth: 300,
            },
            {
              Header: t('Contact Number'),
              accessor: 'contactNumber',
              disableSortBy: true,
              id: 'contactPhone',
              width: 150,
            },
            {
              Header: t('Location'),
              accessor: 'locationId',
              cellRenderer: (locationId) => getLocationChip(locationId),
              id: 'location',
              omit: !(selectedLocationIdsExcludingParent.length > 1),
              disableSortBy: true,
            },
            {
              Header: t('Voicemail Box'),
              accessor: 'voiceMailBoxName',
              disableSortBy: true,
              id: 'voicemailBoxType',
            },
            {
              Header: t('Tags'),
              accessor: (item) => item,
              id: 'tags',
              disableSortBy: true,
              cellRenderer: ({ tags, voicemailId }, _rowData, row, _1, _2, tableInstance) => {
                const showPanel = useShowTranscriptionPanel({
                  row,
                  tableInstance,
                  updateReadStatus: updateReadStatusAsync,
                  deleteVoicemail: onDelete,
                  voicemailsQueryKey: queryKeys.multiVoicemails(searchQuery).filter(Boolean),
                  sectionFocus: ['tags'],
                  openForwardVoicemailModal: openForwardVoicemailModal,
                });

                let isEditing = false;
                if (row?.state && 'isEditing' in row.state && typeof row.state.isEditing === 'boolean') {
                  isEditing = row.state.isEditing;
                }

                return row ? (
                  <TagColumn
                    tags={tags}
                    voicemailId={voicemailId}
                    showPanel={showPanel}
                    queryKey={queryKeys.multiVoicemails(searchQuery).filter(Boolean)}
                    isEditing={isEditing}
                    setIsEditing={(val) => row.setState({ isEditing: val })}
                  />
                ) : null;
              },
            },
            /**
             * This column relies on values from the other columns.
             * Please make sure to consider this when modify the columns that are referenced below
             */
            {
              Header: '',
              cellRenderer: (_, rowData, row, _1, _2, tableInstance) => {
                if (!rowData) {
                  return null;
                }
                const { triggerProps: callTriggerProps, Modal: PhoneCallModal } = ActionsUI.actions.usePhoneCallAction({
                  context: {
                    personId: rowData.contactId,
                    phoneNumber: rowData.contactNumber,
                    useOutboundNumber: true,
                    locationId: rowData.locationId,
                  },
                });

                const {
                  triggerProps: messageTriggerProps,
                  Modal: MessageModal,
                  disabled: disableMessageAction,
                } = ActionsUI.actions.useMessageAction({
                  context: {
                    personId: rowData.contactId,
                    locationId: rowData.locationId,
                    phoneNumber: rowData.contactNumber,
                  },
                });

                const actions = [
                  {
                    Icon: PhoneIcon,
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-call',
                    label: `${t('Call')} ${getShortHandName(rowData)}`,
                    onClick: () => {
                      callTriggerProps?.onClick();
                    },
                  },
                  {
                    Icon: MessageIcon,
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-message',
                    disabled: disableMessageAction,
                    label: `${t('Message')} ${getShortHandName(rowData)}`,
                    onClick: () => {
                      messageTriggerProps?.onClick();
                    },
                  },
                  {
                    label: t('View Profile'),
                    Icon: UserIcon,
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-viewprofile',
                    disabled: !rowData.contactId,
                    onClick: () => {
                      if (!!rowData.contactId) {
                        setPersonId(rowData.contactId, true, rowData.locationId);
                        setShow(true, 'contact');
                      }
                    },
                  },
                  {
                    label: t('Forward Voicemail'),
                    Icon: VoicemailOverrideIcon,
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-forwardvoicemail',
                    onClick: () => {
                      openForwardVoicemailModal({
                        rowIndex: row?.index,
                        vmId: rowData.voicemailId == '' ? rowData.channelId : rowData.voicemailId,
                        mediaId: rowData.mediaId,
                        mediaPath: rowData.mediaFilePath,
                        currentVoicemailBoxId: rowData.mailboxId,
                        currentVoicemailBoxName: rowData.voiceMailBoxName,
                      } as ForwardVoicemailDynamicData);
                    },
                  },
                  {
                    label: t('Add Tag'),
                    Icon: LabelIcon,
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-tagvoicemail',
                    onClick: () => {
                      if (row) {
                        row.setState({ isEditing: true });
                      }
                    },
                  },
                  {
                    label: t('Download Voicemail'),
                    Icon: DownloadIcon,
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-downloadvoicemail',
                    onClick: () => {
                      downloadVoicemail(rowData.mediaFilePath, rowData.contactName ?? 'unknown', rowData.dateTime);
                    },
                  },
                  {
                    label: t('Mark Unread'),
                    Icon: MarkUnreadIcon,
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-togglestatus',
                    hide: !rowData.readAt,
                    onClick: () => {
                      if (!!row) {
                        updateReadStatus({
                          index: row.index,
                          voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                          status: ViewStatus.VIEW_STATUS_UNREAD,
                        });
                      }
                    },
                  },
                  {
                    label: t('Mark Read'),
                    Icon: MarkReadIcon,
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-togglestatus',
                    hide: !!rowData.readAt,
                    onClick: () => {
                      if (!!row) {
                        updateReadStatus({
                          index: row.index,
                          voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                          status: ViewStatus.VIEW_STATUS_READ,
                        });
                      }
                    },
                  },
                  {
                    label: t('Delete Voicemail'),
                    Icon: TrashIcon,
                    trackingId: 'phone-portal-weave2.0-voicemails-btn-tablerowaction-deletevoicemail',
                    destructive: true,
                    onClick: () => {
                      if (!!row) {
                        onDelete({ voicemailId: rowData?.forwardedMessageId || rowData.channelId, index: row.index });
                      }
                    },
                  },
                ];
                return (
                  <>
                    <div style={{ width: '100%', display: 'flex', gap: theme.spacing(1), justifyContent: 'center' }}>
                      {isVMTranscriptionEnabled && row && tableInstance && (
                        <TranscriptColumn
                          row={row}
                          tableInstance={tableInstance}
                          updateReadStatus={updateReadStatusAsync}
                          deleteVoicemail={onDelete}
                          voicemailsQueryKey={queryKeys.multiVoicemails(searchQuery).filter(Boolean)}
                          openForwardVoicemailModal={openForwardVoicemailModal}
                        />
                      )}
                      <CustomActionCell actions={actions} tableTrackingId={'phone-portal-weave2.0-voicemails'} />
                    </div>
                    {PhoneCallModal}
                    {MessageModal}
                  </>
                );
              },
              disableSortBy: true,
              disableColumnFilter: true,
              id: 'actions',
              sticky: 'right',
              width: isVMTranscriptionEnabled ? 100 : 80,
              mobileLayoutConfig: {
                order: -1,
                hideHeader: true,
                spanFullWidth: false,
              },
            },
          ]}
          /**
           * (!)
           * Hide the transcription panel after pagination settings have changed to prevent the panel from using stale data.
           * More info in TranscriptionPanel.
           */
          manualPaginationConfig={{
            handleChange: (action: 'next' | 'prev') => {
              if (action === 'next') {
                /**
                 * We potentially refetch data if the user navigates to the a page where data is already cached.
                 *
                 * The cached page could have less data than expected with optimistic updates on deletion,
                 * so we refresh only if it has a next page and doesn't have a full page of data.
                 */
                if (voicemailsData?.pages[pageNumber]) {
                  refetch<VoicemailQueryData>({
                    refetchPage: (lastPage) => {
                      return lastPage.meta.hasNext && lastPage.data.length < pageSize;
                    },
                  });
                } else {
                  fetchNextPage();
                }
                setPageConfig((prev) => ({ pageNumber: prev.pageNumber + 1 }));
                /** (!) */
                resetTranscriptPanel(setShow, context);
              } else {
                setPageConfig((prev) => ({ pageNumber: prev.pageNumber - 1 }));
                /** (!) */
                resetTranscriptPanel(setShow, context);
              }
            },
            hasNext: getHasNext(),
            hasPrevious: true,
            page: pageNumber,
            defaultRowsPerPage: pageSize,
            onNumRowsChange: (num) => {
              setPageConfig({ pageNumber: 1, pageSize: num });
              /** (!) */
              resetTranscriptPanel(setShow, context);
            },
            rowsPerPageOptions: [10, 25, 50, 75],
          }}
        />
      </div>
    </>
  );
};

const TagList = ({ tags, voicemailId, queryKey }: { tags: Tag[]; voicemailId: string; queryKey: string[] }) => {
  const { t } = useTranslation('calls');
  const alerts = useAlert();
  const { removeTag } = useVoicemailTagMutation({
    queryKey,
    voicemailId,
    onSuccess: ({ op }) => {
      if (op === 'remove') alerts.success(t('Successfully removed tag.'));
    },
    onFailure: ({ op }) => {
      if (op === 'remove') alerts.error(t('Failed to remove tag.'));
    },
  });
  const representativeTag = tags[0];

  return (
    <>
      <Chip.Tag
        isRemovable
        onRemoveClick={() => {
          removeTag(representativeTag);
        }}
        css={{ width: 'auto' }}
        color={TagsUtils.convertStringToTagColor(representativeTag.color)}
      >
        {representativeTag.name}
      </Chip.Tag>
    </>
  );
};

const styles = {
  wrapper: css`
    flex: 1;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: ${theme.spacing(1)};
    position: relative;
  `,

  noRecordingText: css`
    color: ${theme.colors.neutral90};
    display: inline-block;
    text-align: left;
    width: 100%;
  `,
};

const resetTranscriptPanel = (
  setShow: ReturnType<typeof useSlidePanelShallowStore<TranscriptionContext>>['setShow'],
  context: ReturnType<typeof useSlidePanelShallowStore<TranscriptionContext>>['context']
) => {
  context?.toggleRowSelected(context.currentRowId, false);
  setShow(false, 'voicemailTranscription', undefined);
};

const useShowTranscriptionPanel = ({
  row,
  tableInstance,
  updateReadStatus,
  deleteVoicemail,
  voicemailsQueryKey,
  sectionFocus,
  openForwardVoicemailModal,
}: {
  row?: Row<VoicemailTableDataRow>;
  tableInstance?: TableInstance<VoicemailTableDataRow>;
  updateReadStatus: (payload: VmTypes.StatusPayload) => Promise<VmTypes.SetVoicemailViewStatusResponse>;
  deleteVoicemail: (payload: VmTypes.DeletePayload) => void;
  voicemailsQueryKey: string[];
  sectionFocus?: TranscriptionContext['sectionFocus'];
  openForwardVoicemailModal: (data: ForwardVoicemailDynamicData) => void;
}) => {
  const { setShow } = useSlidePanelShallowStore<TranscriptionContext>('setShow', 'panelType', 'show', 'context');
  if (!row || !tableInstance) {
    return () => {};
  }

  return () =>
    setShow(true, 'voicemailTranscription', {
      updateReadStatus,
      deleteVoicemail,
      instanceId: tableInstance.tableId,
      currentRowId: row.id,
      rows: tableInstance.rows.map((row) => ({ ...row.original })),
      toggleRowSelected: tableInstance.toggleRowSelected,
      voicemailsQueryKey,
      sectionFocus,
      openForwardVoicemailModal,
    });
};

const TranscriptColumn = ({
  row,
  tableInstance,
  updateReadStatus,
  deleteVoicemail,
  voicemailsQueryKey,
  openForwardVoicemailModal,
}: {
  row: Row<VoicemailTableDataRow>;
  tableInstance: TableInstance<VoicemailTableDataRow>;
  updateReadStatus: (payload: VmTypes.StatusPayload) => Promise<VmTypes.SetVoicemailViewStatusResponse>;
  deleteVoicemail: (payload: VmTypes.DeletePayload) => void;
  voicemailsQueryKey: string[];
  openForwardVoicemailModal: (data: ForwardVoicemailDynamicData) => void;
}) => {
  const { t } = useTranslation('calls');
  const { setShow, panelType, show, context } = useSlidePanelShallowStore<TranscriptionContext>(
    'setShow',
    'panelType',
    'show',
    'context'
  );
  const showPanel = useShowTranscriptionPanel({
    row,
    tableInstance,
    updateReadStatus,
    deleteVoicemail,
    voicemailsQueryKey,
    openForwardVoicemailModal,
  });

  return (
    <IconButton
      onClick={() => {
        if (show && panelType === 'voicemailTranscription' && context?.currentRowId === row.id) {
          resetTranscriptPanel(setShow, context);
        } else {
          showPanel();
        }
      }}
      trackingId={transcriptionTrackingId({ subComponent: 'table', context: 'panel-trigger' })}
      label={t('Transcription')}
      size='small'
    >
      <ListsIcon color={row.isSelected ? 'primary' : 'subdued'} />
    </IconButton>
  );
};

const TagColumn = ({
  tags,
  voicemailId,
  queryKey,
  showPanel,
  isEditing,
  setIsEditing,
}: {
  tags: Tag[];
  voicemailId: string;
  queryKey: string[];
  showPanel: () => void;
  isEditing?: boolean;
  setIsEditing: (isEditing: boolean) => void;
}) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'minmax(0px, 1fr) auto auto',
        alignItems: 'center',
        gap: theme.spacing(1),
      }}
    >
      {tags.length > 0 && <TagList tags={tags} voicemailId={voicemailId} queryKey={queryKey} />}
      {!isEditing && tags.length > 1 && (
        <TextLink
          css={{
            ':hover': {
              textDecoration: 'none',
            },
          }}
          onClick={() => {
            showPanel();
          }}
        >
          +{tags.length - 1}
        </TextLink>
      )}
      {!isEditing && tags.length === 0 && <AddTag voicemailId={voicemailId} queryKey={queryKey} />}

      {isEditing && (
        <VoicemailTagSelector
          voicemailId={voicemailId}
          queryKey={queryKey}
          existingTagIds={tags.map((tag) => tag.id)}
          onClose={() => {
            setIsEditing(false);
          }}
          onSelect={() => {
            setIsEditing(false);
          }}
        />
      )}
    </div>
  );
};
