import { memo, useCallback, useMemo } from 'react';
import { AnalyticsCommonTypes, PracticeAnalyticsAggregations, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart, YAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useAnalyticsOrgLocations, usePracticeAnalyticsShallowStore } from '../../hooks';
import { formatters } from '../../utils';
import { DemoChip } from '../demo-chip';
import { LocationChip } from '../location-chip';

type Type = 'hygiene' | 'non-hygiene';

type LabelAndColors = {
  hygienePatients: AnalyticsCommonTypes.StringRecord;
  nonHygienePatients: AnalyticsCommonTypes.StringRecord;
};

type Props = PracticeAnalyticsTypes.MetricProps & {
  colors: LabelAndColors;
  data?: ReturnType<typeof PracticeAnalyticsAggregations.activePatientsScheduledDetails>;
  isLoading?: boolean;
  labels: LabelAndColors;
};

type RenderChart = {
  colors: AnalyticsCommonTypes.StringRecord;
  data: BarChartData;
  labels: AnalyticsCommonTypes.StringRecord;
  type: Type;
};

export const UnscheduledPatientsMetricMulti = memo(({ colors, data, isLoading, labels }: Props) => {
  const { t } = useTranslation('analytics');
  const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });
  const { showDemoChipAndBanner } = usePracticeAnalyticsShallowStore('showDemoChipAndBanner');

  const chartData = useMemo(() => {
    const entries = Object.entries(data?.rawData ?? {});

    const hygienePatients: BarChartData = {
      groups: entries.map(([locationId, locationDetails]) => ({
        name: locationNames[locationId] || locationId,
        values:
          locationDetails?.location.unscheduledHygienePatients?.buckets.reduce((acc, { label, total }) => {
            let labelMod = label.split(' ')[0];
            if (labelMod.includes('not')) {
              labelMod = label;
            }
            return { ...acc, [labelMod]: total };
          }, {}) || {},
      })),
    };

    const nonHygienePatients: BarChartData = {
      groups: entries.map(([locationId, locationDetails]) => ({
        name: locationNames[locationId] || locationId,
        values:
          locationDetails?.location.unscheduledNonhygienePatients?.buckets.reduce(
            (acc, { label, total }) => ({
              ...acc,
              [label.replaceAll('last visit', '').replaceAll('months ago', '').trim()]: total,
            }),
            {}
          ) || {},
      })),
    };

    return {
      hygienePatients,
      nonHygienePatients,
    };
  }, [locationNames, data]);

  const renderChart = useCallback(
    ({ colors, data, labels, type }: RenderChart) => {
      return (
        <Chart colors={colors} isLoading={isLoading} labels={labels}>
          <Chart.Header
            bottomElement={
              <Text color='subdued' size='medium'>
                {t('Filter by Months Overdue')}
              </Text>
            }
            leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
            title={type === 'hygiene' ? 'Unscheduled Hygiene Patients' : 'Unscheduled Other Patients'}
          />
          <Chart.Legends style={{ margin: theme.spacing(2, 0) }} />
          <Chart.BarChart
            appearance={{
              collectiveTooltip: true,
              customTooltipTitle: ({ groupName }) => <LocationChip locationName={groupName} noTruncating />,
              customYAxisTick: ({ labels, groupName, ...rest }) => {
                return <YAxisLabelValueTick {...rest} clipLength={25} label={labels?.[groupName] || groupName} />;
              },
              groupsGap: 24,
              layout: 'vertical',
              margin: { left: 114 },
              mode: 'stacked',
              showGridLines: true,
              showXAxis: true,
              showYAxis: true,
            }}
            data={data}
            formatValue={formatters.value.format}
          />
        </Chart>
      );
    },
    [chartData, isLoading]
  );

  return (
    <>
      {renderChart({
        colors: colors.hygienePatients,
        data: chartData.hygienePatients,
        labels: labels.hygienePatients,
        type: 'hygiene',
      })}

      {renderChart({
        colors: colors.nonHygienePatients,
        data: chartData.nonHygienePatients,
        labels: labels.nonHygienePatients,
        type: 'non-hygiene',
      })}
    </>
  );
});

UnscheduledPatientsMetricMulti.displayName = 'UnscheduledPatientsMetricMulti';
