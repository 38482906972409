import { EmailCampaign } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-campaign/v1/emailcampaign.pb';
import { Campaign } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { SortDirection } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { getDirectionStorageKey } from './direction-storage';

export const sortCampaigns = <T extends Campaign | EmailCampaign>(campaigns: T[]): T[] =>
  campaigns?.filter(Boolean)?.sort((a, b) => {
    const sortDirection = getDirectionStorageKey();
    if (a.startSendAt === b.startSendAt) return 0;
    if (sortDirection === SortDirection.DESCENDING) return b.startSendAt?.localeCompare(a.startSendAt!) ?? 0;
    return a.startSendAt?.localeCompare(b.startSendAt!) ?? 0;
  }) ?? [];
