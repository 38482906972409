import { memo, useCallback, useMemo } from 'react';
import { CategoryBarChartData, Chart, YAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useAnalyticsOrgLocations, usePracticeAnalyticsShallowStore } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { DemoChip } from '../demo-chip';

type Props = {
  data?: CategoryBarChartData;
  isLoading?: boolean;
  isLocationsComparison?: boolean;
  onGroupClick?: ((locationId: string) => void) | null;
};

const pratitionerComparisonColors = {
  acceptedPercent: theme.colors.warning50,
  avgPractitioner: theme.colors.primary40,
  diagnosedPercent: theme.colors.warning50,
  practitioner: theme.colors.warning50,
  topPractitioner: theme.colors.secondary.seaweed30,
  unscheduledTreatment: theme.colors.warning50,
};

const locationsComparisonColors = {
  acceptedPercent: theme.colors.secondary.eggplant20,
  diagnosedPercent: theme.colors.secondary.eggplant20,
  unscheduledTreatment: theme.colors.secondary.eggplant20,
  yourPractice: theme.colors.secondary.eggplant20,
};

export const PractitionerAnalysisCategoryChart = memo(
  ({ data = {}, isLoading, isLocationsComparison, onGroupClick = null }: Props) => {
    const { t } = useTranslation('analytics');
    const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });
    const { showDemoChipAndBanner } = usePracticeAnalyticsShallowStore('showDemoChipAndBanner');

    // Get top and total values for each key
    const processedValues = useMemo(() => {
      const values = Object.values(data || {}).reduce(
        (acc, value) => {
          acc.sum.acceptedPercent += value.acceptedPercent;
          acc.sum.diagnosedPercent += value.diagnosedPercent;
          acc.sum.unscheduledTreatment += value.unscheduledTreatment;

          acc.top.acceptedPercent = Math.max(acc.top.acceptedPercent, value.acceptedPercent);
          acc.top.diagnosedPercent = Math.max(acc.top.diagnosedPercent, value.diagnosedPercent);
          acc.top.unscheduledTreatment = Math.max(acc.top.unscheduledTreatment, value.unscheduledTreatment);

          return acc;
        },
        {
          top: {
            acceptedPercent: 0,
            diagnosedPercent: 0,
            unscheduledTreatment: 0,
          },
          sum: {
            acceptedPercent: 0,
            diagnosedPercent: 0,
            unscheduledTreatment: 0,
          },
        }
      );

      return {
        ...values,
        data,
      };
    }, [data]);

    const labels = useMemo(() => {
      return {
        acceptedPercent: t('Patients Accepted'),
        avgPractitioner: t('Average Practitioner'),
        diagnosedPercent: t('Patients Diagnosed'),
        practitioner: t('Practitioner'),
        topPractitioner: t('Top Practitioner'),
        unscheduledTreatment: t('Unscheduled Treatment'),
        yourPractice: t('Your Practice'),
      };
    }, []);

    const handleGroupClick = useCallback(({ groupName }: { groupName: string }) => {
      onGroupClick?.(groupName);
    }, []);

    const dataLength = useMemo(() => Object.keys(processedValues.data).length, [processedValues.data]);

    return (
      <Chart
        colors={isLocationsComparison ? locationsComparisonColors : pratitionerComparisonColors}
        isLoading={isLoading}
        labels={labels}
      >
        <Chart.Header leftElement={showDemoChipAndBanner ? <DemoChip /> : null} title={t('Practitioner Analysis')} />
        <Chart.Legends
          customData={
            isLocationsComparison
              ? {
                  yourPractice: {
                    label: labels['yourPractice'],
                  },
                }
              : {
                  practitioner: {
                    label: labels['practitioner'],
                  },
                  avgPractitioner: {
                    label: labels['avgPractitioner'],
                  },
                  topPractitioner: {
                    label: labels['topPractitioner'],
                  },
                }
          }
          style={{ marginBottom: theme.spacing(2) }}
        />
        <Chart.CategoryBarChart
          appearance={{
            customTooltipTitle: ({ groupName }) => (isLocationsComparison ? locationNames[groupName] : groupName),
            customYAxisTick: ({ labels, groupName, ...rest }) => {
              const label = (isLocationsComparison ? locationNames[groupName] : labels?.[groupName]) || groupName;
              return <YAxisLabelValueTick {...rest} clipLength={25} label={label} />;
            },
            formatters: {
              acceptedPercent: formatters.percent.appendPercent,
              diagnosedPercent: formatters.percent.appendPercent,
              unscheduledTreatment: formatters.currency.format,
            },
            maxValues: {
              acceptedPercent: Math.ceil(processedValues.top.acceptedPercent / 100) * 100,
              diagnosedPercent: Math.ceil(processedValues.top.diagnosedPercent / 100) * 100,
            },
            xAxisTickFormatters: {
              acceptedPercent: formatters.percent.appendPercent,
              diagnosedPercent: formatters.percent.appendPercent,
              unscheduledTreatment: (value) => `$${formatters.value.shortenNumber(value)}`,
            },
          }}
          data={processedValues.data}
          markers={
            isLocationsComparison
              ? undefined
              : {
                  acceptedPercent: [
                    {
                      id: 'avgPractitioner',
                      value: processedValues.sum.acceptedPercent / dataLength,
                    },
                    {
                      id: 'topPractitioner',
                      value: processedValues.top.acceptedPercent,
                    },
                  ],
                  diagnosedPercent: [
                    {
                      id: 'avgPractitioner',
                      value: processedValues.sum.diagnosedPercent / dataLength,
                    },
                    {
                      id: 'topPractitioner',
                      value: processedValues.top.diagnosedPercent,
                    },
                  ],
                  unscheduledTreatment: [
                    {
                      id: 'avgPractitioner',
                      value: processedValues.sum.unscheduledTreatment / dataLength,
                    },
                    {
                      id: 'topPractitioner',
                      value: processedValues.top.unscheduledTreatment,
                    },
                  ],
                }
          }
          onGroupClick={onGroupClick && handleGroupClick}
          trackingIdBase={trackingIds.practiceAnalytics.practitionerAnalysisChart}
        />
      </Chart>
    );
  }
);

PractitionerAnalysisCategoryChart.displayName = 'PractitionerAnalysisCategoryChart';
