import { useEffect } from 'react';
import { css } from '@emotion/react';
import DOMPurify from 'dompurify';
import { theme } from '@frontend/theme';
import { Modal, useModalLoadingState } from '@frontend/design-system';
import { useFetchRefundReceipt } from '../receipt-data-fetching';
import { ReceiptError } from '../ReceiptError';

type RefundReceiptModalProps = {
  paymentId: string;
  refundId: string;
};

export const RefundReceiptModal = ({ paymentId, refundId }: RefundReceiptModalProps) => {
  const { setLoading } = useModalLoadingState();

  const refundReceipt = useFetchRefundReceipt(paymentId, refundId);

  useEffect(() => {
    setLoading(refundReceipt.isLoading);
  }, [refundReceipt.isLoading]);

  return (
    <Modal.Body
      css={css`
        min-height: 300px;
      `}
    >
      {!refundReceipt.isLoading && refundReceipt.data && (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(refundReceipt.data, { WHOLE_DOCUMENT: true }),
          }}
        />
      )}
      {!refundReceipt.isLoading && refundReceipt.isError && refundReceipt.error ? (
        <ReceiptError error={refundReceipt.error} />
      ) : null}
      {refundReceipt.isLoading && (
        <div
          css={css`
            border: 1px solid ${theme.colors.neutral10};
            width: 450px;
            height: 362px;
          `}
        >
          <div
            css={css`
              background-color: ${theme.colors.neutral20};
              width: 100%;
              height: 84px;
            `}
          />
        </div>
      )}
    </Modal.Body>
  );
};
