import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  padding: ${theme.spacing(0, 2)};

  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const headerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(2)};
`;
