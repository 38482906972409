import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { startCase } from 'lodash-es';
import { useQueryClient } from 'react-query';
import { PhoneCallsApi, PhoneCallsTypes } from '@frontend/api';
import { DepartmentsApi, DepartmentsTypes } from '@frontend/api-departments';
import { CoreACLs, getWeaveToken, hasACL } from '@frontend/auth-helpers';
import { Chips } from '@frontend/chips';
import { EmptyStateConfig } from '@frontend/components';
import { ActionsUI } from '@frontend/contact-actions';
import { useCustomContactFormSlidePanel } from '@frontend/create-contact-panel';
import appConfig from '@frontend/env';
import { useTranslation, i18next } from '@frontend/i18n';
import { formatPhoneNumber, isPartialPhoneNumber, isPhoneNumber, sanitizePhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore, useScopedInfiniteQuery } from '@frontend/scope';
import { useContactPanelShallowStore, useFeatureFlagShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  AudioScrubber,
  ChipVariants,
  MessageIcon,
  PhoneIcon,
  Table,
  Chip,
  UserIcon,
  UserManagementIcon,
  useAlert,
} from '@frontend/design-system';
import {
  AllCallsTable,
  CallRecordsFiltersType,
  DEFAULT_TIME_PERIOD,
  PaginationSettings,
  usePhonePageSettingShallowStore,
} from '../../hooks/use-phone-config-state-store';
import { usePhoneScopeStore } from '../../hooks/use-phone-scope-store';
import { queryKeys } from '../../query-keys';
import {
  getFormattedCallerName,
  defaultDateRangeMap,
  formatEndDateWithTimeZone,
  formatStartDateWithTimeZone,
  getFullName,
  getTimePeriodForDateRange,
  useTimePeriodFilterLabels,
} from '../../utils';
import { ProfileCard } from '../all-calls/profile-card';
import { CustomActionCell } from '../all-calls/shared-buttons';
import { HydratedCallRecord } from '../all-calls/types';
import { RecentCallsChipFilter, RecentCallsTrayFilter } from './calls-filter';

type RecentCallProps = {
  setPageSubtitle: (subtitle: string) => void;
};

const getStatusChipColor = (status: PhoneCallsTypes.CallLog['Status']): ChipVariants => {
  switch (status) {
    case 'abandoned':
      return 'warn';
    case 'missed':
      return 'critical';
    case 'answered':
      return 'primary';
    case 'forwarded':
      return 'eggplant';
    default:
      return 'outline';
  }
};

type PageParams = {
  lastId: string;
  endDate: string;
};

type SetPageConfigInput = Partial<PaginationSettings> | ((_: PaginationSettings) => Partial<PaginationSettings>);

const fetchDepartmentsByLocation = async (locationId: string) => {
  const response = await DepartmentsApi.listDept({ locationId }, { locationId: locationId });
  return response.departments;
};

const defaultEmptyState: EmptyStateConfig = {
  type: 'sync_your_phone',
  header: i18next.t('No Items to Display'),
  description: i18next.t('Inbound and outbound call records will display here.'),
};

const getFallbackCallerName = (callInfo: HydratedCallRecord) => {
  const { direction, callerNumber, dialedNumber } = callInfo;
  return direction === 'inbound'
    ? getFormattedCallerName(callerNumber ?? '')
    : getFormattedCallerName(dialedNumber ?? '');
};

export const RecentCallsTable = ({ setPageSubtitle }: RecentCallProps) => {
  const alert = useAlert();
  const { t } = useTranslation('calls', { keyPrefix: 'recent-calls' });
  const weaveToken = getWeaveToken();
  const queryClient = useQueryClient();

  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const departmentsEnabled = getFlag('departments');
  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { setShow } = useSlidePanelShallowStore('setShow');
  const { setShow: openPanel } = useCustomContactFormSlidePanel();

  const { getLocationName } = useAppScopeStore();
  const {
    config,
    setPageSize,
    setPageNumber,
    setFilters: setCallsPageFilters,
  } = usePhonePageSettingShallowStore('config', 'setPageSize', 'setPageNumber', 'setFilters');

  const [emptyState, setEmptyState] = useState<EmptyStateConfig>(defaultEmptyState);

  const setFilters = (filters: CallRecordsFiltersType) => {
    setCallsPageFilters(AllCallsTable.RecentCalls, filters);
  };

  const allTimeFilterLabels = useTimePeriodFilterLabels();

  const getPageSubtitle = (startDate: string, endDate: string): string => {
    const definedTimePeriod = getTimePeriodForDateRange(startDate, endDate);
    const timePeriod = definedTimePeriod
      ? allTimeFilterLabels[definedTimePeriod]
      : `${dayjs(startDate).format('MMM DD, YYYY')} - ${dayjs(endDate).format('MMM DD, YYYY')}`;

    return `Showing results for ${timePeriod}`;
  };

  const { selectedLocationIdsExcludingParent } = usePhoneScopeStore();

  const hasAccesstoCallRecordingMap = useMemo(() => {
    return selectedLocationIdsExcludingParent.reduce((accessMap, locationId) => {
      accessMap[locationId] = hasACL(locationId, CoreACLs.CALL_RECORDING_READ);
      return accessMap;
    }, {} as Record<string, boolean>);
  }, [selectedLocationIdsExcludingParent]);

  const hasAccesstoCallRecording = useMemo(() => {
    return Object.values(hasAccesstoCallRecordingMap).some((hasAccess) => hasAccess);
  }, [hasAccesstoCallRecordingMap]);

  const [departmentsByLocation, setDepartmentsByLocation] = useState<
    Record<string, DepartmentsTypes.ListDepartmentTypes['output']>
  >({});
  useEffect(() => {
    const fetchData = async () => {
      const data: Record<string, DepartmentsTypes.ListDepartmentTypes['output']> = {};
      for (const locId of selectedLocationIdsExcludingParent) {
        const departments = await fetchDepartmentsByLocation(locId);
        data[locId] = { departments: departments };
      }
      setDepartmentsByLocation(data);
    };

    fetchData();
  }, [selectedLocationIdsExcludingParent]);

  const defaultFilters: CallRecordsFiltersType = {
    status: [],
    phoneNumber: '',
    name: '',
    locationIds: selectedLocationIdsExcludingParent,
    startDate: defaultDateRangeMap[DEFAULT_TIME_PERIOD].startDate,
    endDate: defaultDateRangeMap[DEFAULT_TIME_PERIOD].endDate,
  };

  const pageNumber = config[AllCallsTable.RecentCalls].pageNumber;
  const pageSize = config[AllCallsTable.RecentCalls].pageSize;
  const filters = config[AllCallsTable.RecentCalls].filters ?? defaultFilters;

  useEffect(() => {
    // initialize the filters
    if (!filters) {
      setFilters(defaultFilters);
    }
  }, []);

  useEffect(() => {
    setPageSubtitle(getPageSubtitle(filters.startDate!, filters.endDate!));
  }, [filters.startDate, filters.endDate]);

  const setPageConfig = (input: SetPageConfigInput) => {
    const data = typeof input === 'function' ? input({ pageSize, pageNumber }) : input;
    if (data.pageSize) setPageSize(AllCallsTable.RecentCalls, data.pageSize);
    if (data.pageNumber) setPageNumber(AllCallsTable.RecentCalls, data.pageNumber);
  };

  const searchQuery = useMemo(() => `${JSON.stringify({ ...filters })}-${pageSize}`, [filters, pageSize]);

  const { data, isFetching, hasNextPage, hasPreviousPage, fetchNextPage } = useScopedInfiniteQuery({
    queryKey: queryKeys.recentCallLogs(searchQuery),
    queryFn: async ({ pageParam }) => {
      const { records, lastId, endDate } = await PhoneCallsApi.getMultiCallRecords({
        limit: pageSize,
        locationIds: filters.locationIds,
        filters: {
          startTime: formatStartDateWithTimeZone(filters.startDate),
          endTime: formatEndDateWithTimeZone(filters.endDate),
          callStatus: filters.status,
          callDirection: filters.callDirection,
          phoneNumber: filters.phoneNumber,
          name: filters.name,
        },
        page: {
          lastRecordId: pageParam?.lastId,
          lastRecordTime: pageParam?.endDate,
        },
      });
      return { data: records || [], meta: { lastId, endDate } };
    },
    getNextPageParam: (lastPage) => {
      return lastPage.meta as PageParams;
    },
    getPreviousPageParam: (lastPage) => {
      return lastPage.meta as PageParams;
    },
    onError: () => {
      alert.error(t("Couldn't load the data. Please try again."));
    },
  });

  const getHasNext = () => {
    const pageData = data?.pages[pageNumber - 1]?.data || [];
    return hasNextPage && !!pageData.length && pageData.length == pageSize;
  };

  useEffect(() => {
    setPageConfig({ pageNumber: 1 });
  }, [filters]);

  useEffect(() => {
    if (!data?.pages[pageNumber - 1]) {
      fetchNextPage();
    }
  }, [pageNumber]);

  useEffect(() => {
    setFilters({ ...defaultFilters, locationIds: selectedLocationIdsExcludingParent });
  }, [selectedLocationIdsExcludingParent]);

  const getProfileName = (callInfo: HydratedCallRecord) => {
    const { person } = callInfo;
    return !!person?.firstName
      ? `${person?.firstName ?? ''} ${person?.lastName ?? ''}`
      : i18next.t('Unknown', { ns: 'calls' });
  };

  const getShortHandName = (callInfo: HydratedCallRecord) => {
    const { person } = callInfo;
    return !!person?.firstName ? `${person?.firstName ?? ''}` : getFallbackCallerName(callInfo);
  };

  const getOfficeUser = (callInfo: HydratedCallRecord) => {
    const { user, status, direction, sipName } = callInfo;
    // if it is an inbound call with status abandoned or missed, then show `--`, otherwise use sipName
    const defaultValue =
      direction === 'inbound' && (status === 'abandoned' || status === 'missed') ? '--' : sipName ? sipName : '';
    const fullName = getFullName(user?.firstName, user?.lastName, defaultValue);
    return fullName;
  };

  const getLocationChip = (locationId: string | undefined) => {
    if (!!locationId) {
      return <Chips.LocationChip>{getLocationName(locationId)}</Chips.LocationChip>;
    }
    return <span>{'--'}</span>;
  };

  return (
    <div css={styles.wrapper}>
      <Table
        tableInstanceId='recent-calls'
        hasResizeColumns
        hasFilterColumns
        emptyStateConfig={emptyState}
        customToolbarRender={() => (
          <RecentCallsChipFilter
            defaultFilters={defaultFilters}
            filters={filters}
            onChange={setFilters}
            isMulti={selectedLocationIdsExcludingParent.length > 1}
          />
        )}
        colConfig={[
          {
            Header: t('Contact Name'),
            accessor: (callItem) => callItem,
            cellRenderer: (callItem: HydratedCallRecord) => {
              return (
                <ProfileCard
                  trackingId={`phone-portal-weave2.0-calls-profilebtn-tablerowaction-viewprofile`}
                  personName={getProfileName(callItem)}
                  locationId={callItem?.locationId}
                  personID={callItem.person?.personId}
                  showIcon={callItem.direction === 'outbound'}
                />
              );
            },
            disableSortBy: true,
            id: 'contactName',
            mobileLayoutConfig: {
              order: -1,
              spanFullWidth: false,
              hideHeader: true,
              leftShift: 48,
            },
            minWidth: 220,
          },
          {
            Header: t('Time'),
            accessor: ({ startedAt }) => dayjs(startedAt).format('MMM DD YYYY, hh:mm A'),
            disableSortBy: true,
            id: 'dateTime',
            minWidth: 180,
          },
          {
            Header: t('Result'),
            accessor: ({ direction, status }) => ({ direction, status }),
            cellRenderer: ({ direction, status }) => {
              if (direction === 'outbound') return <span>{'--'}</span>;
              const statusChip = getStatusChipColor(status);
              return (
                <Chip isResponsive variant={statusChip}>
                  {startCase(status)}
                </Chip>
              );
            },
            disableSortBy: true,
            id: 'result',
            width: 150,
          },
          {
            Header: t('Contact Number'),
            accessor: ({ callerNumber, direction, dialedNumber }) =>
              formatPhoneNumber(direction === 'inbound' ? callerNumber : dialedNumber),
            disableSortBy: true,
            id: 'contactPhone',
            width: 150,
          },
          {
            Header: t('Office Number'),
            accessor: ({ callerNumber, direction, dialedNumber }) =>
              formatPhoneNumber(direction === 'outbound' ? callerNumber : dialedNumber),
            disableSortBy: true,
            id: 'officePhone',
            mobileLayoutConfig: {
              order: 1,
            },
            width: 150,
          },
          {
            Header: t('Office User'),
            accessor: (callInfo) => getOfficeUser(callInfo),
            disableSortBy: true,
            id: 'officeUser',
            width: 180,
          },
          {
            Header: t('Call Recording'),
            accessor: ({ channelId, locationId, recordingPath, recordingFilename }) => ({
              channelId,
              locationId,
              hasCallRecording: !!recordingPath && !!recordingFilename,
            }),
            cellRenderer: ({ channelId, locationId, hasCallRecording }) => (
              <>
                {channelId && locationId && hasAccesstoCallRecordingMap[locationId] && hasCallRecording ? (
                  <AudioScrubber
                    singlePlayer
                    src={`${appConfig.BACKEND_API}/portal/v1/phone/callRecordings/${channelId}?location_id=${locationId}&token=${weaveToken}`}
                    css={{ maxWidth: '100%' }}
                  />
                ) : (
                  <span css={styles.noRecordingText}>{'--'}</span>
                )}
              </>
            ),
            disableSortBy: true,
            id: 'callRecording',
            omit: !hasAccesstoCallRecording,
            mobileLayoutConfig: {
              spanFullWidth: true,
              order: 1,
            },
            minWidth: 200,
          },
          {
            Header: t('Location'),
            accessor: ({ locationId }) => getLocationChip(locationId),
            id: 'location',
            omit: !(selectedLocationIdsExcludingParent.length > 1),
            disableSortBy: true,
            mobileLayoutConfig: {
              order: 1,
            },
          },
          {
            Header: t('Department'),
            accessor: ({ departmentId, locationId }) => {
              const dept = departmentsByLocation[locationId!]?.departments?.find((item) => item.id === departmentId);
              return !!departmentId ? dept?.name : 'Main Line';
            },
            disableSortBy: true,
            id: 'department',
            mobileLayoutConfig: {
              order: 1,
            },
            omit: !departmentsEnabled,
          },
          {
            Header: '',
            accessor: (callInfo) => callInfo,
            cellRenderer: (_, rowData) => {
              if (!rowData) {
                return null;
              }

              const { triggerProps: callTriggerProps, Modal: PhoneCallModal } = ActionsUI.actions.usePhoneCallAction({
                context: {
                  personId: rowData.person?.personId ?? '',
                  phoneNumber: rowData.direction === 'inbound' ? rowData.callerNumber : rowData.dialedNumber,
                  outboundNumber: rowData.direction === 'outbound' ? rowData.callerNumber : rowData.dialedNumber,
                  useOutboundNumber: true,
                },
              });

              const {
                triggerProps: messageTriggerProps,
                Modal: MessageModal,
                disabled: disableMessageAction,
                disabledDetails: disabledMessageDetails,
              } = ActionsUI.actions.useMessageAction({
                context: {
                  personId: rowData.person?.personId ?? '',
                  locationId: rowData.locationId ?? '',
                  phoneNumber: rowData.direction === 'inbound' ? rowData.callerNumber : rowData.dialedNumber,
                },
              });

              const actions = [
                {
                  Icon: PhoneIcon,
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-call',
                  label: `${t('Call')} ${getShortHandName(rowData)}`,
                  onClick: () => {
                    callTriggerProps?.onClick();
                  },
                },
                {
                  Icon: MessageIcon,
                  disabled: disableMessageAction,
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-message',
                  label: `${t('Message')} ${getShortHandName(rowData)}`,
                  hoverLabel: disabledMessageDetails,
                  onClick: () => {
                    messageTriggerProps?.onClick();
                  },
                },
                {
                  Icon: UserIcon,
                  hide: !rowData.person?.personId,
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-viewprofile',
                  label: t('View Profile'),
                  onClick: () => {
                    if (!!rowData.person?.personId) {
                      setPersonId(rowData.person?.personId);
                      setShow(true, 'contact');
                    }
                  },
                },
                {
                  Icon: UserManagementIcon,
                  hide: !!rowData.person?.personId,
                  trackingId: 'phone-portal-weave2.0-calls-btn-tablerowaction-createcontact',
                  label: t('Create Contact'),
                  onClick: () => {
                    const number = rowData.direction === 'inbound' ? rowData.callerNumber : rowData.dialedNumber;

                    const digitsOnly = number ? sanitizePhoneNumber(number) : '';
                    const hasCountryCode = digitsOnly.startsWith('1');

                    openPanel({
                      show: true,
                      context: {
                        mode: 'create',
                        person: {
                          MobilePhone: hasCountryCode ? digitsOnly.slice(1) : digitsOnly,
                        },
                        locationId: rowData.locationId,
                        onSave: () => {
                          alert.success(t('Contact created successfully.'));

                          // This line doesn't quite accomplish anything because the logs do not update with the newly created contact
                          queryClient.invalidateQueries({ queryKey: queryKeys.recentCallLogs(searchQuery) });
                          queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes('contacts') });
                        },
                        onError: () => {
                          alert.error(t('There was an error creating your contact. Please try again.'));
                        },
                      },
                    });
                  },
                },
              ];
              return (
                <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <CustomActionCell actions={actions} tableTrackingId={'phone-portal-weave2.0-calls'} />
                  </div>
                  {PhoneCallModal}
                  {MessageModal}
                </>
              );
            },
            disableSortBy: true,
            id: 'actions',
            sticky: 'right',
            disableColumnFilter: true,
            width: 80,
            mobileLayoutConfig: {
              order: -1,
              spanFullWidth: false,
              hideHeader: true,
            },
          },
        ]}
        data={data?.pages[pageNumber - 1]?.data || []}
        hasResponsiveColWidths
        isLoading={isFetching}
        fullHeight
        isPaginated
        hasGlobalSearch
        globalTrackingId='phone-portal-weave2.0-calls'
        globalSearchConfig={{
          initialValue: filters.phoneNumber || filters.name || '',
          position: 'right',
          searchHandler: (searchText) => {
            const sanitizedNumber = sanitizePhoneNumber(searchText);
            const numberSearch =
              isPhoneNumber(sanitizedNumber) || isPartialPhoneNumber(sanitizedNumber) ? sanitizedNumber : '';
            const nameSearch =
              !isPhoneNumber(sanitizedNumber) && !isPartialPhoneNumber(sanitizedNumber) ? searchText : '';

            if (!!isPartialPhoneNumber(searchText)) {
              setEmptyState({
                type: 'sync_your_phone',
                header: t('Phone Number Searches Must be Exactly 10 Digits'),
                description: t('Phone number searches must be exactly 10 digits in order to return a match.'),
              });
            } else {
              setEmptyState(defaultEmptyState);
            }

            setFilters({
              ...filters,
              phoneNumber: numberSearch,
              name: nameSearch,
            });
          },
          placeholder: t('Search name or 10-digit number'),
          debounceDelay: 1000,
        }}
        manualFilters
        manualFiltersOptions={{ initialBadgeState: filters.status.length > 0 }}
        manualFiltersRender={(modalProps, setShowNotificationBadge) => (
          <div css={styles.filtersWrapper}>
            <RecentCallsTrayFilter
              defaultFilters={defaultFilters}
              filters={filters}
              modalProps={modalProps}
              onChange={setFilters}
              setShowNotificationBadge={setShowNotificationBadge}
              isMulti={selectedLocationIdsExcludingParent.length > 1}
            />
          </div>
        )}
        manualPaginationConfig={{
          handleChange: (action: 'next' | 'prev') => {
            if (action === 'next') {
              setPageConfig((prev) => ({ pageNumber: prev.pageNumber + 1 }));
            } else {
              setPageConfig((prev) => ({ pageNumber: prev.pageNumber - 1 }));
            }
          },
          hasNext: getHasNext(),
          hasPrevious: hasPreviousPage,
          page: pageNumber,
          defaultRowsPerPage: pageSize,
          onNumRowsChange: (num) => {
            setPageConfig({ pageNumber: 1, pageSize: num });
          },
          rowsPerPageOptions: [10, 25, 50, 75],
        }}
      />
    </div>
  );
};

const styles = {
  wrapper: css`
    flex: 1;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: ${theme.spacing(1)};
    position: relative;
  `,

  filtersWrapper: css`
    order: -1;
    margin-right: ${theme.spacing(2)};

    @media screen and (max-width: 1500px) {
      flex: 1;
    }
  `,

  audio: css`
    height: ${theme.spacing(4)};
  `,

  noRecordingText: css`
    color: ${theme.colors.neutral90};
    display: inline-block;
    text-align: left;
    width: 100%;
  `,
};
