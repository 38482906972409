import jwtDecode from 'jwt-decode';
import * as oauth from 'oauth4webapi';
import {
  hashCode,
  AuthStorage,
  DecodedOIDCIDToken,
  getLastVisitedPage,
  isTimeExpired,
  isWeaveTokenActive,
  localStorageHelper,
  OidcClientConfig,
  SignInMethod,
} from '@frontend/auth-helpers';
import { AuthFlow, ClientTokens } from './AuthFlow';
// eslint-disable-next-line @nx/enforce-module-boundaries

const s256 = 'S256';
const tokenEndpointAuthMethod = 'none';

// implementation based on the example given by oauth4webapi:
// https://github.com/panva/oauth4webapi/blob/main/examples/public.ts

export class OidcClient implements AuthFlow {
  private config: OidcClientConfig;
  private as: oauth.AuthorizationServer | undefined;

  constructor(config: OidcClientConfig) {
    this.config = config;
  }

  public async signIn() {
    const as = await this.getAuthenticationServer();
    // check if the server supports PKCE
    if (as.code_challenge_methods_supported?.includes(s256) !== true) {
      // This assumes S256 PKCE support is signalled
      // If it isn't supported, random `nonce` must be used for CSRF protection.
      throw new Error();
    }
    const code_verifier = oauth.generateRandomCodeVerifier();
    const code_challenge = await oauth.calculatePKCECodeChallenge(code_verifier);
    const code_challenge_method = s256;
    // store the code_verifier in local storage for later use
    localStorage.setItem('oidc.code_verifier', code_verifier);

    const authorizationUrl = new URL(as.authorization_endpoint!);
    authorizationUrl.searchParams.set('client_id', this.config.clientID);
    authorizationUrl.searchParams.set('code_challenge', code_challenge);
    authorizationUrl.searchParams.set('code_challenge_method', code_challenge_method);
    authorizationUrl.searchParams.set('redirect_uri', this.config.redirectUri.toString());
    authorizationUrl.searchParams.set('response_type', 'code');
    authorizationUrl.searchParams.set('scope', 'openid email');
    authorizationUrl.searchParams.set('state', this.generateStateHash());

    window.location.href = authorizationUrl.toString();
    return Promise.resolve('');
  }

  // Not to be used as a cryptographic hash function.
  private generateStateHash(): string {
    const desiredPage = getLastVisitedPage();
    const randomNumber = Math.random() * 100000;
    return 'st_' + hashCode(desiredPage) + '_' + randomNumber.toString() + '_' + new Date().getTime();
  }

  private async getAuthenticationServer() {
    if (this.as) {
      return Promise.resolve(this.as);
    }
    return await oauth
      .discoveryRequest(this.config.issuer)
      .then((discoveryResponse) => {
        return oauth.processDiscoveryResponse(this.config.issuer, discoveryResponse);
      })
      .catch((err: Error) => {
        // TODO: handle different error states.
        throw err;
      });
  }

  public async signOut(opts: { logoutRedirect?: string } = {}) {
    // redirect the browser to the logout endpoint
    const as = await this.getAuthenticationServer();
    if (!!as.end_session_endpoint) {
      const oidc_token = localStorageHelper.get<{ accessToken: string; IDToken: string }>(
        AuthStorage.oidc_token_storage
      );
      let signOutURI = `${as.end_session_endpoint}?post_logout_redirect_uri=${window.location.origin}&id_token_hint=${oidc_token?.IDToken}`;
      try {
        const decodedIDToken = jwtDecode(oidc_token?.IDToken || '') as DecodedOIDCIDToken;
        if (isTimeExpired(decodedIDToken.exp)) {
          signOutURI = `${as.end_session_endpoint}`;
        }
      } catch (err) {
        // invalid token
        signOutURI = `${as.end_session_endpoint}`;
      }
      localStorageHelper.delete(AuthStorage.oidc_token_storage);
      window.location.replace(signOutURI);
    } else if (opts.logoutRedirect) {
      window.location.href = opts.logoutRedirect;
    } else {
      // we default to the home page
      window.location.href = '/';
    }
    // returning a promise that never resolves allows the user to handle any errors with a catch but not be able to continue executing code
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 30000); // 30 seconds
    }); // return a promise that never resolves
  }

  public getTokens(): Promise<ClientTokens> {
    const tokens = localStorageHelper.get<ClientTokens>(AuthStorage.oidc_token_storage);
    return Promise.resolve(tokens || {});
  }

  public async handleCallback(): Promise<ClientTokens> {
    const client: oauth.Client = {
      client_id: this.config.clientID,
      token_endpoint_auth_method: tokenEndpointAuthMethod,
    };
    const as = await this.getAuthenticationServer();
    const code_verifier = localStorage.getItem('oidc.code_verifier');
    if (!code_verifier) {
      throw new Error('Code verifier not found');
    }
    // validate that we are on the expected URL
    const currentUrl: URL = new URL(window.location.href);
    const params = oauth.validateAuthResponse(as, client, currentUrl, oauth.skipStateCheck); // might be able to use state to redirect to the desired page
    if (oauth.isOAuth2Error(params)) {
      throw new Error('Could not validate callback parameters'); // Handle OAuth 2.0 redirect error
    }
    const response = await oauth.authorizationCodeGrantRequest(
      as,
      client,
      params,
      this.config.redirectUri.toString(),
      code_verifier
    );

    // TODO: verify if we will ever need to parse and handle these challenges in the coarse of the auth flow
    // let challenges: oauth.WWWAuthenticateChallenge[] | undefined;
    // if ((challenges = oauth.parseWwwAuthenticateChallenges(response))) {
    //   for (const challenge of challenges) {
    //     // TODO: handle different challenge types
    //     challenge.
    //   }
    //   throw new Error(); // Handle www-authenticate challenges as needed
    // }
    // -- end of challenge handling

    const result = await oauth.processAuthorizationCodeOpenIDResponse(as, client, response);
    if (oauth.isOAuth2Error(result)) {
      throw new Error("Could not process identity provider's response"); // Handle OAuth 2.0 response body error
    }

    // Kept this commented so I can remember how to get the claims from the token
    // const claims = oauth.getValidatedIdTokenClaims(result);

    // store access token and id token in local storage
    const clientTokens: ClientTokens = {
      accessToken: (result as oauth.OpenIDTokenEndpointResponse).access_token,
      IDToken: (result as oauth.OpenIDTokenEndpointResponse).id_token,
    };

    localStorageHelper.create(AuthStorage.oidc_token_storage, clientTokens);

    return clientTokens;
  }

  public getSignInMethod(): Promise<SignInMethod> {
    return Promise.resolve('oidc');
  }

  public isUserAuthenticated(): boolean {
    const oidcTokens = localStorageHelper.get<ClientTokens>(AuthStorage.oidc_token_storage);
    if (oidcTokens?.IDToken) {
      return !this.isOIDCTokenExpired(oidcTokens.IDToken) && isWeaveTokenActive();
    }
    return false;
  }

  private isOIDCTokenExpired(IDToken: string): boolean {
    try {
      const decodedToken = jwtDecode<DecodedOIDCIDToken>(IDToken);
      const expirationDate = new Date(0);
      expirationDate.setUTCSeconds(decodedToken.exp);
      return expirationDate < new Date();
    } catch (e) {
      return true;
    }
  }
}
