import { FC } from 'react';
import { TextSize, Button } from '@frontend/design-system';

interface AddButtonProps {
  title: string;
  onClick: () => void;
  className?: string;
  size?: number;
  textSize?: TextSize;
  style?: Record<string, string>;
}

export const AddButton: FC<AddButtonProps> = ({ title, onClick, className, style }) => {
  return (
    <Button onClick={onClick} className={className} variant='secondary' style={style} iconName='plus'>
      {title}
    </Button>
  );
};
