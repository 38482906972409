import { FC, useEffect, useMemo, useState } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { emptyStateGraphics, useAlert } from '@frontend/design-system';
import { BulkMessageButton, HygieneTreatmentMetric } from '..';
import { InlineStats, TabbedTable } from '../..';
import { useAnalyticsOrgLocations, usePracticeAnalyticsShallowStore, useShowBulkMessageButton } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { trackingIds } from '../../../tracking-ids';
import { ExportUtils, formatters } from '../../../utils';
import { commonStyles } from '../../../views';
import {
  PatientsHelpers,
  getPracticeAnalyticsQueryVariables,
  isPatientScheduled,
  subViewTableColConfig,
} from '../helpers';
import { usePatientsLastContacted } from '../hooks';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      hygieneTreatmentPlan: treatmentPlan(treatmentPlanType: HYGIENE) {
        details(start: $start, end: $end, step: $step) {
          patients {
            id
            diagnosed
            procedures {
              adaCode
              date
              procedureAmount
            }
            productionAmount
            FirstName: firstName
            LastName: lastName
            PMID: pmid
            MobilePhone: mobilePhone
            HomePhone: homePhone
            WorkPhone: workPhone
            Email: email
            PreferredName: preferredName
            Birthdate: birthdate
            Gender: gender
          }
        }
        totals(start: $start, end: $end, step: $step) {
          accepted
          acceptedPercent
          diagnosed
          diagnosedPercent
          qualifiedVisits
          unscheduledTreatment
        }
      }
    }
  }
`;

type CategorizedTreatment = {
  diagnosed: PracticeAnalyticsTypes.PatientInfo[];
  unscheduled: PracticeAnalyticsTypes.PatientInfo[];
};

export const HygieneTreatmentSubView: FC<React.PropsWithChildren<PracticeAnalyticsTypes.MetricSubViewProps>> = ({
  exportEnabled,
  onPatientsLoad,
}) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount, showDemoChipAndBanner } = usePracticeAnalyticsShallowStore(
    'demoData',
    'filters',
    'isDemoAccount',
    'showDemoChipAndBanner'
  );
  const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });
  const isBulkMessagingEnabled = useShowBulkMessageButton();

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  const [categorizedData, setCategorizedData] = useState<CategorizedTreatment>({
    diagnosed: [],
    unscheduled: [],
  });

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(`hygieneTreatmentSubView-${isDemoAccount}-${JSON.stringify(filters)}`),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.HygieneTreatmentPlanResponse>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.hygieneTreatmentPlan : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const processedData = useMemo(() => {
    return {
      aggregatedData: PracticeAnalyticsAggregations.hygieneTreatmentPlanDetails(data),
      patientIds: Object.values(data?.data || {}).reduce((acc, locationDetails) => {
        return PatientsHelpers.collectPatientIds(
          locationDetails?.location?.hygieneTreatmentPlan?.details?.patients,
          acc
        );
      }, [] as string[]),
    };
  }, [data?.data]);

  const patientsLastContacted = usePatientsLastContacted({
    patientIds: processedData.patientIds,
  });

  const middleColumns = [
    {
      Header: t('Diagnosed'),
      headerLabel: t('Diagnosed'),
      accessor: ({ diagnosed }: PracticeAnalyticsTypes.PatientInfo) => diagnosed || '',
      cellRenderer: (date: string) => (date ? formatters.date.format(date) : '-'),
      id: 'diagnosed',
      width: 200,
    },
  ];

  const tabsCommonConfig = {
    colConfig: subViewTableColConfig({
      actionsTrackingIdBase: 'hygiene-treatment-patient',
      hasProcedures: true,
      middleColumns,
      patientsLastContacted,
    }),
    emptyStateConfig: 'users' as keyof typeof emptyStateGraphics,
    tableInstanceId: 'hygienePracticeSubView',
  };

  useEffect(() => {
    const patients = processedData.aggregatedData.hygieneTreatmentPlan.details?.patients || [];

    setCategorizedData(
      patients.reduce(
        (final, patient) => {
          let value = { ...final, diagnosed: [...final.diagnosed, patient] };
          if (!isPatientScheduled(patient)) {
            value = { ...value, unscheduled: [...value.unscheduled, patient] };
          }
          return value;
        },
        { diagnosed: [], unscheduled: [] } as CategorizedTreatment
      )
    );
    onPatientsLoad?.(patients);
  }, [processedData]);

  return (
    <>
      <InlineStats
        data={[
          {
            label: t('Patients Diagnosed'),
            value: formatters.percent.format(
              processedData.aggregatedData.hygieneTreatmentPlan.totals.diagnosedPercent || 0
            ),
          },
          {
            label: t('Patients Accepted'),
            value: formatters.percent.format(
              processedData.aggregatedData.hygieneTreatmentPlan.totals.acceptedPercent || 0
            ),
          },
          {
            label: t('Unscheduled Treatment'),
            value: formatters.currency.format(
              Math.round(processedData.aggregatedData.hygieneTreatmentPlan.totals.unscheduledTreatment || 0)
            ),
          },
        ]}
        isDemoAccount={showDemoChipAndBanner}
        isLoading={isLoading}
      />
      <HygieneTreatmentMetric clickNoop isDrillDownPage />
      <TabbedTable
        actions={BulkMessageButton}
        autorizedToExport={PracticeAnalyticsApi.auditPracticeAnalyticsExport}
        exportFileName='Hygiene Treatment Patients' // Translation not needed
        initialTab='diagnosed'
        isExportReady={!isLoading && !patientsLastContacted.isLoading}
        isLoading={isLoading}
        isSelectable={isBulkMessagingEnabled}
        processExportableData={(data) =>
          // Translation is not needed as this is for export purpose
          ExportUtils.processExportableData({
            columns: [
              'Patient Name',
              ...(multipleLocationsSelected ? ['Location Name'] : []),
              'Phone Number',
              'Diagnosed',
              'Last Contacted',
              '$ Value',
              'Procedure Codes',
            ],
            data,
            deriveExportValue: (params) =>
              PatientsHelpers.deriveExportValue({
                ...params,
                lastContactedDates: patientsLastContacted.lastContactedDates,
                locationNames,
              }),
            sortColumn: 'productionAmount',
          })
        }
        showExportIcon={exportEnabled}
        tabs={{
          diagnosed: {
            ...tabsCommonConfig,
            data: categorizedData.diagnosed,
            label: t('Diagnosed'),
            trackingId: trackingIds.practiceAnalytics.hygieneTreatmentDiagnosedTab,
          },
          unscheduled: {
            ...tabsCommonConfig,
            data: categorizedData.unscheduled,
            label: t('Unscheduled'),
            trackingId: trackingIds.practiceAnalytics.hygieneTreatmentUnscheduledTab,
          },
        }}
        trackingIdBase='hygiene-treatment-patients'
        wrapperStyle={commonStyles.drillDownTableSize}
      />
    </>
  );
};
