import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Text, TextProps } from '@frontend/design-system';
import { useInboxFiltersShallowStore } from '../stores';
import { InboxFilter, InboxStatus } from '../types';

type InboxStatusFiltersTextProps = Omit<TextProps, 'children'> & { hideFilters?: InboxFilter[] };

export const InboxStatusFiltersText = ({ hideFilters = [], ...props }: InboxStatusFiltersTextProps) => {
  const { t } = useTranslation('inbox');
  const { inboxFilters } = useInboxFiltersShallowStore('inboxFilters');

  const statusLabels: Record<InboxStatus, string> = {
    [InboxStatus.ERROR]: t('Error'),
    [InboxStatus.READ]: t('Read'),
    [InboxStatus.REPLIED]: t('Replied'),
    [InboxStatus.UNREAD]: t('Unread'),
    [InboxStatus.UNREPLIED]: t('Unreplied'),
  };

  const text = useMemo(() => {
    const arr: string[] = [];
    if (!hideFilters.includes('statuses')) {
      arr.push(...inboxFilters.statuses.map((status) => statusLabels[status]));
    }

    if (!hideFilters.includes('tags') && inboxFilters.tags.length > 0) {
      arr.push(t('{{count}} tags', { count: inboxFilters.tags.length }));
    }

    if (!hideFilters.includes('departments') && inboxFilters.departments.length > 0) {
      arr.push(t('{{count}} departments', { count: inboxFilters.departments.length }));
    }

    return arr.join(', ');
  }, [inboxFilters.statuses, inboxFilters.tags, inboxFilters.departments, JSON.stringify(hideFilters)]);

  if (!text) return null;

  return (
    <Text color='primary' size='small' {...props}>
      {text}
    </Text>
  );
};
