import React, { useEffect, useRef, useState } from 'react';
import { RegistererState, UserAgentState, LogLevel } from 'sip.js';
import { SessionManager } from 'sip.js/lib/platform/web';
import { createContext, useContextSelector } from 'use-context-selector';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { sentry } from '@frontend/tracking';
import { makeTargetURI } from '../../utils/phone-utils';
import { useSoftphoneEventsEmitter } from '../softphone-events-provider';

export type SoftphoneClientContextValue = {
  uri: string;
  domain: SoftphoneTypes.SipProfile['domain'];
  client: SessionManager | undefined;
  clientState: keyof typeof UserAgentState | undefined;
  registrationState: keyof typeof RegistererState | undefined;
  reconnect: () => Promise<void> | undefined;
  status: 'loading' | 'success' | 'error';
  deviceName: string;
  extensionNumber: number | undefined;
  extensions: SoftphoneTypes.User[];
  registerCallId: string;
};

const SoftphoneClientContext = createContext<SoftphoneClientContextValue>({} as SoftphoneClientContextValue);

type SoftphoneProviderProps = {
  onConnect?: () => void;
  onDisconnect?: () => void;
  proxy: string;
  username: string;
  domain: SoftphoneTypes.SipProfile['domain'];
  password: string;
  useragent: string;
  children: React.ReactNode;
  deviceName: string;
  extensionNumber: number | undefined;
  extensions: SoftphoneTypes.User[];
};

const registrationExpiry = 300;
const refreshFrequency = 50;

export const SoftphoneClientProvider = ({
  proxy,
  username,
  domain,
  password,
  useragent,
  deviceName,
  extensionNumber,
  onConnect,
  onDisconnect,
  extensions,
  children,
}: SoftphoneProviderProps) => {
  const [clientState, setClientState] = useState<keyof typeof UserAgentState>();
  const [registrationState, setRegistrationState] = useState<keyof typeof RegistererState>();
  const lastRegistered = useRef<number | undefined>();
  const [isInitializing, setIsInitializing] = useState(true);
  const [client, setClient] = useState<SessionManager>();
  const emitter = useSoftphoneEventsEmitter();
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const registerCallId = useRef<string>('');

  const disconnect = () => {
    console.log('Softphone Disonnected', client);
    try {
      client?.disconnect();
      client?.unregister();
      setClient(undefined);
      setClientState(UserAgentState.Stopped);
      setRegistrationState(RegistererState.Terminated);
      onDisconnect?.();
      client?.userAgent.stop();
      client?.userAgent.transport.disconnect();
      client?.userAgent.transport.dispose();
    } catch (err) {
      //if disconnect fails, it's likely already disconnected, and we don't really care.
    }
  };

  const connect = (proxy: string, username: string, domain: SoftphoneTypes.FullyQualifiedAddress, password: string) => {
    const sessionManager = new SessionManager(`wss://${proxy}`, {
      media: {
        constraints: {
          audio: true,
          video: false,
        },
      },
      registererOptions: {
        expires: registrationExpiry,
        refreshFrequency: refreshFrequency,
      },
      maxSimultaneousSessions: 5,
      autoStop: false,
      delegate: {
        onServerConnect: () => {
          setClientState('Started');
        },
        onServerDisconnect: (err) => {
          console.log('Softphone Disconnected', err);
          setClientState('Stopped');
          disconnect();
        },
        onRegistered() {
          setRegistrationState('Registered');
          lastRegistered.current = Date.now();
          onConnect?.();
        },
        onUnregistered() {
          console.log('Phone Unregistered', { registerCallId: registerCallId.current });
          emitter.emit('registration.unregistered', { callId: registerCallId.current });
          registerCallId.current = '';
          lastRegistered.current = undefined;
          setRegistrationState('Unregistered');
        },
        onNotificationReceived(request) {
          if (request.request.headers['O'][0].raw === 'check-sync') {
            request.accept();
          }
        },
        onMessageReceived(message) {
          console.log('Message Received', message);
        },
      },
      userAgentOptions: {
        uri: makeTargetURI(`${username}@${domain}`),
        //TODO: don't default to 'error' once in production
        logLevel: (localStorage.getItem('softphone.log-level') as LogLevel) ?? 'error',
        userAgentString: useragent,
        authorizationPassword: password,
        authorizationUsername: username,
        sessionDescriptionHandlerFactoryOptions: {
          iceCheckingTimeout: 2000,
          peerConnectionConfiguration: {
            iceServers: [],
          },
        },
        // contactName: softphone.sip_profile.username,
      },
    });
    setClient(sessionManager);
  };

  useEffect(() => {
    if (!(proxy && username && domain && password)) {
      return;
    }

    if (!client) {
      connect(proxy, username, domain, password);
    }
  }, [client, proxy, username, domain, password, emitter]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - (lastRegistered.current ?? 0) > 1000 * (registrationExpiry + 10)) {
        //log 'last registered' time
        emitter.emit('registration.not_renewed', { callId: registerCallId.current });
      }
    }, 30000);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (!client) {
      return;
    }

    if (client && client.isConnected()) {
      return;
    }

    //intentionally throttle here to prevent rapid-fire reconnecting
    if (!timeout.current) {
      setIsInitializing(true);
      timeout.current = setTimeout(() => {
        client
          .connect()
          .then(() => {
            if (client.isConnected()) {
              client.userAgent.contact.uri.user = username;
              client.register({
                requestDelegate: {
                  onAccept: (res) => {
                    registerCallId.current = res.message.callId;
                    setRegistrationState('Registered');
                    emitter.emit('registration.registered', { callId: res.message.callId });
                  },
                  onReject: (res) => {
                    console.log('registration.failure', res);
                    emitter.emit('registration.failure', { callId: res.message.callId });
                    setRegistrationState('Unregistered');
                  },
                },
              });
            }
          })
          .catch((err) => {
            console.log('Disconnecting Softphone Client Initialization Error', err);
            onDisconnect?.();
          });

        setTimeout(() => {
          setIsInitializing(false);
        }, 2000);
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }, 250);
    }

    return () => {
      clearTimeout(timeout.current);
      timeout.current = undefined;
      console.info('Unmounting & Disconnecting Softphone');
      if (client && client.isConnected()) {
        disconnect();
      }
    };
  }, [client]);

  const reconnect = () => {
    console.log('Reconnecting Softphone', client);
    if (!client) {
      disconnect();
      return;
    }

    return client.connect().then(() => {
      if (!client) {
        return;
      }
      setClientState(UserAgentState.Started);
      client.register({
        requestDelegate: {
          onAccept: (res) => {
            setRegistrationState('Registered');
            emitter.emit('registration.registered', { callId: res.message.callId });
          },
          onReject: (res) => {
            setRegistrationState('Unregistered');
            emitter.emit('registration.failure', { callId: res.message.callId });
            console.log('registration.failure', res);
            sentry.warn({
              error:
                'Error reconnecting Softphone: ' + res
                  ? typeof res === 'object'
                    ? res?.message
                    : JSON.stringify(res)
                  : 'Unknown',
              topic: 'phone',
            });
          },
        },
      });
    });
  };

  const status = (() => {
    if (clientState === 'Started' && registrationState === 'Registered') {
      return 'success';
    }
    if (isInitializing) {
      return 'loading';
    }

    return 'error';
  })();

  const value = {
    uri: makeTargetURI(`${username}@${domain}`)?.toString() ?? '',
    domain,
    client,
    clientState,
    registrationState,
    reconnect,
    deviceName,
    extensionNumber,
    extensions,
    status,
    registerCallId: registerCallId.current,
  } as const satisfies SoftphoneClientContextValue;

  return <SoftphoneClientContext.Provider value={value}>{children}</SoftphoneClientContext.Provider>;
};

export const useSoftphoneClient = <T extends any>(selector: (val: SoftphoneClientContextValue) => T) => {
  return useContextSelector(SoftphoneClientContext, selector);
};
