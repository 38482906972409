import { useEffect, useMemo } from 'react';
import { CallIntelMockData, CallIntelligenceApi } from '@frontend/api-analytics';
import { Chart, XAxisLabelValueTick } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { CustomTooltipTitle } from '..';
import { useCallIntelDemoFlags, useCallIntelShallowStore } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { callIntelligenceUtils, formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { OverviewSummaryOpportunitiesStack, SentimentsChart } from '../charts';
import { SubViewOpportunitiesInlineStats } from './sub-view-opportunities-inline-stats';

type Props = {
  metricsFor?: string;
  onChangeLoadingState?: (isLoading: boolean) => void;
};

export const OpportunitiesByTypeSubView = ({ metricsFor, onChangeLoadingState }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { isDemoAccount, showDemoChipAndBanner } = useCallIntelDemoFlags();
  const { dataColors, dataLabels, filterHintText, filters, subView } = useCallIntelShallowStore(
    'dataColors',
    'dataLabels',
    'filterHintText',
    'filters',
    'subView'
  );

  const filteredOverviewAppointmentsDrillDown = useMemo(() => {
    return CallIntelMockData.getFilteredOverviewAppointmentsDrillDown(
      CallIntelMockData.overviewAppointmentsDrillDown,
      filters
    );
  }, [filters]);

  const filteredOverviewCategoriesDrillDown = useMemo(() => {
    return CallIntelMockData.getFilteredOverviewCategoriesDrillDown(
      CallIntelMockData.overviewCategoriesDrillDown,
      filters
    );
  }, [filters]);

  const drillDownFilters = callIntelligenceUtils.getDrillDownFilterKeys(subView);
  const queryKey = queryKeys.callIntelligence(
    `overview-drill-down-${JSON.stringify(filters)}-${JSON.stringify(drillDownFilters)}`
  );

  const { data: aggregatedData, isLoading: isLoadingAggregatedData } = useScopedQuery({
    queryKey,
    queryFn: () =>
      isDemoAccount
        ? null
        : drillDownFilters
        ? CallIntelligenceApi.getOverviewDetails({ drillDownOptions: drillDownFilters, filters })
        : null,
    onError: (err) => {
      alert.error(t('Failed to fetch overview data'));
      console.error(err);
    },
    refetchOnWindowFocus: false,
    select: (data) =>
      isDemoAccount
        ? subView.type === 'appointment-type'
          ? filteredOverviewAppointmentsDrillDown
          : filteredOverviewCategoriesDrillDown
        : data,
    staleTime: 1000 * 60 * 5,
  });

  const targetedData = useMemo(() => {
    const isAppointmentType = subView.type === 'appointment-type';

    // Show(target) opposite data
    return {
      colors: isAppointmentType ? dataColors.categories : dataColors.appointmentTypes,
      data: isAppointmentType ? aggregatedData?.categories : aggregatedData?.appointmentTypes,
      labels: isAppointmentType ? dataLabels.categories : dataLabels.appointmentTypes,
      title: isAppointmentType
        ? t('{{name}} Call Categories', { name: dataLabels.appointmentTypes?.[subView.id || ''] }).trim()
        : t('{{name}} Call Appointment Types', { name: dataLabels.categories?.[subView.id || ''] }).trim(),
    };
  }, [aggregatedData]);

  const chartData = useMemo(() => {
    return {
      groups: Object.entries(targetedData.data || {}).map(([name, value]) => ({
        name,
        values: {
          [name]: value,
        },
      })),
    };
  }, [targetedData]);

  useEffect(() => {
    onChangeLoadingState?.(isLoadingAggregatedData);
  }, [isLoadingAggregatedData]);

  return (
    <div>
      <SubViewOpportunitiesInlineStats
        data={aggregatedData}
        isLoading={isLoadingAggregatedData}
        metricsFor={metricsFor}
      />
      <OverviewSummaryOpportunitiesStack data={aggregatedData} isLoading={isLoadingAggregatedData} />
      <Chart.HorizontalContainer marginBottom={0}>
        <Chart
          colors={targetedData.colors || {}}
          commonTooltipLabel={t('Calls')}
          isLoading={isLoadingAggregatedData}
          labels={targetedData.labels}
        >
          <Chart.Header
            leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
            subtitle={filterHintText}
            title={targetedData.title}
          />
          <Chart.BarChart
            appearance={{
              customTooltipTitle: ({ groupName }) => {
                return <CustomTooltipTitle suffix={targetedData.labels?.[groupName]} />;
              },
              customXAxisTick: ({ labels, groupName, ...rest }) => {
                return (
                  <XAxisLabelValueTick
                    {...rest}
                    color={targetedData.colors?.[groupName]}
                    label={labels?.[groupName] || groupName}
                    value={formatters.value.format(
                      targetedData?.data?.[groupName as keyof (typeof targetedData)['data']]
                    )}
                  />
                );
              },
              margin: { bottom: 20 },
              showXAxis: true,
            }}
            data={chartData}
            formatValue={formatters.value.format}
          />
        </Chart>
        <SentimentsChart internalPage isLoading={isLoadingAggregatedData} sentiments={aggregatedData?.sentiments} />
      </Chart.HorizontalContainer>
    </div>
  );
};
