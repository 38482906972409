import { createProvider } from '@frontend/store';
import { BUILDER_FIELD_DIVISIONS } from '../constants';
import { FormBuilderStore } from './types';

/**
 * This store (context) is used to hold the state of the FormBuilder.
 */
export const { Provider: FormBuilderStoreProvider, useStore: useFormBuilderStore } = createProvider<FormBuilderStore>()(
  (set) => ({
    isBuilderReady: false,
    setIsBuilderReady: (value) => set({ isBuilderReady: value }),

    primaryFieldElements: undefined,
    setPrimaryFieldElements: (value) => set({ primaryFieldElements: value }),

    usedPrimaryFieldElements: {},
    setUsedPrimaryFieldElement: (key, value) =>
      set((state) => ({
        usedPrimaryFieldElements: {
          ...state.usedPrimaryFieldElements,
          [key]: value,
        },
      })),

    batchUpdateUsedPrimaryFieldElements: (value, reset = false) => {
      if (reset) {
        set({ usedPrimaryFieldElements: value });
      } else {
        set((state) => ({
          usedPrimaryFieldElements: {
            ...state.usedPrimaryFieldElements,
            ...value,
          },
        }));
      }
    },

    otherFieldElements: undefined,
    setOtherFieldElements: (value) => set({ otherFieldElements: value }),

    sectionTemplates: undefined,
    setSectionTemplates: (value) => set({ sectionTemplates: value }),

    sectionTemplatesOrder: [],
    setSectionTemplatesOrder: (value) => set({ sectionTemplatesOrder: value }),

    usedSectionTemplates: {},
    setUsedSectionTemplate: (key, value) =>
      set((state) => ({
        usedSectionTemplates: {
          ...state.usedSectionTemplates,
          [key]: value,
        },
      })),

    batchUpdateUsedSectionTemplates: (value, reset = false) => {
      if (reset) {
        set({ usedSectionTemplates: value });
      } else {
        set((state) => ({
          usedSectionTemplates: {
            ...state.usedSectionTemplates,
            ...value,
          },
        }));
      }
    },

    shouldShowMedicalHistoryFields: false,
    setShouldShowMedicalHistoryFields: (value) => set({ shouldShowMedicalHistoryFields: value }),

    medicalConditions: {},
    setMedicalConditions: (value) => set({ medicalConditions: value }),

    activeBuilderDivision: BUILDER_FIELD_DIVISIONS[0],
    setActiveBuilderDivision: (value) => set({ activeBuilderDivision: value }),
  })
);
