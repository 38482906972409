import { useMutation } from 'react-query';
import { LimitedSchemaMutationOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { UpdateReviewRequiredFlagForFormIO, DeleteFormV2IO } from '../../types';
import { endpointKeys } from '../keys';

export const useUpdateReviewRequiredFlagForForm = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<UpdateReviewRequiredFlagForFormIO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.updateReviewRequiredFlagForForm,
    mutationFn: (req) => SchemaDigitalForms.UpdateFormReviewRequiredFlag(req),
    ...options,
  });
};

export const useDeleteFormV2 = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<DeleteFormV2IO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.deleteFormV2,
    mutationFn: (req) => SchemaDigitalForms.DeleteFormV2(req),
    ...options,
  });
};
