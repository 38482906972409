import { useMemo, ReactElement, ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Icon } from '../../../icon';
import { Heading, HeadingProps } from '../../heading';
import { useStepperCardContext } from '../provider/stepper-card.provider';
import { useStepperContext } from '../provider/stepper.provider';

export type StepperTitleProps = {
  icon?: ReactElement;
  level?: HeadingProps['level'];
  children: ReactNode;
};

export const StepperTitle = ({ icon, children, level = 2, ...rest }: Partial<StepperTitleProps>) => {
  const { stepStatus } = useStepperContext();
  const { step, stepValue } = useStepperCardContext();

  const titleDisplay = useMemo(() => {
    if (step)
      if (stepStatus[step] === 'completed' && stepValue) {
        return stepValue;
      }
    return children;
  }, [stepStatus[step], stepValue]);

  return (
    <div
      className='step-title-container'
      css={css`
        display: flex;
        align-items: center;
      `}
      {...rest}
    >
      {icon && (
        <Icon
          className='step-content-svg'
          css={css`
            margin-right: ${theme.spacing(1)};
            height: ${theme.spacing(3)};
          `}
        >
          {icon}
        </Icon>
      )}
      <Heading
        level={level}
        className='step-content-title'
        css={css`
          margin: ${theme.spacing(0)};
          text-transform: capitalize;
        `}
      >
        {titleDisplay}
      </Heading>
    </div>
  );
};
