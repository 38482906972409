import { useCallback, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useSoftphoneCallState } from '..';
import { EstablishedCall, isEstablishedCall } from '../../../types';
import { makeTargetURI } from '../../../utils/phone-utils';
import { useSoftphoneClient } from '../../softphone-client-provider';
import { useSoftphoneDialer } from '../../softphone-dialer-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';
import { useToggleHold } from './use-hold-call';
import { usePlaceCall } from './use-place-call';

export const useTransfer = () => {
  const client = useSoftphoneClient((ctx) => ctx.client);
  const resolvedDialAddress = useSoftphoneDialer((ctx) => ctx.resolvedDialAddress);
  const setIsMuted = useSoftphoneCallState((ctx) => ctx.setIsMuted);
  const terminateCall = useSoftphoneCallState((ctx) => ctx.terminateCall);
  const { emit } = useSoftphoneEventsEmitter();
  const { placeCallFromDialer } = usePlaceCall();
  const { toggleHold } = useToggleHold();
  const setCurrentTransfer = useSoftphoneCallState((ctx) => ctx.setCurrentTransfer);
  const currTransfer = useSoftphoneCallState((ctx) => ctx.currentTransfer);
  const alerts = useAlert();
  const { t } = useTranslation('softphone');

  const blindTransfer = useCallback(
    (call: EstablishedCall, name: string) => {
      if (!client) {
        throw new Error('No Client');
      }

      if (!resolvedDialAddress) {
        throw new Error('No Phone number or URI');
      }

      emit('blind-transfer.sent', { call });
      return call.session
        .refer(makeTargetURI(resolvedDialAddress), {
          requestDelegate: {
            onReject(response) {
              emit('blind-transfer.failed', { call, message: response.message.reasonPhrase ?? '' });
            },
          },
        })
        .then(() => {
          setIsMuted(false);
          emit('blind-call.transferred', { call });
          alerts.success(t('Successfully transferred call with {{name}}', { name }));
          return terminateCall(call, 'blind-transfer');
        })
        .catch((err: unknown) => {
          console.error('Error transferring call', err);
          emit('blind-transfer.failed', { call, message: `${err}` });
          throw err;
        });
    },
    [client, setIsMuted, emit, terminateCall, resolvedDialAddress]
  );

  const startWarmTransfer = useCallback(
    (existingCall: EstablishedCall) => {
      if (!client) {
        throw new Error('No Client');
      }
      if (!resolvedDialAddress) {
        throw new Error('No Phone number or URI');
      }

      return placeCallFromDialer().then((res) => {
        toggleHold(existingCall, true);
        setCurrentTransfer({ initialCallId: existingCall.id, transferTargetId: res.id });
        emit('attended-transfer.third-party.invitation.sent', { call: existingCall, thirdParty: res });
        return res;
      });
    },
    [client, toggleHold, placeCallFromDialer, resolvedDialAddress]
  );

  const confirmWarmTransfer = useCallback(
    (name: string) => {
      if (!currTransfer) {
        throw new Error('No current transfer established');
      }
      if (!isEstablishedCall(currTransfer.transferTarget)) {
        throw new Error('The transfer target session must be established');
      }

      return currTransfer.initialCall.session.refer(currTransfer.transferTarget.session).then((refer) => {
        setCurrentTransfer(undefined);
        emit('attended-transfer.after-established.transferred', {
          call: currTransfer.initialCall,
          thirdParty: currTransfer.transferTarget as EstablishedCall,
        });

        alerts.success(t('Successfully transferred call with {{name}}', { name }));
        return refer;
      });
    },
    [currTransfer]
  );

  return useMemo(
    () => ({
      blindTransfer,
      startWarmTransfer,
      confirmWarmTransfer,
    }),
    [blindTransfer, startWarmTransfer]
  );
};
