import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { InvoiceModel } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import {
  CollectPaymentModal,
  CollectPaymentModalSteps,
  useValidPhoneNumberFromInvoice,
} from '@frontend/payments-collection-flow';
import { useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { theme } from '@frontend/theme';
import {
  Button,
  CheckIconSmall,
  ConfirmationModal,
  CopyIcon,
  DeleteIcon,
  MessageIcon,
  ModalControlResponse,
  MoreIcon,
  PopoverMenu,
  PopoverMenuItem,
  Text,
  useModalControl,
  usePopoverMenu,
  useTooltip,
} from '@frontend/design-system';
import { useCancelInvoice, useNavigateWithAlert } from '../../hooks';
import { isPaidInvoice, PaymentsNavAlerts } from '../../utils';

const styles = {
  collectPayment: css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${theme.spacing(2)};
  `,
  autoWidth: css`
    width: auto;
  `,
};

type TooltipStylesProps = { copied: boolean };

const tooltipStyles = (props: TooltipStylesProps) => css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  .copy-status-icon {
    fill: ${props.copied ? theme.colors.success[50] : theme.colors.white};
  }
`;

type SummaryCardProps = {
  invoice: InvoiceModel;
  collectModalControl: ModalControlResponse;
};

export const PaymentRequestActions = ({ invoice, collectModalControl }: SummaryCardProps) => {
  const { t } = useTranslation('payments');
  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');

  const { cancelInvoice } = useCancelInvoice();
  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);

  const {
    modalProps: collectPaymentModalProps,
    triggerProps: collectPaymentModalTriggerProps,
    closeModal,
  } = collectModalControl;

  const { modalProps: deleteRequestModalProps, triggerProps: deleteRequestTriggerProps } = useModalControl();

  const { getTriggerProps, getMenuProps, close } = usePopoverMenu({ placement: 'bottom-start' });
  const menuTriggerProps = getTriggerProps();
  const menuProps = getMenuProps();
  const { triggerProps: tooltipTriggerProps, tooltipProps, Tooltip } = useTooltip({ hoverDelay: 500 });

  const [copied, setCopied] = useState(false);
  const [skipToStep, setSkipToStep] = useState<CollectPaymentModalSteps>(CollectPaymentModalSteps.PaymentFlowList);
  const [deleted, setDeleted] = useState(false);

  useNavigateWithAlert({
    enable: deleted,
    key: PaymentsNavAlerts.CancelInvoice,
    to: '/payments/invoices',
    type: 'success',
  });

  const handleCollectPaymentClick = () => {
    setSelectedInvoiceId(invoice.id);
    collectPaymentModalTriggerProps.onClick();
  };

  const handleSendReminderClick = () => {
    setSelectedInvoiceId(invoice.id);
    setSkipToStep(CollectPaymentModalSteps.ShareInMessage);
    collectPaymentModalTriggerProps.onClick();
    close();
  };

  const handleConfirmDelete = () => {
    cancelInvoice(invoice);
    setDeleted(true);
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  if (isPaidInvoice(invoice)) return null;

  return (
    <>
      <div css={styles.collectPayment}>
        <Button variant={'primary'} css={styles.autoWidth} onClick={handleCollectPaymentClick}>
          {t('Collect Payment')}
        </Button>
        <Button variant='secondary' css={styles.autoWidth} {...menuTriggerProps}>
          <MoreIcon size={16} />
        </Button>
        <PopoverMenu {...menuProps}>
          <PopoverMenuItem Icon={CopyIcon}>
            <CopyToClipboard text={invoice?.links?.payment || ''} onCopy={() => setCopied(true)}>
              <span {...tooltipTriggerProps}>{t('Copy Payment Link')}</span>
            </CopyToClipboard>
          </PopoverMenuItem>
          {firstValidPhoneNumber && (
            <PopoverMenuItem Icon={MessageIcon} onClick={handleSendReminderClick}>
              {t('Send Reminder')}
            </PopoverMenuItem>
          )}
          <PopoverMenuItem Icon={DeleteIcon} destructive {...deleteRequestTriggerProps}>
            {t('Delete Invoice')}
          </PopoverMenuItem>
        </PopoverMenu>
        <Tooltip {...tooltipProps} css={tooltipStyles({ copied })}>
          {copied ? (
            <CheckIconSmall className='copy-status-icon' size={16} />
          ) : (
            <CopyIcon className='copy-status-icon' size={16} />
          )}
          <Text css={{ margin: 0 }} color='white'>
            {copied ? t('Link copied!') : t('Copy link')}
          </Text>
        </Tooltip>
      </div>
      <CollectPaymentModal modalProps={collectPaymentModalProps} closeModal={closeModal} skipToStep={skipToStep} />
      <ConfirmationModal
        {...deleteRequestModalProps}
        destructive
        title={t('Delete Payment Request')}
        message={t('This cannot be undone. Are you sure you want to delete this request?')}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};
