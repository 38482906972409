import { PageRequest } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { LimitedSchemaInfiniteQueryOptions, LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { GetPacketListIO, GetPacketListV2IO, GetPacketListV3IO, GetPacketDetailsIO } from '../../types';
import { defaultOptions } from '../config';
import { requestKeys } from '../keys';

/**
 * @param companyId - AKA locationId
 */
export const usePacketList = <E = unknown, T = GetPacketListIO['output']>(
  req: Compulsory<GetPacketListIO['input'], 'companyId'>,
  options?: LimitedSchemaQueryOptions<GetPacketListIO, E, T>
) => {
  const queryKey = requestKeys.packetList(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetPacketList(req, { headers: { 'Location-Id': req.companyId } }),
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * @param companyIds - AKA locationIds
 * @deprecated Use `usePacketListV3` instead
 */
export const usePacketListV2 = <E = unknown, T = GetPacketListV2IO['output']>(
  req: Compulsory<GetPacketListV2IO['input'], 'companyIds' | 'pageRequest'>,
  options?: LimitedSchemaInfiniteQueryOptions<GetPacketListV2IO, E, T>
) => {
  const queryKey = requestKeys.packetListV2(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: PageRequest }) =>
      SchemaDigitalForms.GetPackets({
        ...req,
        pageRequest: pageParam || req.pageRequest,
      }),
    getNextPageParam: (lastPage): PageRequest => {
      const page = lastPage.pageMetadata?.page;
      return {
        pageNumber: page ? parseInt(page, 10) : undefined,
        pageLimit: req.pageRequest.pageLimit,
      };
    },
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

export const usePacketListV3 = <E = unknown, T = GetPacketListV3IO['output']>(
  req: Compulsory<GetPacketListV3IO['input'], 'orgRequest' | 'pageRequest'>,
  options?: LimitedSchemaInfiniteQueryOptions<GetPacketListV3IO, E, T>
) => {
  const queryKey = requestKeys.packetListV3(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: PageRequest }) =>
      SchemaDigitalForms.GetPacketsV3({
        ...req,
        pageRequest: pageParam || req.pageRequest,
      }),
    getNextPageParam: (lastPage): PageRequest => {
      const page = lastPage.pageMetadata?.page;
      return {
        pageNumber: page ? parseInt(page, 10) : undefined,
        pageLimit: req.pageRequest.pageLimit,
      };
    },
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useGetPacketDetails = <E = unknown, T = GetPacketDetailsIO['output']>(
  req: Compulsory<GetPacketDetailsIO['input'], 'packetId' | 'orgId'>,
  options?: LimitedSchemaQueryOptions<GetPacketDetailsIO, E, T>
) => {
  const queryKey = requestKeys.packetDetails(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetPacketDetails(req),
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};
