import { useEffect, useMemo } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { usePracticeAnalyticsShallowStore } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { getPracticeAnalyticsQueryVariables } from './helpers';
import { NewPatientsMetricMulti, NewPatientsMetricSingle } from '.';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      newPatients {
        historicalData(start: $start, end: $end, step: $step) {
          total
          label
        }
        totals(start: $start, end: $end, step: $step) {
          patients
          production
          productionPerDay {
            date
            amount
          }
          unscheduledProduction
          unscheduledProductionPerDay {
            date
            amount
          }
        }
      }
    }
  }
`;

const colors = {
  newPatients: theme.colors.warning50,
  completedProduction: theme.colors.success20,
  uncapturedProduction: theme.colors.critical40,
};

export const NewPatientsMetric = (props: PracticeAnalyticsTypes.MetricProps) => {
  const { isDrillDownPage, onFetchStateChange } = props;

  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;
  const showLocationComparison = isDrillDownPage && multipleLocationsSelected;

  const labels = {
    newPatients: t('New Patients'),
    completedProduction: t('Completed Production ($)'),
    uncapturedProduction: t('Uncaptured Production ($)'),
  };

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(`newPatientsSummary-${isDemoAccount}-${JSON.stringify(filters)}`),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.NewPatientsTrend>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.newPatients : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const aggregatedData = useMemo(() => {
    return PracticeAnalyticsAggregations.newPatientsSummary(data);
  }, [data?.data]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return showLocationComparison ? (
    <NewPatientsMetricMulti {...props} colors={colors} data={aggregatedData} isLoading={isLoading} labels={labels} />
  ) : (
    <NewPatientsMetricSingle {...props} colors={colors} data={aggregatedData} isLoading={isLoading} labels={labels} />
  );
};
