import { useMemo, useState } from 'react';
import {
  Batch,
  BatchStatus,
  GetBatchesRequest,
  GetBatchesResponse,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import {
  EmailCampaign,
  GetSegmentsRequest,
  ListEmailDraftsRequest,
  ListEmailDraftsResponse,
  ListEmailMessagesRequest,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-campaign/v1/emailcampaign.pb';
import {
  Audience,
  Campaign,
  GetAudienceRequest,
  GetCampaignRequest,
  GetCampaignsSummaryRequest,
  GetUsageRequest,
  GetUsageResponse,
  ListActiveYearsRequest,
  ListCampaignsRequest,
  LocationUsage,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { UseQueryOptions, useQueries, useQuery, useQueryClient } from 'react-query';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';
import { SchemaBulkMessage, SchemaBulkMessagingService, SchemaEmailCampaignService } from './service';
import { BulkUsageInfo, QueryKeysType } from './types';
import { sortCampaigns } from './utils';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const keys = {
  base: ['bulk-messaging'],
  getAudience: (request: GetAudienceRequest) => [...keys.base, 'get-audience', request],
  getCampaign: (request: GetCampaignRequest) => [...keys.base, 'get-campaign', request],
  getCampaignsSummary: (request: GetCampaignsSummaryRequest) => [...keys.base, 'get-campaigns-summary', request],
  getUsage: (request: GetUsageRequest, month?: number) => [...keys.base, 'get-usage', request, month],
  listActiveYears: (request: ListActiveYearsRequest) => [...keys.base, 'list-active-years', request],
  listCampaigns: (request: ListCampaignsRequest) => [...keys.base, 'list-campaigns', request],

  getSegments: (request: GetSegmentsRequest) => [...keys.base, 'get-segments', request],
  listEmailDrafts: (request: ListEmailDraftsRequest) => [...keys.base, 'list-email-drafts', request],
  listEmailMessages: (request: ListEmailMessagesRequest) => [...keys.base, 'list-email-messages', request],
};

export const useExpandCampaignsSummary = (onError?: (err: unknown) => void) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const expandMonth = async (request: GetCampaignsSummaryRequest, month: number) => {
    try {
      const listCampaignsRequest: ListCampaignsRequest = {
        orgId: request.orgId,
        locationIds: request.locationIds,
        types: request.types,
        sort: request.sort,
        year: request.year,
        month,
      };

      const campaignsSummaryQueryKey = keys.getCampaignsSummary(request);
      const listCampaignsQueryKey = keys.listCampaigns(listCampaignsRequest);

      // Check if the selected month's email campaigns are already cached
      if (queryClient.getQueryData(listCampaignsQueryKey)) return;

      setIsLoading(true);

      // Get the campaign summary from the cache
      const campaignsSummary = queryClient.getQueryData<Campaign[]>(campaignsSummaryQueryKey) ?? [];

      // Fetch all campaigns for the month to expand
      // TODO: Adjust this for pagination
      const response = await SchemaBulkMessagingService.ListCampaigns(listCampaignsRequest);
      const additionalCampaigns = response.campaigns;

      // Combine the summary campaigns with the additional campaigns. Remove the duplicates and sort everything.
      const updatedCampaignsSummary = sortCampaigns([...campaignsSummary, ...additionalCampaigns]).filter(
        (firstCampaign, index, arr) =>
          arr.findIndex((secondCampaign) => firstCampaign.campaignId === secondCampaign.campaignId) === index
      );

      // Update the cache
      queryClient.setQueryData(campaignsSummaryQueryKey, updatedCampaignsSummary);
      queryClient.setQueryData(listCampaignsQueryKey, additionalCampaigns);
    } catch (err) {
      onError?.(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { expandMonth, isLoading };
};

// TODO: Replace UseQueryOptions with helper types from the query helper library
export const useGetAudience = (request: GetAudienceRequest, options?: UseQueryOptions<Audience, unknown, Audience>) =>
  useQuery({
    ...defaultOptions,
    queryKey: keys.getAudience(request),
    queryFn: () => SchemaBulkMessagingService.GetAudience(request),
    enabled: !!request.campaignId,
    ...options,
  });

export const useGetCampaign = (request: GetCampaignRequest, options?: UseQueryOptions<Campaign, unknown, Campaign>) =>
  useQuery({
    ...defaultOptions,
    queryKey: keys.getCampaign(request),
    queryFn: () => SchemaBulkMessagingService.GetCampaign(request),
    ...options,
  });

export const useGetCampaignsSummary = (
  request: GetCampaignsSummaryRequest,
  options?: UseQueryOptions<Campaign[], unknown, Campaign[]>
) =>
  useQuery({
    ...defaultOptions,
    queryKey: keys.getCampaignsSummary(request),
    queryFn: async () => {
      const data = await SchemaBulkMessagingService.GetCampaignsSummary(request);
      return data.campaigns;
    },
    ...options,
  });

export const useGetUsage = (
  request: GetUsageRequest,
  month?: number,
  options?: UseQueryOptions<Record<string, LocationUsage>, unknown, Record<string, LocationUsage>>
) =>
  useQuery({
    ...defaultOptions,
    queryKey: keys.getUsage(request, month),
    queryFn: async () => {
      const data = await SchemaBulkMessagingService.GetUsage(request);
      if (!month) return data.locations;
      const filteredData: GetUsageResponse = { locations: {} };
      Object.entries(data.locations).forEach(([locationId, locationUsage]) => {
        filteredData.locations[locationId] = {
          usage: [
            locationUsage.usage?.find((usage) => usage.month === month) ?? {
              allotment: 0,
              error: 0,
              month,
              scheduled: 0,
              sent: 0,
            },
          ],
        };
      });
      return filteredData.locations;
    },
    ...options,
  });

export const useListActiveYears = (
  request: ListActiveYearsRequest,
  options?: UseQueryOptions<number[], unknown, number[]>
) =>
  useQuery({
    ...defaultOptions,
    queryKey: keys.listActiveYears(request),
    queryFn: async () => {
      const data = await SchemaBulkMessagingService.ListActiveYears(request);
      return data.years;
    },
    enabled: !!request.locationIds.length,
    ...options,
  });

export const useListEmailDrafts = (request: ListEmailDraftsRequest) =>
  useQuery({
    queryKey: keys.listEmailDrafts(request),
    queryFn: () => SchemaEmailCampaignService.ListEmailDrafts(request),
    select: (data) => data.draftCampaigns,
    enabled: !!request.locationIds?.length,
  });

type Draft = (Batch | EmailCampaign) & { type: 'email' | 'text' };
export const useCombinedCampaignDrafts = (
  bulkEmailRequest: ListEmailDraftsRequest,
  bulkTextRequest: GetBatchesRequest,
  emailEnabled: boolean,
  textEnabled: boolean
) => {
  const listEmailDraftsQueryKey = keys.listEmailDrafts(bulkEmailRequest) as QueryKeysType;
  // Just hard coded in the queryKey from the `useBulkQueryKeys` file in the messaging service. One less service to import especially that this query is used in the navigation. And no circular dependency.
  const listTextDraftsQueryKey = [
    bulkTextRequest.locationId,
    'bulk-messages',
    'batches',
    bulkTextRequest.direction,
    bulkTextRequest.year,
    bulkTextRequest.month,
  ] as QueryKeysType;
  const [{ data: emailDrafts, isFetched: emailDraftsIsFetched }, { data: textDrafts, isFetched: textDraftsIsFetched }] =
    useQueries([
      {
        queryKey: listEmailDraftsQueryKey,
        queryFn: () => SchemaEmailCampaignService.ListEmailDrafts(bulkEmailRequest),
        select: (data: ListEmailDraftsResponse) =>
          data.draftCampaigns?.map((campaign) => ({ ...campaign, type: 'email' })),
        enabled: !!bulkEmailRequest?.locationIds?.length && emailEnabled,
      },
      {
        queryKey: listTextDraftsQueryKey,
        queryFn: () => SchemaBulkMessage.GetBatches(bulkTextRequest),
        select: (data: GetBatchesResponse) =>
          data.batches
            ?.flatMap((monthlyBatches) => monthlyBatches.batches ?? [])
            .filter((batch) => batch.status === BatchStatus.DRAFT)
            .map((batch) => ({ ...batch, type: 'text' })),
        enabled: !!bulkTextRequest?.locationId && textEnabled,
      },
    ]);
  const drafts = [...(emailDrafts || []), ...(textDrafts || [])] as Draft[];
  return {
    count: drafts.length,
    drafts,
    isFetched: emailDraftsIsFetched && textDraftsIsFetched,
    listEmailDraftsQueryKey,
    listTextDraftsQueryKey,
  };
};

export const useListEmailMessages = (req: ListEmailMessagesRequest) =>
  useQuery({
    queryKey: keys.listEmailMessages(req),
    queryFn: () => SchemaEmailCampaignService.ListEmailMessages(req),
    enabled: !!req.campaignId,
  });

export const useGetMultiBulkMessageUsageInfo = (locationIds: string[], year: number, month?: number) => {
  const queryResults = useQueries(
    locationIds.map((locationId) => ({
      queryKey: [locationId, 'bulk-message-usage', year, month ?? 'all'],
      queryFn: async (): Promise<[string, BulkUsageInfo]> => {
        const res = await SchemaBulkMessage.GetBatches({
          locationId,
          year,
          month,
        });

        const usageInfo = (res?.batches ?? []).reduce<BulkUsageInfo>(
          (acc, batch) => ({
            allotment: acc.allotment || (batch.usage?.quota ?? 0),
            sentAndFailed: acc.sentAndFailed + (batch.usage?.sent ?? 0) + (batch.usage?.failed ?? 0),
            scheduled: acc.scheduled + (batch.usage?.pending ?? 0),
          }),
          { allotment: 0, sentAndFailed: 0, scheduled: 0 }
        );

        return [locationId, usageInfo];
      },
      enabled: !!locationId && !!year,
    }))
  );

  const messageUsageInfoMap = useMemo<Map<string, BulkUsageInfo>>(
    () =>
      queryResults.reduce<Map<string, BulkUsageInfo>>((usageMap, queryResult) => {
        if (!queryResult?.data) return usageMap;

        const [locationId, usageInfo] = queryResult.data;
        if (!locationId || (!usageInfo.allotment && !usageInfo.scheduled && !usageInfo.sentAndFailed)) return usageMap;

        usageMap.set(locationId, usageInfo);
        return usageMap;
      }, new Map<string, BulkUsageInfo>()),
    [queryResults]
  );

  const isLoading = queryResults.some((result) => result.isLoading);
  return { messageUsageInfoMap, isLoading };
};

interface UseGetMultiBulkEmailUsageInfoParams {
  orgId: string;
  locationIds: string[];
  year: number;
  month?: number;
}

export const useGetMultiBulkEmailUsageInfo = ({
  orgId,
  locationIds,
  year,
  month,
}: UseGetMultiBulkEmailUsageInfoParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ['yearly-bulk-text-batches', orgId, ...locationIds],
    queryFn: () => SchemaEmailCampaignService.ListUsageAndAllotment({ locationIds, orgId, year }),
    enabled: !!locationIds.length && !!orgId && !!year,
  });

  const emailUsageInfoMap = useMemo(() => {
    const batches = data?.usageAndAllotment ?? [];
    const emailUsageInfoMap = new Map<string, BulkUsageInfo>();
    batches.forEach((batch) => {
      if (!batch.locationId || (!!month && batch.month !== month)) return;

      const location = emailUsageInfoMap.get(batch.locationId);
      emailUsageInfoMap.set(batch.locationId, {
        allotment: (location?.allotment ?? 0) || (batch.allotment ?? 0),
        sentAndFailed: (location ? location.sentAndFailed : 0) + (batch.sent ?? 0) + (batch.errorAgainstUsage ?? 0),
        scheduled: (location ? location.scheduled : 0) + (batch.scheduled ?? 0),
      });
    });

    return emailUsageInfoMap;
  }, [data?.usageAndAllotment]);

  return { emailUsageInfoMap, isLoading };
};

export const useGetSegments = (req: GetSegmentsRequest) =>
  useQuery({
    queryKey: keys.getSegments(req),
    queryFn: () => SchemaEmailCampaignService.GetSegments(req),
    enabled: !!req.campaignId,
    select: (data) => data.segments,
  });
