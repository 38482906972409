import { useMutation } from 'react-query';
import { LimitedSchemaMutationOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { UpdateReviewRequiredFlagForPacketIO, CreatePacketV2IO, UpdatePacketV2IO, DeletePacketV2IO } from '../../types';
import { endpointKeys } from '../keys';

export const useUpdateReviewRequiredFlagForPacket = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<UpdateReviewRequiredFlagForPacketIO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.updateReviewRequiredFlagForPacket,
    mutationFn: (req) => SchemaDigitalForms.UpdatePacketReviewRequiredFlag(req),
    ...options,
  });
};

export const useCreatePacketV2 = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<CreatePacketV2IO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.createPacketV2,
    mutationFn: (req) => SchemaDigitalForms.CreatePacketV2(req),
    ...options,
  });
};

export const useUpdatePacketV2 = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<UpdatePacketV2IO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.updatePacketV2,
    mutationFn: (req) => SchemaDigitalForms.UpdatePacketV2(req),
    ...options,
  });
};

export const useDeletePacketV2 = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<DeletePacketV2IO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.deletePacketV2,
    mutationFn: (req) => SchemaDigitalForms.DeletePacketV2(req),
    ...options,
  });
};
