import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { gql } from 'graphql-request';
import { capitalize } from 'lodash-es';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { CategoryBarChartData } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Tabs, useAlert } from '@frontend/design-system';
import { usePaNavigate, usePracticeAnalyticsShallowStore } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { trackingIds } from '../../../tracking-ids';
import { commonStyles } from '../../../views';
import { PracticeAnalyticsURLs } from '../constants';
import { getPracticeAnalyticsQueryVariables } from '../helpers';
import { PractitionerAnalysisCategoryChart } from '../practitioner-analysis-category-chart';
import { PractitionerAnalysisPatients } from '../practitioner-analysis-patients';
import { PractitionerInlineStats } from '../practitioner-inline-stats';

const requiredSummaryFields = `
  accepted
  acceptedPercent
  diagnosed
  diagnosedPercent
  practitionerName
  qualifiedVisits
  unscheduledTreatment
`;

const requiredPatientsDetails = `
  details(start: $start, end: $end, step: $step) {
    patients {
      diagnosed
      FirstName: firstName
      HomePhone: homePhone
      id
      LastName: lastName
      MobilePhone: mobilePhone
      procedures {
        adaCode
        date
        procedureAmount
      }
      productionAmount
      WorkPhone: workPhone
    }
  }
`;

const requiredTotals = `
  totals(start: $start, end: $end, step: $step) {
    accepted
    acceptedPercent
    diagnosed
    diagnosedPercent
    qualifiedVisits
    unscheduledTreatment
  }
`;

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      treatmentPlan(treatmentPlanType: RESTORATIVE) {
        practitionerTreatmentPlanAnalytics(start: $start, end: $end, step: $step) {
          practitionerRestorativeTreatmentPlanTotals {
            ${requiredSummaryFields}
          }
          practitionerHygieneTreatmentPlanTotals {
            ${requiredSummaryFields}
          }
        }
      }
      hygieneTreatmentPlan: treatmentPlan(treatmentPlanType: HYGIENE) {
        ${requiredPatientsDetails}
        ${requiredTotals}
      }
      restorativeTreatmentPlan: treatmentPlan(treatmentPlanType: RESTORATIVE) {
        ${requiredPatientsDetails}
        ${requiredTotals}
      }
    }
  }
`;

type Props = PracticeAnalyticsTypes.MetricSubViewProps & {
  locationId?: string;
};

const generatePractitionerChartData = (data?: PracticeAnalyticsTypes.PractitionerTreatmentPlanAnalytics[]) =>
  data?.reduce(
    (acc, { acceptedPercent = 0, diagnosedPercent = 0, practitionerName = '', unscheduledTreatment = 0 }) => {
      acc[practitionerName.replace(/\w+/g, capitalize)] = {
        diagnosedPercent: diagnosedPercent * 100,
        acceptedPercent: acceptedPercent * 100,
        unscheduledTreatment,
      };
      return acc;
    },
    {} as CategoryBarChartData
  );

const generateLocationsChartData = (
  data: Record<string, PracticeAnalyticsTypes.PractitionerAnalysisDetailsResponse>,
  key: 'hygieneTreatmentPlan' | 'restorativeTreatmentPlan'
) =>
  Object.entries(data).reduce((acc, [locationId, locationDetails]) => {
    const {
      acceptedPercent = 0,
      diagnosedPercent = 0,
      unscheduledTreatment = 0,
    } = locationDetails.location[key]?.totals || {};

    acc[locationId] = {
      diagnosedPercent: diagnosedPercent * 100,
      acceptedPercent: acceptedPercent * 100,
      unscheduledTreatment,
    };
    return acc;
  }, {} as CategoryBarChartData);

export const PractitionerAnalysisSubView = ({ exportEnabled, locationId }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const navigate = usePaNavigate();
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const multipleLocationsSelected = !locationId && (filters.locations?.length || 0) > 1;

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(
      `practitioner-analysis-details-${isDemoAccount}-${JSON.stringify(filters)}`
    ),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.PractitionerAnalysisDetailsResponse>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? demoData?.practitionerAnalysisDetails : data;
    },
    staleTime: 1000 * 60 * 5,
  });

  const processedData = useMemo(() => {
    const { aggregatedData, rawData } = PracticeAnalyticsAggregations.practitionerAnalysisDetails(data);
    const specificLocation = locationId ? rawData[locationId]?.location : null;

    const hygieneChartData = multipleLocationsSelected
      ? generateLocationsChartData(rawData, 'hygieneTreatmentPlan')
      : generatePractitionerChartData(
          specificLocation
            ? specificLocation.treatmentPlan.practitionerTreatmentPlanAnalytics?.practitionerHygieneTreatmentPlanTotals
            : aggregatedData.treatmentPlan.practitionerTreatmentPlanAnalytics.practitionerHygieneTreatmentPlanTotals
        );

    const restorativeChartData = multipleLocationsSelected
      ? generateLocationsChartData(rawData, 'restorativeTreatmentPlan')
      : generatePractitionerChartData(
          specificLocation
            ? specificLocation.treatmentPlan.practitionerTreatmentPlanAnalytics
                ?.practitionerRestorativeTreatmentPlanTotals
            : aggregatedData.treatmentPlan.practitionerTreatmentPlanAnalytics.practitionerRestorativeTreatmentPlanTotals
        );

    return {
      hygieneChartData,
      hygienePatients: specificLocation
        ? specificLocation.hygieneTreatmentPlan?.details?.patients
        : aggregatedData.hygieneTreatmentPlan.details.patients,
      hygieneSummary: specificLocation
        ? specificLocation.hygieneTreatmentPlan?.totals
        : aggregatedData.hygieneTreatmentPlan.totals,
      restorativeChartData,
      restorativePatients: specificLocation
        ? specificLocation.restorativeTreatmentPlan?.details?.patients
        : aggregatedData.restorativeTreatmentPlan.details.patients,
      restorativeSummary: specificLocation
        ? specificLocation.restorativeTreatmentPlan?.totals
        : aggregatedData.restorativeTreatmentPlan.totals,
    };
  }, [data?.data, locationId]);

  const handleGroupClick = useCallback((locationId: string) => {
    navigate({
      to: `${PracticeAnalyticsURLs.BASE}/practitioner-analysis/${locationId}`,
    });
  }, []);

  return (
    <Tabs initialTab='hygiene-treatment'>
      <Tabs.Bar css={styles.tabBar}>
        <Tabs.Tab
          controls='hygiene-treatment-panel'
          id='hygiene-treatment'
          trackingId={trackingIds.practiceAnalytics.hygienePractitionerAnalysisTab}
        >
          {t('Hygiene Treatment')}
        </Tabs.Tab>
        <Tabs.Tab
          controls='restorative-treatment-panel'
          id='restorative-treatment'
          trackingId={trackingIds.practiceAnalytics.restorativePractitionerAnalysisTab}
        >
          {t('Restorative Treatment')}
        </Tabs.Tab>
      </Tabs.Bar>

      <Tabs.Panel controller='hygiene-treatment' css={styles.panel} id='hygiene-treatment-panel'>
        <PractitionerInlineStats data={processedData.hygieneSummary} isLoading={isLoading} />
        <PractitionerAnalysisCategoryChart
          data={processedData.hygieneChartData}
          isLoading={isLoading}
          isLocationsComparison={multipleLocationsSelected}
          onGroupClick={multipleLocationsSelected ? handleGroupClick : null}
        />
        <hr css={commonStyles.hr} />
        <PractitionerAnalysisPatients
          data={processedData.hygienePatients}
          isExportEnabled={exportEnabled}
          isLoading={isLoading}
          locationId={locationId}
          tab='hygiene-treatment'
        />
      </Tabs.Panel>

      <Tabs.Panel controller='restorative-treatment' css={styles.panel} id='restorative-treatment-panel'>
        <PractitionerInlineStats data={processedData.restorativeSummary} isLoading={isLoading} />
        <PractitionerAnalysisCategoryChart
          data={processedData.restorativeChartData}
          isLoading={isLoading}
          isLocationsComparison={multipleLocationsSelected}
          onGroupClick={multipleLocationsSelected ? handleGroupClick : null}
        />
        <hr css={commonStyles.hr} />
        <PractitionerAnalysisPatients
          data={processedData.restorativePatients}
          isExportEnabled={exportEnabled}
          isLoading={isLoading}
          locationId={locationId}
          tab='restorative-treatment'
        />
      </Tabs.Panel>
    </Tabs>
  );
};

const styles = {
  tabBar: css`
    margin-top: ${theme.spacing(-1)};
  `,

  panel: css`
    padding: ${theme.spacing(3, 0, 0)};
  `,
};
