import { css } from '@emotion/react';
import { useTranslation, Trans } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { AlertIcon, Text, TextLink } from '@frontend/design-system';

export const ReceiptError = ({ error }: any) => {
  const { t } = useTranslation('payments');
  return (
    <>
      <Text
        color='white'
        textAlign='center'
        css={css`
          padding: ${theme.spacing(2)};
          background-color: ${theme.colors.status.critical};
          margin: 0;
        `}
      >
        <AlertIcon
          color='white'
          css={css`
            margin-right: ${theme.spacing(2)};
          `}
        />
        {t('Oops! Failed to fetch receipt.')}
      </Text>
      <Text
        textAlign='center'
        css={css`
          padding: ${theme.spacing(3, 3, 0)};
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        {!!error ? (
          <>
            <Text>{error.message}</Text>
            {error.response?.data?.data?.error && <Text>{error.response.data.data.error}</Text>}
          </>
        ) : (
          <Trans t={t}>
            We were unable to fetch this receipt{' '}
            <TextLink to='mailto:payments@getweave.com?subject=Error fetching receipt'>payments@getweave.com</TextLink>.
          </Trans>
        )}
      </Text>
    </>
  );
};
