import { FC, useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { gql } from 'graphql-request';
import { MorningHuddleTypes, PracticeAnalyticsAggregations, PracticeAnalyticsApi } from '@frontend/api-analytics';
import { AreaChartData, Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { DemoChip } from '..';
import { usePracticeAnalyticsDemoData, usePracticeAnalyticsShallowStore } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { convertToDateId, formatters } from '../../utils';

type ScheduledProductionProps = {
  reportDate: string;
};

const query = gql`
  query ($start: Int!, $end: Int!, $avgStart: Int!, $avgEnd: Int!) {
    location {
      id
      avgProduction: production {
        totals(step: DAILY, start: $avgStart, end: $avgEnd) {
          averageCompletedProductionPerDay
        }
      }
      production {
        projections(step: DAILY, start: $start, end: $end) {
          details {
            timestamp
            label
            production
          }
        }
      }
    }
  }
`;

export const ScheduledProduction: FC<React.PropsWithChildren<ScheduledProductionProps>> = ({ reportDate }) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { filters, isDemoAccount, showDemoChipAndBanner } = usePracticeAnalyticsShallowStore(
    'filters',
    'isDemoAccount',
    'showDemoChipAndBanner'
  );
  const demoData = usePracticeAnalyticsDemoData(reportDate);
  const [dateRange, setDateRange] = useState<Dayjs[]>([dayjs(reportDate), dayjs(reportDate).add(6, 'day')]);

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.morningHuddle(
      `scheduledProdution-${isDemoAccount}-${dayjs(dateRange[0]).format('YYYY-MM-DD')}-${filters.locations}`
    ),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<MorningHuddleTypes.ScheduledProductionResponse>({
            locationIds: filters.locations,
            queries: [query],
            variables: {
              avgEnd: convertToDateId(dateRange[0]),
              avgStart: convertToDateId(dateRange[0].subtract(30, 'days')),
              end: convertToDateId(dateRange[1]),
              start: convertToDateId(dateRange[0]),
            },
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => (isDemoAccount ? demoData?.morningHuddleScheduledProduction : data),
    staleTime: 1000 * 60 * 5,
  });

  const chartData = useMemo(() => {
    const aggregatedData = PracticeAnalyticsAggregations.morningHuddleScheduledProduction(
      data,
      filters.locations?.length
    );

    const { avgProduction, production } = aggregatedData || {};
    const processedData = production?.projections?.details?.reduce(
      (acc, { production, timestamp }) => {
        const x = formatters.date.format(timestamp, true);

        return {
          ...acc,
          groups: [
            ...(acc.groups || []),
            {
              name: x,
              values: {
                average: avgProduction?.totals?.averageCompletedProductionPerDay || 0,
                scheduled: production,
              },
            },
          ],
        };
      },
      {
        groups: [],
      } as AreaChartData
    );

    return {
      data: processedData,
      average: avgProduction?.totals?.averageCompletedProductionPerDay || 0,
      scheduled: production?.projections?.details?.reduce((acc, { production }) => acc + production, 0) || 0,
    };
  }, [data]);

  useEffect(() => {
    setDateRange([dayjs(reportDate), dayjs(reportDate).add(6, 'day')]);
  }, [reportDate]);

  return (
    <Chart
      colors={{ average: theme.colors.success30, scheduled: theme.colors.primary20 }}
      isLoading={isLoading}
      labels={{ average: t('Average Day Production - Last 30 days'), scheduled: t('Upcoming Scheduled - Next 7 Days') }}
    >
      <Chart.Header
        leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
        title={t('Scheduled Production - Next 7 Days')}
      />
      <Chart.Legends
        values={{
          scheduled: formatters.currency.format(chartData.scheduled),
          average: formatters.currency.format(chartData.average),
        }}
      />
      <Chart.AreaChart
        appearance={{
          showXAxis: true,
          showYAxis: true,
        }}
        data={chartData.data}
        formatValue={formatters.currency.format}
      />
    </Chart>
  );
};
