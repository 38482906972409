import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { LargeInfo } from '@frontend/payments-collection-flow';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';
import { formatCentsToCurrency } from '../../../utils';

type SummaryCardProps = {
  invoice: InvoiceModel;
};

const styles = {
  summarySectionRow: css`
    display: flex;
    margin-bottom: ${theme.spacing(3)};
    flex-wrap: wrap;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  `,
  summary: css`
    display: flex;
    flex-direction: column;
  `,
  amount: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
  `,
  collectPayment: css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: ${theme.spacing(2)};
  `,
  autoWidth: css`
    width: auto;
  `,
};

export const SummaryCard = ({ invoice }: SummaryCardProps) => {
  const { t } = useTranslation('payments');

  return (
    <section css={styles.summarySectionRow}>
      <div css={styles.summary}>
        <Heading level={3}>{invoice.person.name}</Heading>
        <div css={styles.amount}>
          <LargeInfo title={t('Amount')} info={formatCentsToCurrency(invoice.billedAmount)} />
          <LargeInfo title={t('Payment Due')} info={formatDate(invoice.billedAt, 'MMMM D, YYYY')} />
        </div>
      </div>
    </section>
  );
};
