import { formatDate } from '@frontend/date';
import { TFunction } from '@frontend/i18n';
import { TableColumnConfig } from '@frontend/design-system';
import { HistoryRow } from '../../../types';
import { formatCentsToCurrency } from '../../../utils';

export const generateColumns = (t: TFunction): TableColumnConfig<HistoryRow>[] => [
  {
    id: 'type',
    Header: t('Type', { ns: 'payments' }),
    accessor: (row) => row.type,
    cellConfig: {
      element: 'Text',
      weight: 'bold',
      showHoverLabel: true,
    },
    width: 85,
  },
  {
    id: 'date',
    Header: t('Date', { ns: 'payments' }),
    accessor: (row) => formatDate(row.date, 'L'),
    width: 150,
  },
  {
    id: 'time',
    Header: t('Time', { ns: 'payments' }),
    accessor: (row) => formatDate(row.date, 'LT'),
    width: 40,
  },
  {
    id: 'user',
    Header: t('User', { ns: 'payments' }),
    accessor: ({ user }) => {
      return user || '-';
    },
    width: 50,
  },
  {
    id: 'amount',
    Header: t('Amount', { ns: 'payments' }),
    accessor: (row) => formatCentsToCurrency(row.amount),
    width: 70,
  },
];
