import { FC, useRef, useLayoutEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Text, useTooltip } from '@frontend/design-system';
import { useTeamChatStore } from '../../../providers';
import { StreamConversation } from '../../../types';
import { ChatAvatar } from '../../common';
import {
  containerStyle,
  selectedContainerStyle,
  unreadTextStyle,
  poundSignStyle,
  chatNameStyle,
  paddingForUnread,
} from './chat-list-item.styles';

interface ChatListItemProps {
  chat: StreamConversation;
}

export const ChatListItem: FC<ChatListItemProps> = ({ chat }) => {
  const { activeConversation, completeNewConversation } = useTeamChatStore([
    'activeConversation',
    'completeNewConversation',
  ]);
  const { Tooltip, tooltipProps, triggerProps, setOpen, isOpen } = useTooltip({ hoverDelay: 300 });
  const isSelected = activeConversation?.channelId === chat.channelId;
  const chatNameRef = useRef<HTMLElement | null>(null);
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const [offsetWidth, setOffsetWidth] = useState<number>(chatNameRef.current?.offsetWidth ?? 0);

  useLayoutEffect(() => {
    if (!chatNameRef.current) return;
    // need to trigger a rerender to get the width of the element after it has been rendered
    // otherwise the width will be 0 until a rerender is triggered
    setOffsetWidth(chatNameRef?.current?.offsetWidth);
  }, [chatNameRef?.current?.offsetWidth]);

  function chatNameMeasureRef(node: HTMLSpanElement) {
    if (node === null) return;

    chatNameRef.current = node;
    if (!offsetWidth) return;
    if (offsetWidth < node.scrollWidth) {
      setShowToolTip(true);
    } else {
      setShowToolTip(false);
    }
  }

  return (
    <div
      css={[containerStyle, isSelected && selectedContainerStyle]}
      onClick={() => {
        completeNewConversation(chat);
      }}
      {...(showToolTip && triggerProps)}
      onMouseOut={() => showToolTip && isOpen && setOpen(false)}
    >
      {chat.type === 'DM' ? (
        <ChatAvatar members={chat.members} />
      ) : (
        <Text size='medium' css={poundSignStyle}>
          #
        </Text>
      )}
      <Text css={css([chatNameStyle])} ref={chatNameMeasureRef}>
        {chat.name}
      </Text>
      {/* Want to avoid unnecessary re-rendering hence adding a check to conditionally render the tooltip */}
      {showToolTip && (
        <Tooltip {...tooltipProps}>
          <Text color='white'>{chat.name}</Text>
        </Tooltip>
      )}
      {!!chat.unreadCount && (
        <Text
          weight='semibold'
          size='medium'
          as='span'
          color='white'
          css={[unreadTextStyle, chat.unreadCount > 9 && paddingForUnread]}
        >
          {chat.unreadCount}
        </Text>
      )}
    </div>
  );
};
