import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { isEmpty, isNil, set } from 'lodash-es';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Chip, IconButton, Modal, ModalControlModalProps, NakedUl, Text } from '@frontend/design-system';
import { useCallIntelShallowStore, useIsMobile } from '../../../hooks';
import { trackingIds } from '../../../tracking-ids';
import { callIntelligenceUtils } from '../../../utils';
import { TaskStatusSwitcher } from '../task-status-switcher';

type PaginateParams = { start: number; total: number };

type PatientProvidedInfoProps = {
  callerInfo: Record<string, string | string[]>;
};

type TaskModalProps = {
  call: CallIntelligenceTypes.Call;
  callParticipantsMetadata: CallIntelligenceTypes.CallParticipantsMetadata;
  modalProps: ModalControlModalProps;
  onClose: () => void;
  onDeleteTaskConfirm: (taskId: string) => void;
  onNext: () => void;
  onPrevious: () => void;
  onStatusChange: (taskId: string, newStatus: CallIntelligenceTypes.TaskStatusEnum) => void;
  paginateParams: { start: number; total: number };
  task: CallIntelligenceTypes.Task;
};

type TaskModalFooterProps = {
  onDeleteTask: () => void;
  onNext: () => void;
  onPrevious: () => void;
  paginateParams: PaginateParams;
};

type ModalVariant = 'delete' | 'info';

const extractPatientInfo = (
  patientInfoLabel: Record<keyof CallIntelligenceTypes.PatientProvidedInfo, string>,
  callParticipantsMetadata: CallIntelligenceTypes.CallParticipantsMetadata
): CallIntelligenceTypes.PatientProvidedInfo => {
  const patientInfo: CallIntelligenceTypes.PatientProvidedInfo = {};

  try {
    const caller = JSON.parse(callParticipantsMetadata.caller);

    const addIfExists = (key: string, value: any) => {
      if (!isNil(value) && !isEmpty(value)) {
        set(patientInfo, key, value);
      }
    };

    addIfExists(patientInfoLabel.email, caller?.email);
    addIfExists(patientInfoLabel.address, caller?.address);
    addIfExists(patientInfoLabel.city, caller?.city);
    addIfExists(patientInfoLabel.state, caller?.state);
    addIfExists(patientInfoLabel.zipcode, caller?.postal_code);
    addIfExists(patientInfoLabel.country, caller?.country);
    addIfExists(patientInfoLabel.ssn, caller?.ssn);
    addIfExists(patientInfoLabel.dob, caller?.date_of_birth);
    addIfExists(patientInfoLabel.faxNumber, caller?.fax);

    addIfExists(patientInfoLabel.dependentName, caller?.dependents[0]?.name);
    addIfExists(patientInfoLabel.dependentPhone, caller?.dependents[0]?.phone);
    addIfExists(patientInfoLabel.dependentEmail, caller?.dependents[0]?.email);
    addIfExists(patientInfoLabel.dependentDob, caller?.dependents[0]?.date_of_birth);
    addIfExists(patientInfoLabel.dependentSsn, caller?.dependents[0]?.ssn);

    addIfExists(patientInfoLabel.insuranceProvider, caller?.insurance?.name);
    addIfExists(patientInfoLabel.insurancePhoneNumber, caller?.insurance?.phone);
    addIfExists(patientInfoLabel.insuranceFax, caller?.insurance?.fax);
    addIfExists(patientInfoLabel.insuranceEmail, caller?.insurance?.email);
    addIfExists(patientInfoLabel.insuranceMemberId, caller?.insurance?.insurance_ids);

    return patientInfo;
  } catch (error) {
    return patientInfo;
  }
};

const PatientProvidedInfo = ({ callerInfo }: PatientProvidedInfoProps) => {
  const { t } = useTranslation('analytics');

  return (
    <>
      <Text weight='bold'>{t('Patient Provided Information')}</Text>

      <NakedUl css={styles.list}>
        {Object.entries(callerInfo).map(([label, value]) => (
          <li key={label}>
            <Text color='subdued'>{label}</Text>
            {Array.isArray(value) ? <Text>{value.join(' , ')}</Text> : <Text>{value}</Text>}
          </li>
        ))}
      </NakedUl>
    </>
  );
};

const TaskModalFooter = ({
  onDeleteTask,
  paginateParams: { start, total },
  onNext,
  onPrevious,
}: TaskModalFooterProps) => {
  const { t } = useTranslation('analytics');

  const isAtStart = start === 1;
  const isAtEnd = start === total;

  return (
    <div css={styles.footerWrapper}>
      <IconButton
        label={t('Delete task')}
        size='xsmall'
        css={styles.deleteButton}
        onClick={onDeleteTask}
        trackingId={trackingIds.callIntel.taskModalDeleteBtn}
      >
        <Icon name='trash' css={styles.deleteIcon} />
      </IconButton>

      <div css={styles.paginationWrapper}>
        <Text color='subdued'>
          <Trans t={t} start={start} total={total}>
            Task {{ start }} of {{ total }}
          </Trans>
        </Text>
        <IconButton
          css={styles.paginationButton}
          disabled={isAtStart}
          label={t('Move left')}
          onClick={onPrevious}
          size='small'
          trackingId={trackingIds.callIntel.taskModalPaginateLeft}
        >
          <Icon name='caret-left-small' />
        </IconButton>

        <IconButton
          css={styles.paginationButton}
          disabled={isAtEnd}
          label={t('Move right')}
          onClick={onNext}
          size='small'
          trackingId={trackingIds.callIntel.taskModalPaginateRight}
        >
          <Icon name='caret-right-small' />
        </IconButton>
      </div>
    </div>
  );
};

export const TaskModal = ({
  call: { person, phoneNumber },
  modalProps,
  onClose,
  onNext,
  onPrevious,
  onStatusChange,
  onDeleteTaskConfirm,
  paginateParams,
  task,
  callParticipantsMetadata,
}: TaskModalProps) => {
  const isMobile = useIsMobile();

  const { chipVariants, dataLabels } = useCallIntelShallowStore('chipVariants', 'dataLabels');

  const [variant, setVariant] = useState<ModalVariant>('info');

  const { t } = useTranslation('analytics');

  const patientInfoLabel: Record<keyof CallIntelligenceTypes.PatientProvidedInfo, string> = useMemo(
    () => ({
      email: t('Email'),
      address: t('Address'),
      city: t('City'),
      state: t('State'),
      zipcode: t('Zipcode'),
      country: t('Country'),
      ssn: t('SSN'),
      dob: t('DOB'),
      faxNumber: t('Fax Number'),
      dependentName: t('Dependent Name'),
      dependentPhone: t('Dependent Phone'),
      dependentEmail: t('Dependent Email'),
      dependentDob: t('Dependent DOB'),
      dependentSsn: t('Dependent SSN'),
      insuranceProvider: t('Insurance Provider'),
      insurancePhoneNumber: t('Insurance Phone Number'),
      insuranceFax: t('Insurance Fax'),
      insuranceEmail: t('Insurance Email'),
      insuranceMemberId: t('Insurance Member ID'),
    }),
    [t]
  );

  const callerInfo = useMemo(
    () => extractPatientInfo(patientInfoLabel, callParticipantsMetadata),
    [callParticipantsMetadata]
  );

  const handleDeleteTask = () => setVariant('delete');

  const handleTaskCancel = () => setVariant('info');

  const fullName = person?.firstName || person?.lastName ? `${person?.firstName} ${person?.lastName}`.trim() : null;
  const nameORNumber =
    fullName || (phoneNumber ? formatPhoneNumber(callIntelligenceUtils.getPhoneNumber(phoneNumber)) : null);

  return (
    <Modal {...modalProps} maxWidth={!isMobile ? 600 : 342} css={styles.wrapper} onClose={onClose}>
      <Modal.Header onClose={onClose}>{variant != 'delete' ? `${task.title}` : t('Delete Task')}</Modal.Header>
      <Modal.Body css={styles.body}>
        {variant != 'delete' ? (
          <div>
            <div>
              <Text>{task.description}</Text>
              {!!Object.keys(callerInfo).length && (
                <div css={styles.infoWrapper}>
                  <PatientProvidedInfo callerInfo={callerInfo} />
                </div>
              )}
            </div>

            <div css={styles.statusWrapper}>
              <TaskStatusSwitcher
                defaultOptionValue={task.status}
                onStatusChange={(newStatus) => onStatusChange(task.id, newStatus)}
                trackingId={trackingIds.callIntel.taskModalStatusSwitcher}
                value={task.status}
              />

              <Chip key={task.type} variant={chipVariants[task.type] || 'neutral'}>
                {dataLabels.taskTypes?.[task.type] || task.type}
              </Chip>
            </div>
          </div>
        ) : (
          <div>
            <Text>
              <Trans t={t}>
                This task will be permanently deleted from the call with{' '}
                <Text as='span' weight='bold'>
                  {nameORNumber},{' '}
                </Text>
                and the call will be removed from the{' '}
                <Text as='span' weight='bold'>
                  {dataLabels.taskTypes?.[task.type] || task.type} Follow-ups
                </Text>{' '}
                list.
              </Trans>
            </Text>

            <Text css={styles.deleteConfirmation}>{t('Are you sure you want to delete this task?')}</Text>
          </div>
        )}
        <div css={styles.actionsWrapper}>
          {variant != 'delete' ? (
            <TaskModalFooter
              onDeleteTask={handleDeleteTask}
              paginateParams={paginateParams}
              onPrevious={onPrevious}
              onNext={onNext}
            />
          ) : (
            <div css={styles.deleteActionWrapper}>
              <Modal.Actions
                className='task-modal-delete-action'
                destructive={true}
                onSecondaryClick={handleTaskCancel}
                onPrimaryClick={() => onDeleteTaskConfirm(task.id)}
                primaryLabel={t('Delete')}
                primaryTrackingId={trackingIds.callIntel.taskModalConfirmDelete}
                secondaryLabel={t('Cancel')}
              ></Modal.Actions>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

const styles = {
  actionsWrapper: css`
    margin-top: ${theme.spacing(4)};
  `,

  body: css`
    margin-top: ${theme.spacing(1)};
  `,

  deleteActionWrapper: css`
    > div {
      padding: 0px;

      @media screen and (max-width: ${breakpoints.small.max}px) {
        display: flex;
        flex-direction: column;
      }
    }
  `,

  deleteButton: css`
    padding: 0px;
    border: none;
  `,

  deleteConfirmation: css`
    margin-top: ${theme.spacing(3)};
  `,

  deleteIcon: css`
    color: ${theme.colors.icon.critical};
  `,

  footerWrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,

  infoWrapper: css`
    margin-top: ${theme.spacing(4)};
  `,

  list: css`
    margin-top: ${theme.spacing(1)};
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};

    li {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    li p:first-of-type {
      margin-right: ${theme.spacing(1)};
    }

    li p:last-of-type {
      word-break: break-word;
    }
  `,

  paginationButton: css`
    border-color: ${theme.colors.neutral30};
  `,

  paginationWrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(2)};
  `,

  statusWrapper: css`
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(2)};
    gap: ${theme.spacing(1)};
    border-bottom: 1px solid ${theme.colors.neutral20};
  `,

  wrapper: css`
    padding: ${theme.spacing(4, 1)};
    width: 100%;

    @media screen and (min-width: 648px) {
      width: 100%;
    }

    .task-modal-delete-action {
      gap: ${theme.spacing(2)};

      button {
        font-weight: 700;

        @media screen and (max-width: ${breakpoints.small.max}px) {
          width: 100%;
        }
      }

      button:first-of-type {
        color: ${theme.colors.neutral70};
      }

      @media screen and (max-width: ${breakpoints.small.max}px) {
        flex-direction: column-reverse;
      }
    }
  `,
};
