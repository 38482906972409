import { Dispatch, SetStateAction, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useLocation } from '@tanstack/react-location';
import { motion } from 'framer-motion';
import { useSpring } from 'react-spring';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMediaMatches } from '@frontend/responsiveness';
import { StaticSettingsRoutes, useSettingsNavigate } from '@frontend/settings-routing';
import { useShellTheme } from '@frontend/shell-theme';
import { DeepReadonly } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  ContactsIconSpark,
  Heading,
  HelpIcon,
  ModalBackdrop,
  NakedButton,
  NakedUl,
  Text,
  NotificationBadge,
  styles,
} from '@frontend/design-system';
import { NavLink } from './nav-link';

export type NavItem = {
  icon: typeof ContactsIconSpark;
  root: `/${string}`;
  label: string;
  show?: boolean;
  hasCount?: boolean;
  settings?: {
    label: string;
    path: StaticSettingsRoutes;
    show?: boolean;
  };
  subNavItems: {
    relativePath?: string;
    path?: `/${string}`;
    label: string;
    count?: number;
    isPassiveCount?: boolean;
    show?: boolean;
    trackingId?: string;
  }[];
};

type NavItemReadonlyList = DeepReadonly<NavItem[]>;

type NavProps<T extends NavItemReadonlyList> = {
  items: T;
  currentPath?: T[number]['root'];
  onClick?: (path: `/${string}`) => void;
  open?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const notificationBubbleStyle = ({ show, borderColor }: { show: boolean; borderColor?: string }) => css`
  ::after {
    background: ${theme.colors.critical50};
    border-radius: 16px;
    border: 1px solid ${borderColor || 'white'};
    content: '';
    height: 10px;
    left: 45px;
    opacity: ${show ? 1 : 0};
    position: absolute;
    top: 6px;
    /* match the animation and color of the icon button hover */
    transition: border-color, opacity ease-in-out 250ms;
    width: 10px;
    z-index: 10;
  }
`;

const cssVariables = css`
  --nav-width: 284px;
  --nav-icon-width: 5.25em;
  --nav-item-font-size: ${theme.font.size.large};
  --nav-background-color: ${theme.colors.neutral10};
`;

const openStyle = css`
  width: calc(var(--nav-width) - var(--nav-icon-width));
`;

const PendoTrigger = () => {
  const { colorObject } = useShellTheme('colorObject');
  return (
    <NakedButton
      id='pendo-trigger'
      css={css`
        position: relative;
        align-items: center;
        background: ${colorObject.helpColor};
        display: flex;
        height: 3em;
        justify-content: center;
        transition: background 0.3s ease-in-out;
        width: var(--nav-icon-width);
        :hover {
          background: ${colorObject.hover};
        }
      `}
    >
      <HelpIcon
        css={css`
          color: ${colorObject.iconColor};
          height: 1em;
          width: 1em;
        `}
      />
    </NakedButton>
  );
};

const MotionNakedUl = motion(NakedUl);

function NavWithoutRef<T extends NavItemReadonlyList, TLinks extends string = T[number]['root']>(
  { items, currentPath = '/', onClick, open, setOpen }: NavProps<T>,
  insideRef: React.ForwardedRef<HTMLElement>
) {
  const { current } = useLocation();
  const defaultPath = ('/' + current?.pathname?.split('/')?.[1]) as TLinks;
  const { colorObject } = useShellTheme('colorObject');

  // TODO: this could be done with CSS instead of JS
  // to do this with CSS, we would need to render all the items
  // in the subnav flyout, but hide them with CSS one at a time
  const [currentParent, setCurrentParent] = useState<TLinks | undefined>(defaultPath || undefined);
  const [isSubMenu, setIsSubMenu] = useState(true);

  const subMenuRef = useRef<HTMLDivElement>(null);

  /**
   * Making this a ref, not state, to avoid re-renders.
   */
  const activeLinkCoordsRef = useRef<{ leftRect: DOMRect; rightRect: DOMRect } | null>(null);

  /**
   * Calculate the triangle path between the
   * active nave link (which has the sub menu open)
   * and the top/bottom of the sub menu.
   *
   * Doing it here, so it only needs to happen once,
   * and not a million times as the mouse moves.
   */
  const onChangeSubMenu = (activeNavLink: HTMLElement) => {
    if (!subMenuRef.current) {
      return;
    }

    const leftRect = activeNavLink.getBoundingClientRect();
    const rightRect = subMenuRef.current.getBoundingClientRect();

    activeLinkCoordsRef.current = { leftRect, rightRect };
  };

  /**
   * If mouse is in the "safe" triangle, we'll debounce
   * the mouseEnter event, to avoid menu flickering.
   *
   * Outside of the triangle, the mouseEnter event will trigger
   * a menu change immediately.
   */
  const isMouseInTriangle = (mouseCoords: Coords) => {
    if (!activeLinkCoordsRef.current) {
      return false;
    }

    return isCoordsInTriangle(mouseCoords, activeLinkCoordsRef.current.leftRect, activeLinkCoordsRef.current.rightRect);
  };

  const containerRef = useRef<HTMLUListElement>(null);
  const { matches } = useMediaMatches();
  const isWiderThanMobile = matches.mediumMin();
  const isMediumRange = matches.mediumMinMax();
  const navSize = !isWiderThanMobile ? 'mobile' : isMediumRange ? 'mini' : 'desktop';
  const subNavMenus = useMemo(
    () =>
      items.reduce(
        (acc, item) => ({
          ...acc,
          [item.root]: item.subNavItems
            ? {
                parentLink: item.root,
                parentLabel: item.label,
                items: item.subNavItems,
              }
            : undefined,
        }),
        {} as Record<TLinks, { parentLink: `/${string}`; parentLabel: string; items: NavItem['subNavItems'] }>
      ),
    [items]
  );

  const [, ...pathParts] = currentPath?.split('/') ?? [];
  const selectedParent = `/${pathParts?.[0] ?? ''}` as TLinks;

  const currentSubNavMenu = currentParent ? subNavMenus[currentParent] : subNavMenus[selectedParent];
  const currentSettingsPath = items.find(
    (item) => item.root === ((currentParent as keyof typeof items) ?? items[selectedParent as keyof typeof items])
  );
  const showSettings = currentSettingsPath?.settings && currentSettingsPath.settings.show !== false;

  // could just pass in the path, don't need the data-path
  const handleMouseEnter = (e: React.MouseEvent<HTMLButtonElement>) => {
    const target = e.target as HTMLElement;
    const path = target.dataset.path;
    if (!path) return;
    setCurrentParent(path as TLinks);
  };

  useEffect(() => {
    if (selectedParent !== currentParent) {
      setCurrentParent(undefined);
    }
  }, [selectedParent]);

  useEffect(() => {
    // every time we close the nav, go back to the sub menu
    setIsSubMenu(true);
  }, [open]);

  const backdropProps = useSpring({
    opacity: open ? 1 : 0,
  });
  if (!currentSubNavMenu) return null;
  const subMenuContent = (
    <div
      css={{
        padding: '0 0.5em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: '100%',
      }}
    >
      <div css={{ width: '100%' }}>
        <Heading
          level={3}
          css={css`
            align-items: center;
            display: flex;
            font-size: calc(1.5 * var(--nav-item-font-size));
            margin: 0.3333em 0;
            padding: 5px 8px 0 8px;
          `}
        >
          {currentSubNavMenu?.parentLabel}
        </Heading>
        <NakedUl
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            margin: 0.5em 0 0;
          `}
        >
          {currentSubNavMenu?.items?.map(({ label, count, isPassiveCount, relativePath, show, trackingId, path }) => {
            // this check can't be (!show) because show could be undefined, which means we should show the item
            if (show === false) return null;
            return (
              <li key={relativePath || path}>
                <NavLink
                  trackingId={trackingId}
                  as='button'
                  onClick={() => {
                    setOpen((open) => !open);
                    setTimeout(() => {
                      if (path) {
                        onClick?.(path);
                      } else {
                        onClick?.(`${currentSubNavMenu.parentLink}${relativePath}`);
                      }
                    }, 350);
                  }}
                  css={[
                    css`
                      align-items: center;
                      text-align: left;
                      border-radius: var(--border-radius-medium);
                      display: flex;
                      font-size: var(--nav-item-font-size);
                      height: 2.5em;
                      justify-content: space-between;
                      padding: 0 1em;
                      color: ${theme.font.colors.default};
                      width: 100%;
                      position: relative;
                      transition: background-color 0.3s ease-in-out;
                      :hover {
                        background-color: var(--colors-neutral-5);
                      }
                    `,
                    hasSameRootPath(selectedParent, currentSubNavMenu.parentLink) &&
                      hasSameSubPath(currentPath, relativePath || path || '') &&
                      css`
                        background-color: var(--nav-background-color);
                      `,
                  ]}
                >
                  <span>{label}</span>
                  {count ? (
                    <NotificationBadge
                      css={
                        isPassiveCount &&
                        css`
                          background: transparent;
                          border-radius: none;
                          color: ${theme.colors.neutral50};
                        `
                      }
                      truncateCount
                    >
                      {count}
                    </NotificationBadge>
                  ) : null}
                </NavLink>
              </li>
            );
          })}
        </NakedUl>
      </div>
      <footer css={{ marginBottom: theme.spacing(1), width: '100%' }}>
        {showSettings && (
          <SettingsNavButton label={currentSettingsPath.settings.label} path={currentSettingsPath.settings.path} />
        )}
      </footer>
    </div>
  );

  return (
    <>
      {(navSize === 'mobile' || navSize === 'mini') && (
        <>
          <nav
            className='mobile-nav'
            onKeyDown={(e) => e.key === 'Escape' && setOpen(false)}
            ref={insideRef}
            css={[
              cssVariables,
              css`
                background: var(--colors-white);
                display: flex;
                height: 100%;
                left: 0;
                position: absolute;
                transition: left 0.3s ease-in-out;
                width: var(--nav-width);
                z-index: 2;
              `,
              !open &&
                css`
                  left: ${isSubMenu
                    ? 'calc(calc(var(--nav-width) + var(--nav-icon-width)) * -1)'
                    : 'calc((var(--nav-width) * -1))'};
                `,
            ]}
          >
            <div css={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%' }}>
              <NakedUl
                className='wv-nav-main__desktop__mobile'
                ref={containerRef}
                tabIndex={0}
                css={css`
                  background: ${colorObject.sideNavColor};
                  padding-top: ${theme.spacing(1)};
                  height: 100%;
                  margin: 0;
                  width: 100%;
                  overflow: auto;
                `}
              >
                {items.map(({ icon: Icon, label, root, subNavItems, show, hasCount }) => {
                  // this check can't be (!show) because show could be undefined, which means we should show the item
                  if (show === false) return null;
                  return (
                    <li key={label}>
                      <NavLink
                        aria-label={label}
                        as='button'
                        data-path={root}
                        onClick={() => {
                          setCurrentParent(root as TLinks);
                          if (!isSubMenu) {
                            setOpen(false);
                          }
                          setTimeout(() => {
                            onClick?.(`${root}${subNavItems[0].relativePath}`);
                          }, 350);
                        }}
                        css={[
                          css`
                            align-items: center;
                            color: ${colorObject.iconColor};
                            display: flex;
                            font-size: var(--nav-item-font-size);
                            height: 3.5em;
                            justify-content: center;
                            position: relative;
                            width: 100%;
                          `,
                          hasSameRootPath(selectedParent, root) &&
                            css`
                              background-color: ${colorObject.hover};
                            `,
                          !hasSameRootPath(selectedParent, root) &&
                            css`
                              :hover {
                                background: ${colorObject.hover};
                                color: ${colorObject.activeIconColor};
                              }
                            `,
                          notificationBubbleStyle({ show: hasCount || false }),
                        ]}
                      >
                        <div css={{ display: 'flex', flexDirection: 'column' }}>
                          <Icon
                            css={css`
                              font-size: inherit;
                              min-width: var(--nav-icon-width);
                              transition: background 0.2s ease-out;
                              width: var(--nav-icon-width);
                              color: ${hasSameRootPath(selectedParent, root) ? colorObject.activeIconColor : 'inherit'};
                            `}
                          />
                          <Text
                            as='span'
                            size='small'
                            css={{
                              color: hasSameRootPath(selectedParent, root) ? colorObject.activeIconColor : 'inherit',
                            }}
                            weight={hasSameRootPath(selectedParent, root) ? 'bold' : 'regular'}
                          >
                            {label}
                          </Text>
                        </div>
                        <Text
                          as='span'
                          css={css`
                            align-items: center;
                            color: var(--colors-neutral-70);
                            display: flex;
                            height: 100%;
                            opacity: ${isSubMenu ? 0 : 1};
                            transition: opacity 0.3s ease-in-out;
                            width: 100%;
                            :hover {
                              cursor: pointer;
                            }
                          `}
                        >
                          {label}
                        </Text>
                      </NavLink>
                    </li>
                  );
                })}
              </NakedUl>
              <footer css={{ background: 'var(--nav-background-color)' }}>
                <PendoTrigger />
              </footer>
            </div>
            <section
              tabIndex={0}
              className='wv-nav-subnav-flyout__desktop'
              data-path={currentSubNavMenu.parentLink}
              css={[
                css`
                  background-color: var(--colors-white);
                  display: flex;
                  flex-direction: column;
                  font-size: var(--nav-item-font-size);
                  height: 100%;
                  left: calc(var(--nav-icon-width) - 50px);
                  opacity: 0;
                  position: absolute;
                  transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
                  visibility: hidden;
                  width: 71%;
                  overflow: auto;
                `,
                isSubMenu &&
                  css`
                    left: var(--nav-icon-width);
                    transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
                    opacity: 1;
                    visibility: visible;
                  `,
              ]}
            >
              {subMenuContent}
            </section>
          </nav>
          {open && <ModalBackdrop style={backdropProps} omitOffset zIndex={1} />}
        </>
      )}
      {navSize === 'desktop' && (
        <nav
          className='desktop-nav'
          onMouseLeave={() => {
            setCurrentParent(('/' + currentPath.split('/')[1]) as TLinks);
          }}
          css={[
            cssVariables,
            css`
              display: flex;
              height: 100%;
              position: relative;
              transition: left 300ms ease-in-out;
              width: var(--nav-width);

              @media (max-width: ${breakpoints.medium.min - 1}px) {
                width: var(--nav-icon-width);
                .wv-nav-subnav-flyout__desktop {
                  // TODO: ask design about this, add layover background instead?
                  box-shadow: 11px 1px 14px -6px rgba(32, 35, 40, 0.2);
                }
              }

              p,
              ul {
                margin: 0;
              }

              /* highlights the main menu items */
              :hover .wv-nav-main__desktop button[data-path='${currentSubNavMenu.parentLink}'] {
                background-color: ${colorObject.hover};
              }
            `,

            // effects for flyout
            css`
              .wv-nav-subnav-flyout__desktop {
                transition: width 0.3s ease-in-out;
              }

              // shows the flyout when main menu item is hovered
              .wv-nav-main__desktop:hover + .wv-nav-subnav-flyout__desktop {
                ${openStyle}
              }

              // keeps the flyout open when the flyout itself is hovered
              .wv-nav-subnav-flyout__desktop:hover {
                ${openStyle}
              }

              // dock the flyout in place for larger screens
              @media (min-width: ${breakpoints.medium.min}px) {
                .wv-nav-main__desktop + .wv-nav-subnav-flyout__desktop {
                  ${openStyle}
                }
              }
            `,
          ]}
        >
          <div css={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            <MotionNakedUl
              className='wv-nav-main__desktop'
              tabIndex={0}
              initial={{ background: colorObject.sideNavColor }}
              animate={{ background: colorObject.sideNavColor }}
              transition={{ duration: 0.3 }}
              id='nav'
              css={css`
                padding-top: ${theme.spacing(1)};
                border-radius: 0 0.5em 0 0;
                height: 100%;
                overflow: auto;
              `}
            >
              {items.map(({ icon: Icon, label, subNavItems, root, show, hasCount }) => {
                // this check can't be (!show) because show could be undefined, which means we should show the item
                if (show === false) return null;
                const submenuIsActive = hasSameRootPath(root, currentSubNavMenu.parentLink);

                return (
                  <li key={label}>
                    <NavLink
                      aria-label={label}
                      as='button'
                      data-path={root}
                      onMouseEnter={handleMouseEnter}
                      useSmartHover
                      registerActiveLink={onChangeSubMenu}
                      isMouseInTriangle={isMouseInTriangle}
                      isActive={submenuIsActive}
                      onClick={() => {
                        if (subNavItems) {
                          // navigate to the first subnav item
                          onClick?.(`${root}${subNavItems[0].relativePath}`);
                          return;
                        }
                        onClick?.(root);
                      }}
                      css={[
                        css`
                          align-items: center;
                          color: ${colorObject.iconColor};
                          display: flex;
                          flex-direction: column;
                          font-size: var(--nav-item-font-size);
                          height: 3.5em;
                          justify-content: center;
                          position: relative;
                          transition: width 0.2s ease-in-out, background 0.2s ease-in-out;
                          width: var(--nav-icon-width);
                        `,
                        notificationBubbleStyle({
                          show: hasCount || false,
                          borderColor:
                            hasSameRootPath(selectedParent, root) || hasSameRootPath(currentParent || '', root)
                              ? 'var(--colors-white)'
                              : 'var(--nav-background-color)',
                        }),
                        hasSameRootPath(selectedParent, root) &&
                          css`
                            background-color: ${colorObject.hover};
                          `,
                        !hasSameRootPath(selectedParent, root) &&
                          css`
                            :hover {
                              background: ${colorObject.hover};
                              color: ${colorObject.activeIconColor};
                            }
                          `,
                      ]}
                    >
                      <Icon
                        css={{
                          fontSize: 'inherit',
                          color: hasSameRootPath(selectedParent, root) ? colorObject.activeIconColor : 'inherit',
                          transition: 'color 0.3s ease',
                        }}
                      />
                      <Text
                        as='span'
                        size='small'
                        weight={hasSameRootPath(selectedParent, root) ? 'bold' : 'regular'}
                        css={{
                          color: hasSameRootPath(selectedParent, root) ? colorObject.activeIconColor : 'inherit',
                        }}
                      >
                        {label}
                      </Text>
                    </NavLink>
                  </li>
                );
              })}
            </MotionNakedUl>
            <footer>
              <PendoTrigger />
            </footer>
          </div>
          <section
            ref={subMenuRef}
            tabIndex={0}
            data-path={currentSubNavMenu.parentLink}
            onMouseEnter={handleMouseEnter}
            className='wv-nav-subnav-flyout__desktop'
            css={[
              css`
                background-color: var(--colors-white);
                display: flex;
                flex-direction: column;
                font-size: var(--nav-item-font-size);
                gap: 0.5em;
                height: 100%;
                left: var(--nav-icon-width);
                overflow: hidden;
                position: absolute;
                top: 0;
              `,
              openStyle,
            ]}
          >
            {subMenuContent}
          </section>
        </nav>
      )}
    </>
  );
}

const SettingsNavButton = ({ label, path }: { label: string; path: StaticSettingsRoutes }) => {
  const { navigate } = useSettingsNavigate();
  const { t } = useTranslation();
  return (
    <NakedButton
      onClick={() => navigate({ to: path, originLabel: label })}
      css={css`
        display: flex;
        gap: ${theme.spacing(1)};
        width: 100%;
        padding: ${theme.spacing(1, 2, 1, 1)};
        text-align: left;
        border-radius: ${theme.borderRadius.medium};
        transition: background-color 0.3s ease-in-out;
        color: ${theme.font.colors.default};
        :hover {
          background-color: ${theme.colors.neutral5};
        }
      `}
    >
      <Icon name='settings' />{' '}
      <span css={styles.truncate}>
        {label} {t('Settings')}
      </span>
    </NakedButton>
  );
};

const hasSameRootPath = (a: string, b: string) => {
  if (!a || !b) return;
  const [aRoot, ...aRelative] = a.split('/');
  const [bRoot, ...bRelative] = b.split('/');

  return aRoot === bRoot && aRelative[0] === bRelative[0];
};

const hasSameSubPath = (a: string, b: string) => {
  const aPath = a.split('/');
  const bPath = b.split('/');

  return aPath[2] === bPath[1];
};

const NavWithRef = forwardRef(NavWithoutRef);

export const Nav = NavWithRef;

type Coords = { x: number; y: number };

function isPointInTriangle(mouse: Coords, left: Coords, topRight: Coords, bottomRight: Coords) {
  const { x: px, y: py } = mouse;
  const { x: ax, y: ay } = left;
  const { x: bx, y: by } = topRight;
  const { x: cx, y: cy } = bottomRight;

  // Calculate vectors
  const v0x = cx - ax;
  const v0y = cy - ay;
  const v1x = bx - ax;
  const v1y = by - ay;
  const v2x = px - ax;
  const v2y = py - ay;

  // Compute dot products
  const dot00 = v0x * v0x + v0y * v0y;
  const dot01 = v0x * v1x + v0y * v1y;
  const dot02 = v0x * v2x + v0y * v2y;
  const dot11 = v1x * v1x + v1y * v1y;
  const dot12 = v1x * v2x + v1y * v2y;

  // Compute barycentric coordinates
  const invDenom = 1 / (dot00 * dot11 - dot01 * dot01);
  const u = (dot11 * dot02 - dot01 * dot12) * invDenom;
  const v = (dot00 * dot12 - dot01 * dot02) * invDenom;

  // Check if point is in triangle
  return u >= 0 && v >= 0 && u + v < 1;
}

function isCoordsInTriangle(pos: Coords, leftRect: DOMRect, rightRect: DOMRect) {
  // The the left / center point of the active nav link
  const leftX = leftRect.left + leftRect.width / 2;
  const leftY = leftRect.top + leftRect.height / 2;

  const topRightX = rightRect.left;
  const topRightY = rightRect.top;

  const bottomRightX = rightRect.left;
  const bottomRightY = rightRect.bottom;

  // Check if the pos is inside the triangle formed by (top-left, top-right, bottom-right)
  return isPointInTriangle(
    pos,
    { x: leftX, y: leftY },
    { x: topRightX, y: topRightY },
    { x: bottomRightX, y: bottomRightY }
  );
}
