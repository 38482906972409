import { memo, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { EmojiClickData } from 'emoji-picker-react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Avatar, Text, useAlert, usePopoverMenu } from '@frontend/design-system';
import { useReactions } from '../../../hooks';
import { useTeamChatStore } from '../../../providers';
import { Message } from '../../../types';
import { MessageComposer } from '../../composer';
import { ActionsBarPopover } from './actions-bar-popover';
import { Attachments } from './attachments';
import { Author } from './author';
import { Reactions } from './reactions';

interface MessageItemProps {
  message: Message;

  className?: string;
}

export const MessageItem = memo(({ className, message }: MessageItemProps) => {
  const alert = useAlert();
  const { t } = useTranslation('team-chat');
  const { addReaction } = useReactions();
  const { streamClient } = useTeamChatStore(['streamClient']);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { close, getMenuProps, isOpen, open, refs } = usePopoverMenu({
    placement: 'top-end',
  });

  const handleAddEmoji = useCallback((emoji: EmojiClickData) => {
    addReaction({
      conversationId: message.channelId,
      messageId: message.id,
      reaction: emoji.unified,
    });
    close();
  }, []);

  const handleDeleteMessage = useCallback(
    (message: Message) => {
      streamClient
        ?.deleteMessage(message.id, true)
        .then(() => {
          alert.success(t('Message deleted successfully'));
        })
        .catch(() => {
          alert.error(t('Failed to delete message'));
        })
        .finally(() => {
          close();
        });
    },
    [streamClient]
  );

  const handleEditMessage = () => {
    setIsEditing(true);
    close();
  };

  return (
    <li
      className={`${isOpen ? 'popover-open' : 'popover-close'} ${className}`}
      css={styles.listItem}
      onMouseEnter={isEditing ? undefined : open}
      onMouseLeave={isEditing ? undefined : close}
      ref={refs.setReference}
      style={isEditing ? { backgroundColor: theme.colors.warning5 } : {}}
    >
      <Avatar name={message.senderName} isUser />
      <div style={{ width: '100%' }}>
        {isEditing ? (
          <MessageComposer closeComposer={() => setIsEditing(false)} message={message} />
        ) : (
          <>
            <Author date={message.created} name={message.senderName} />
            {/* Future scope: messages that fall within same HH:mm can be clubbed and shown without rendering the Author component for each one of them */}
            <Text css={styles.text}>
              <span style={{ marginRight: theme.spacing(0.5) }}>{message.text}</span>
              {!!message.isEdited && (
                <Text as='span' color='subdued' size='small'>
                  ({t('edited')})
                </Text>
              )}
            </Text>
            {!!message.attachments?.length && (
              <Attachments attachments={message.attachments} onOpenAttachment={close} />
            )}
            {!!message.reactions?.length && <Reactions message={message} />}
            {/* TODO :: add thread option here */}
          </>
        )}
      </div>
      <ActionsBarPopover getMenuProps={getMenuProps}>
        <ActionsBarPopover.Emoji onSelect={handleAddEmoji} />
        {message.isOwnMessage && (
          <ActionsBarPopover.FlyoutMenu message={message} onDelete={handleDeleteMessage} onEdit={handleEditMessage} />
        )}
      </ActionsBarPopover>
    </li>
  );
});

MessageItem.displayName = 'MessageItem';

const styles = {
  listItem: css`
    display: flex;
    gap: ${theme.spacing(1)};
    padding: ${theme.spacing(1.5, 2)};

    &.popover-open {
      background-color: ${theme.colors.neutral5};
    }
  `,

  text: css`
    white-space: pre-wrap;
    word-break: break-word;
  `,
};
