import { useEffect } from 'react';
import { useSearch } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { ContentLoader, useModalControl } from '@frontend/design-system';
import { useNavAlert, useNavigateWithAlert } from '../../hooks';
import { PaymentsNavAlerts } from '../../utils';
import { PaymentHistoryCard } from '../InvoiceDetails';
import { AttachmentSection } from './AttachmentSection';
import { Details } from './Details';
import { SummaryCard } from './SummaryCard';

export { PaymentRequestActions } from './payment-request-actions';

export const PaymentRequestDetailsPage = ({ selectedInvoiceId }: { selectedInvoiceId: string }) => {
  const { t } = useTranslation('payments');
  const { invoice, isLoading, isFetched } = useSelectedInvoice(selectedInvoiceId);
  const { collectPayment } = useSearch<{ Search: { collectPayment: boolean } }>();
  const collectModalControl = useModalControl();

  useNavigateWithAlert({
    enable: isFetched && !isLoading && !invoice,
    key: PaymentsNavAlerts.InvalidInvoice,
    to: '/payments/invoices',
    type: 'error',
  });

  const openCollectPaymentModal = () => collectModalControl.openModal();
  useNavAlert(
    PaymentsNavAlerts.CreatePaymentRequest,
    t('Payment Request Created Successfully.'),
    openCollectPaymentModal
  );

  useEffect(() => {
    if (collectPayment) {
      collectModalControl.openModal();
    }
  }, []);

  return (
    <>
      <ContentLoader show={isLoading} />
      {!isLoading && !!invoice && (
        <>
          <SummaryCard invoice={invoice} />
          <Details invoice={invoice} />
          <AttachmentSection />
          <PaymentHistoryCard invoice={invoice} variant='payments-app' />
        </>
      )}
    </>
  );
};
