import React, {
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
  forwardRef,
  MouseEventHandler,
  RefObject,
} from 'react';
import { css } from '@emotion/react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import {
  GetUserStatusForTeamChatResponse,
  UserStatusType,
} from '@weave/schema-gen-ts/dist/schemas/team-chat/tc_messages.pb';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { AccountManagerQueries } from '@frontend/api';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { useAccessibilityModeEnabled } from '@frontend/accessibility';
import { AccountManagerPopupController, AccountManagerProfile } from '@frontend/account-manager-profile';
import { ActionBar } from '@frontend/action-bar';
import { PortalUser, getUser, clearLastVisitedPage } from '@frontend/auth-helpers';
import { DeviceSection } from '@frontend/calls-v2';
import { ChatTrayContents, trackingId as chatTrackingId, useChatStatusShallowStore, useStrategy } from '@frontend/chat';
import { Chips } from '@frontend/chips';
import { useDialpadManagerClient } from '@frontend/dialpad';
import { useFeedback } from '@frontend/feedback';
import { http } from '@frontend/fetch';
import { useTranslation, i18next } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { BulkNotificationHistoryActions, NotificationTray, useNotificationContext } from '@frontend/notifications';
import { useHasPhoneSystemAccess } from '@frontend/phone-config';
import { PopupBarManagerProps } from '@frontend/popup-bar';
import { NavSize, useNavSize, useWindowWidth } from '@frontend/responsiveness';
import {
  QuickFillContent,
  ScheduleActionsModalContent,
  quickfillTrackingIds,
  useCalendarEventsPanelStore,
  useGetScheduleRequestAndAlertsCount,
} from '@frontend/schedule';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useFeatureFlagShallowStore, useMultiLocationFeature } from '@frontend/shared';
import { useShell, IPCRendererCallback } from '@frontend/shell-utils';
import { useTaskTrayShallowStore } from '@frontend/task-tray';
import { ChatTray, OPEN_TEAM_CHAT_EVENT, useTeamChatStore } from '@frontend/team-chat';
import { TourGuideHelpers } from '@frontend/tour-guide';
import { theme } from '@frontend/theme';
import {
  Avatar,
  Chip,
  ContentLoader,
  getAllFocusableElements,
  HamburgerMenuIcon,
  Heading,
  IconButton,
  NakedButton,
  NakedUl,
  Tray,
  Text,
  TextLink,
  useModalControl,
  useTooltip,
  WeaveLogoIcon,
  XIcon,
  styles,
  PrimaryButton,
  Dot,
  NotificationBadge,
  useEventListener,
} from '@frontend/design-system';
import { ICON_BUTTON_WIDTH, ICON_SIZE, TOP_BAR_HEIGHT } from '../constants';
import { signOut } from '../helpers/sign-out';
import { useNavTrigger } from '../layout';
import { WeaveActionBar } from './action-bar';
import { DialpadWrap } from './dialpad-wrap';
import { GlobalSearchView } from './global-search';
import { DecoratedLocationPicker } from './location-picker';
import { SoftphoneWrap } from './softphone-wrap';
import { AnimatedLogo } from './top-bar-logo';
import { useTeamChatMauReduction } from './use-team-chat-mau-reduction';
import WeaveLogoDark from './Weave-Logo-Dark.svg';
import WeaveLogo from './Weave-Logo-white.svg';

const iconButtonStyles = ({ isSelected, isWeaveApp }: { isSelected: boolean; isWeaveApp: boolean }) => [
  css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: ${theme.spacing(1)};
    transition: background 250ms ease-in-out;
    min-width: ${ICON_BUTTON_WIDTH}px;
    :hover {
      background: ${isWeaveApp ? theme.colors.neutral5 : theme.colors.neutral80};
    }
    svg {
      fill: ${isWeaveApp ? theme.colors.neutral90 : theme.colors.white};
      color: ${isWeaveApp ? theme.colors.neutral90 : theme.colors.white};
    }
  `,
  isSelected &&
    css`
      background: ${isWeaveApp ? theme.colors.neutral10 : theme.colors.white};
      :hover {
        background: ${isWeaveApp ? theme.colors.neutral5 : theme.colors.white};
      }
      svg {
        fill: ${theme.colors.neutral90};
        color: ${theme.colors.neutral90};
      }
    `,
];

type SelectableIcons =
  | 'location'
  | 'notifications'
  | 'profile'
  | 'chat'
  | 'taskTray'
  | 'multiLocations'
  | 'quickfill'
  | 'schedulePulse'
  | 'chatV2';

const labelMap: Record<SelectableIcons, string> = {
  location: i18next.t('Location', { ns: 'base' }),
  multiLocations: i18next.t('Filter Locations', { ns: 'base' }),
  notifications: i18next.t('Notifications', { ns: 'base' }),
  profile: i18next.t('Profile', { ns: 'base' }),
  chat: i18next.t('Team Chat', { ns: 'base' }),
  taskTray: i18next.t('Task Tray', { ns: 'base' }),
  quickfill: i18next.t('Quick Fill', { ns: 'base' }),
  schedulePulse: i18next.t('Schedule Pulse', { ns: 'base' }),
  chatV2: i18next.t('Team Chat', { ns: 'base' }),
} as const;

const useDetectChatStatus = ({ show, icon }: { show: boolean; icon: string }) => {
  const { setTrayStatus } = useChatStatusShallowStore('setTrayStatus');
  const trayStatus = useRef<boolean>(show);

  useEffect(() => {
    /**
     * The icon is reset to undefined after the modal closes.
     * We don't want that to re-trigger the chat status detection.
     *
     * Added a ref to compare the last vales of chat modal show attribute because
     * In case when chat tray is being closed via chat icon click, icon comes an empty string
     * leading to a case where the tray status would never be set to closed
     */
    if (icon === 'chat' || trayStatus.current !== show) {
      trayStatus.current = show;
      const chatTrayOpen = show;
      setTrayStatus(!chatTrayOpen ? 'closed' : 'open');
    }
  }, [show, icon]);
};

//TODO: Remove this functionality after September 3rd, 2024

const nwxBetaEndDate = '9/3/2024';

function hasDateBeenReached() {
  const now = dayjs();
  const target = dayjs(nwxBetaEndDate);

  return !now.isBefore(target);
}

export const TopBar = ({
  PopupBarManager,
  ChatProviders,
}: {
  PopupBarManager: (props: PopupBarManagerProps) => React.ReactElement;
  ChatProviders: (props: { children: React.ReactNode }) => React.ReactElement;
}) => {
  // react hooks
  const shell = useShell();
  const [selectedIcon, setSelectedIcon] = useState<SelectableIcons | ''>('');
  const ulRef = useRef<HTMLUListElement>(null);
  const locationsTriggerRef = useRef<HTMLButtonElement>(null);
  const profileTriggerRef = useRef<HTMLButtonElement>(null);
  const notificationTriggerRef = useRef<HTMLButtonElement>(null);
  const chatTriggerRef = useRef<HTMLButtonElement>(null);
  const quickfillTriggerRef = useRef<HTMLButtonElement>(null);
  const schedulePulseTriggerRef = useRef<HTMLButtonElement>(null);
  const taskTrayTriggerRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // custom hooks
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();
  const { t } = useTranslation();
  const user = getUser();
  const { setOpen, hamburgerButtonRef, open } = useNavTrigger();
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const { modalProps, openModal, closeModal } = useModalControl({
    disableReturnFocus: true,
  });
  const { isEnabled: accessibilityModeEnabled } = useAccessibilityModeEnabled('isEnabled');
  const { isShowIntakeForm } = useIntakeFormShallowStore('isShowIntakeForm');
  const { isMultiLocationFeature } = useMultiLocationFeature();
  const { isTaskTrayOpen, toggleTaskTrayOpen } = useTaskTrayShallowStore('isTaskTrayOpen', 'toggleTaskTrayOpen');
  const { getFlag } = useFeatureFlagShallowStore('getFlag', 'flags');
  const {
    selectedLocationIdsWithParents,
    selectedLocationIds,
    accessibleLocationIds,
    getLocationName,
    accessibleLocationData,
  } = useAppScopeStore();
  const { unreadCount, setNotificationTrayIsOpen } = useNotificationContext();

  const isChatEnabled = getFlag('new-chat-experience');
  const hasOnboardingTaskFeatureFlag = getFlag('portal-onboarding-tasks');
  const isTeamChatV2Enabled = getFlag('team-chat-2.0');

  const singleLocationReferenceName = getLocationName(selectedLocationIdsWithParents[0]);

  // other hooks

  const windowWidth = useWindowWidth({ tolerance: 5 });
  const navigate = useNavigate();
  const { current } = useLocation();

  const isWeaveApp = !current.pathname.startsWith('/portal');

  const navSize = useNavSize();
  const isHamburgerButtonVisible = navSize === NavSize.Mini || navSize === NavSize.Mobile;
  // Note: temporary hide this as its creating strange UX, Design team working on new UX for this mobile case
  const isTaskTrayIconButtonVisible =
    false && hasOnboardingTaskFeatureFlag && (isWeaveApp || navSize === NavSize.Mini || navSize === NavSize.Mobile);
  let focusableElements: HTMLElement[] = [];
  const isDevEnv = localStorage.getItem('backendEnv') === 'dev';
  const isTeamChatMauReductionEnabled = getFlag('team-chat-mau-reduction');
  const { setActive } = useTeamChatMauReduction();
  const hasPhoneSystemAccess = useHasPhoneSystemAccess();

  useLocalizedQuery({
    queryKey: ['team-chat-active'],
    queryFn: ({ queryKey }) =>
      http.get<GetUserStatusForTeamChatResponse>(`team-chat/v1/user-status?location_id=${queryKey[0]}`),
    onSuccess: (data) => {
      // if the reduction ff is on, but the user is active,
      // go ahead and init the twilio client in the background.
      if (data.userStatus === UserStatusType.ACTIVE) {
        setActive(true);
      }
    },
    // only check the user status if the reduction ff is turned on.
    // if the ff is off, we don't care about user status,
    // bc we want to init twilio no matter what
    enabled: isTeamChatMauReductionEnabled,
  });

  // opens team chat side tray from notification
  useEventListener(
    OPEN_TEAM_CHAT_EVENT as any,
    (e) => {
      if (selectedIcon !== 'chatV2') {
        handleIconClick('chatV2', e);
      }
    },
    isTeamChatV2Enabled
  );

  const handleIconClick = (tab: SelectableIcons, e: MouseEvent) => {
    // set user as active at this point
    if (tab === 'chat' || tab === 'chatV2') {
      setActive(true);
    }

    e.stopPropagation();
    if (tab === 'taskTray') return;

    if (tab === selectedIcon) {
      closeModal();
      setSelectedIcon('');
    } else {
      setSelectedIcon(tab);
      openModal();
    }
  };

  const handleTaskTrayIconClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (modalProps.show) {
      closeModal();
    }

    toggleTaskTrayOpen('taskTray' !== selectedIcon);
    setSelectedIcon('taskTray');
  };
  useEffect(() => {
    if (shell.isShell) return;
    // if the notification tray is open and the app is running in the web,
    // disable ephemeral notifications
    const notificationTrayIsOpen = selectedIcon === 'notifications';
    setNotificationTrayIsOpen(notificationTrayIsOpen);
  }, [selectedIcon === 'notifications']);

  useEffect(() => {
    /**
     * This allows the currently opened modal to sync between top bar and calendar events panel
     */
    const unsubscribe = useCalendarEventsPanelStore.subscribe(
      (state) => state.activeAction,
      (activeAction) => {
        if (activeAction && activeAction !== 'chat') {
          closeModal();
        }
      }
    );

    return () => unsubscribe();
  }, []);

  // close location selection modal if its already open and locationData get changes.
  useEffect(() => {
    if (modalProps.show) {
      closeModal();
      setSelectedIcon('');
    }
  }, [selectedLocationIdsWithParents]);

  useEffect(() => {
    if (isTaskTrayIconButtonVisible && isTaskTrayOpen && !selectedIcon) {
      // if task tray opened & task tray icon button visible but its not selected then set as selected
      // this will happen for case when screen size get changed from desktop to mobile
      setSelectedIcon('taskTray');
    } else if (!isTaskTrayIconButtonVisible && selectedIcon === 'taskTray') {
      // if task tray icon button got hidden then unselect task tray icon button if its already selected
      // this will happen for case when screen size get changed from mobile to desktop
      setSelectedIcon('');
    } else if (isTaskTrayIconButtonVisible && !isTaskTrayOpen && selectedIcon === 'taskTray') {
      // if task tray panel get closed & task tray button visible then unselect task tray icon and focus on task tray button
      // this will happen for case when user click on task tray panel close button
      taskTrayTriggerRef?.current?.focus();
      setSelectedIcon('');
    }
  }, [isTaskTrayIconButtonVisible, isTaskTrayOpen]);

  useEffect(() => {
    // don't use useState for focusableElements because it's async, and we need to get
    // the first focusable element and focus it all in the same lifecycle
    if (containerRef.current) {
      focusableElements = getAllFocusableElements(containerRef.current);
    }
    if (focusableElements.length) {
      focusableElements?.[0].focus();
    }
  }, [containerRef.current, modalProps.show, selectedIcon]);

  useEffect(() => {
    // send focus back to trigger element when modal is closed
    if (!modalProps.show) {
      // this resets the selectedIcon if the user clicks outside of the tray to close it
      if (selectedIcon === 'taskTray') return;

      if (selectedIcon === 'location') locationsTriggerRef.current?.focus();
      if (selectedIcon === 'profile') profileTriggerRef.current?.focus();
      if (selectedIcon === 'notifications') notificationTriggerRef.current?.focus();
      if (selectedIcon === 'chat') chatTriggerRef.current?.focus();
      if (selectedIcon === 'quickfill') quickfillTriggerRef.current?.focus();
      if (selectedIcon === 'schedulePulse') schedulePulseTriggerRef.current?.focus();
      setSelectedIcon('');
    }
  }, [modalProps.show]);
  useDetectChatStatus({ show: modalProps.show, icon: selectedIcon });

  const [nextVersion, setNextVersion] = useState<string>();

  //TODO: this is a legacy way of subscribing to an update-available message. Now we're going to ask ipc ourselves and listen
  //After the next release of 2.0.15 we can remove this whole useEffect
  useEffect(() => {
    if (!shell?.isShell) {
      return;
    }
    const id = 'update:new-version-available' + Math.random();
    const cb: IPCRendererCallback<'update:new-version-available'> = (_e, payload) => {
      const version = payload?.version;
      setNextVersion(version);
    };
    shell.on?.('update:new-version-available', cb, id);
    return () => {
      shell.off?.('update:new-version-available', cb, id);
    };
  }, [shell.isShell]);

  //This is the new way to request the version. This will make sure we don't miss the event if it's already happened
  //right now we're doing it on load, but we could put this on a timer to check every x minutes/hours
  useEffect(() => {
    if (!shell.isShell) {
      return;
    }
    const cb: IPCRendererCallback<'info:update-available'> = (_e, payload) => {
      const version = payload?.version;
      if (version) {
        setNextVersion(version);
      }
    };
    const id = 'info:update-available' + Math.random();
    shell.emit?.('info:update-available', undefined);
    shell.on?.('info:update-available', cb, id);
    return () => {
      shell.off?.('info:update-available', cb, id);
    };
  }, [shell.isShell]);

  const { scheduleAlertsCount, scheduleRequestCount } = useGetScheduleRequestAndAlertsCount();

  const handleOpenProfilePanel = () => {
    setSelectedIcon('profile');
    openModal();
  };

  const newNotificationCountExact = unreadCount ? unreadCount : 0;
  const isGreaterThan99 = newNotificationCountExact > 99;

  //TODO: this isn't a standard breakpoint, but is the only size that makes sense
  const allowSoftphoneTrayViewInTopBar = windowWidth > 850;
  const isSoftphoneDeviceConnected = useDialpadManagerClient((ctx) => ctx?.isSoftphoneDeviceConnected);
  const showGlobalNavForDialpad = useDialpadManagerClient((ctx) => ctx.canShowDialpad);
  const shouldDisallowPicker = accessibleLocationIds.length === 1 && user?.type !== 'weave';
  const [show, setShow] = useState(false);
  const toggle = () => setShow((show) => !show);

  const verticalAnimationRef = useRef({ hasShown: false });

  useEffect(() => {
    // reset hasShown if the user changes location
    verticalAnimationRef.current.hasShown = false;
  }, [singleLocationReferenceName]);

  const hasShellThemeEnabled = shell?.isShell && shell?.featureAvailability?.has('shell-theme');

  return (
    <>
      <header
        css={css`
          align-items: center;
          background: ${isWeaveApp ? theme.colors.white : theme.colors.neutral90};
          display: flex;
          justify-content: space-between;
          max-height: ${TOP_BAR_HEIGHT}px;
          min-height: ${TOP_BAR_HEIGHT}px;
        `}
        onClick={() => {
          if (modalProps.show) {
            closeModal();
          }
        }}
      >
        <section
          css={css`
            align-items: center;
            color: ${theme.colors.white};
            display: flex;
            height: 100%;
            padding: ${theme.spacing(0, 1, 0, 0)};
          `}
        >
          {isHamburgerButtonVisible && (
            <NakedButton
              onClick={() => setOpen((prev) => !prev)}
              ref={hamburgerButtonRef}
              css={[
                css`
                  align-items: center;
                  color: ${theme.colors.white};
                  display: flex;
                  font-size: ${theme.fontSize(24)};
                  font-weight: bold;
                  height: 100%;
                  justify-content: center;
                  padding: ${theme.spacing(1)};
                  transition: background 250ms ease-in-out;
                  width: ${ICON_BUTTON_WIDTH}px;
                  :hover {
                    background: ${isWeaveApp ? theme.colors.neutral5 : theme.colors.neutral80};
                  }
                `,
                open &&
                  css`
                    :hover {
                      background: ${theme.colors.neutral10};
                      svg {
                        fill: ${theme.colors.neutral90};
                        transition: fill 250ms ease-in-out;
                      }
                    }
                  `,
              ]}
            >
              {open ? (
                <XIcon size={ICON_SIZE} color={isWeaveApp ? 'default' : 'white'} />
              ) : (
                <HamburgerMenuIcon color={isWeaveApp ? 'default' : 'white'} size={ICON_SIZE} />
              )}
            </NakedButton>
          )}
          <NakedButton
            css={css`
              align-items: center;
              color: ${theme.colors.white};
              display: flex;
              font-size: ${theme.fontSize(24)};
              padding: ${theme.spacing(0, 1)};
            `}
            onClick={() => navigate({ to: isWeaveApp ? '/home/dashboard' : '/portal' })}
          >
            {navSize === NavSize.Desktop ? (
              <>
                {isWeaveApp ? (
                  <>
                    {verticalAnimationRef.current.hasShown ? (
                      <img
                        src={WeaveLogoDark}
                        alt='weave-app-logo'
                        css={{ height: ICON_SIZE }}
                        data-tour-guide='portal-logo'
                      />
                    ) : (
                      <AnimatedLogo
                        vertical={accessibleLocationData?.[selectedLocationIds?.[0]]?.vertical}
                        onComplete={() => {
                          verticalAnimationRef.current.hasShown = true;
                        }}
                      />
                    )}
                  </>
                ) : (
                  <img src={WeaveLogo} alt='portal logo' css={{ height: ICON_SIZE }} data-tour-guide='portal-logo' />
                )}
              </>
            ) : (
              <>
                <WeaveLogoIcon
                  color={isWeaveApp ? 'default' : 'white'}
                  css={{ marginRight: theme.spacing(1) }}
                  size={ICON_SIZE}
                  data-tour-guide='portal-logo'
                />
              </>
            )}
          </NakedButton>
          {hasPhoneSystemAccess && !isSoftphoneDeviceConnected && showGlobalNavForDialpad && <DialpadWrap />}
          {hasPhoneSystemAccess && (
            <SoftphoneWrap
              allowTrayView={allowSoftphoneTrayViewInTopBar}
              allowButtonView={true}
              allowPopout={allowSoftphoneTrayViewInTopBar}
            />
          )}
          {isWeaveApp && !hasDateBeenReached() && (
            <Chip css={{ marginLeft: theme.spacing(2) }} variant='seaweed'>
              Beta
            </Chip>
          )}
        </section>
        {isWeaveApp && !hasShellThemeEnabled && <GlobalSearchView />}
        <section css={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <NakedUl
            css={{
              display: 'flex',
              paddingRight: theme.spacing(1),
              height: '100%',
            }}
            ref={ulRef}
          >
            {isDevEnv && (
              <li>
                <Text
                  css={{
                    alignItems: 'center',
                    background: theme.colors.secondary.eggplant50,
                    color: 'white',
                    display: 'flex',
                    fontWeight: theme.font.weight.bold,
                    height: '100%',
                    padding: theme.spacing(2),
                  }}
                  {...triggerProps}
                >
                  DEV
                </Text>
                <Tooltip {...tooltipProps}>
                  <Text color='white'>Using DEV endpoints.</Text>
                  <Text color='white'>
                    Use{' '}
                    <Text as='code' style={{ color: theme.colors.primary20 }}>
                      ctrl + shift + e
                    </Text>{' '}
                    to open the endpoint switcher.
                  </Text>
                </Tooltip>
              </li>
            )}
            <li>
              <NakedButton
                css={[
                  iconButtonStyles({
                    isSelected: 'location' === selectedIcon,
                    isWeaveApp,
                  }),
                  css`
                    padding: ${theme.spacing(1, 2)};
                  `,
                  shouldDisallowPicker &&
                    css`
                      :hover {
                        background: inherit;
                      }
                      pointer-events: none;
                    `,
                ]}
                onClick={(e) => {
                  if (!shouldDisallowPicker) {
                    handleIconClick('location', e);
                  }
                }}
                ref={locationsTriggerRef}
                data-test-id='nav-location-picker-button'
              >
                {navSize !== NavSize.Mobile && (
                  <>
                    {isMultiLocationFeature ? (
                      <>
                        <Text
                          css={[
                            {
                              paddingLeft: theme.spacing(1),
                              paddingRight: theme.spacing(1),
                              color:
                                'location' === selectedIcon || isWeaveApp
                                  ? theme.colors.neutral90
                                  : theme.colors.neutral20,
                              transition: 'color 250ms ease-in-out',
                              maxWidth: 175,
                            },
                            styles.truncate,
                          ]}
                        >
                          {selectedLocationIds.length > 1
                            ? `${selectedLocationIds.length} Locations`
                            : singleLocationReferenceName}
                        </Text>
                      </>
                    ) : (
                      <Text
                        css={[
                          {
                            paddingRight: theme.spacing(1),
                            color:
                              'location' === selectedIcon || isWeaveApp
                                ? theme.colors.neutral90
                                : theme.colors.neutral20,
                            transition: 'color 250ms ease-in-out',
                            maxWidth: 175,
                          },
                          styles.truncate,
                        ]}
                      >
                        {singleLocationReferenceName || t('No location selected')}
                      </Text>
                    )}
                  </>
                )}
                <Icon name='location' />
              </NakedButton>
            </li>

            <li css={{ position: 'relative' }} {...(isGreaterThan99 && { onMouseEnter: toggle, onMouseLeave: toggle })}>
              <NotificationButton
                count={newNotificationCountExact}
                showLimit={isGreaterThan99}
                isTrayOpen={show}
                onClick={(e) => handleIconClick('notifications', e)}
                notificationTriggerRef={notificationTriggerRef}
                css={iconButtonStyles({
                  isSelected: 'notifications' === selectedIcon,
                  isWeaveApp,
                })}
              />
            </li>
            {!isShowIntakeForm && (
              <li>
                <NakedButton
                  css={iconButtonStyles({
                    isSelected: false,
                    isWeaveApp,
                  })}
                  onClick={() => settingsNavigate({ to: '/organization' })}
                  trackingId='top-bar-button-settings-2.0'
                >
                  <Icon name='settings' />
                </NakedButton>
              </li>
            )}
            <li>
              <NakedButton
                css={[
                  iconButtonStyles({ isSelected: 'profile' === selectedIcon, isWeaveApp }),
                  css`
                    position: relative;
                  `,
                ]}
                onClick={(e) => {
                  handleIconClick('profile', e);
                }}
                ref={profileTriggerRef}
                trackingId='top-bar-profile-tray-trigger'
              >
                <Avatar isUser name={user?.firstName + ' ' + user?.lastName} size='xs' disableClick>
                  {(nextVersion || accessibilityModeEnabled) && (
                    <Dot
                      color='critical'
                      css={{
                        width: theme.spacing(1.5),
                        height: theme.spacing(1.5),
                        top: theme.spacing(-0.75),
                        right: theme.spacing(-0.5),
                        border: `1.5px solid ${theme.colors.white}`,
                      }}
                    />
                  )}
                </Avatar>
              </NakedButton>
            </li>
            {isTaskTrayIconButtonVisible && (
              <li>
                <NakedButton
                  id='top-nav-task-tray-button'
                  css={iconButtonStyles({ isSelected: selectedIcon === 'taskTray', isWeaveApp })}
                  onClick={handleTaskTrayIconClick}
                  ref={taskTrayTriggerRef}
                >
                  <Icon name='list' />
                </NakedButton>
              </li>
            )}
          </NakedUl>
        </section>
      </header>
      {!allowSoftphoneTrayViewInTopBar && hasPhoneSystemAccess && (
        <>
          <SoftphoneWrap allowTrayView={true} allowButtonView={false} allowPopout={true} />
        </>
      )}
      {/* We want the chat provider to be inside the Tray, so a change in the chat provider doesn't trigger a Tray rerender
          However, the chat provider has to also wrap the PopupbarManager, which can't be inside of the side modal,
          because it can't conditionally render with the Tray.
          Meaning it has to be outside of the side modal. That is why the Tray flashes when we get the chat strategy.
        */}
      <ChatProviders>
        {!isShowIntakeForm && (
          <WeaveActionBar>
            {isChatEnabled && (
              <>
                {isTeamChatV2Enabled ? (
                  <ChatV2Button
                    isActive={'chatV2' === selectedIcon}
                    onClick={(e: MouseEvent) => {
                      handleIconClick('chatV2', e);
                    }}
                    ref={chatTriggerRef}
                  />
                ) : (
                  <ChatButton
                    isActive={'chat' === selectedIcon}
                    onClick={(e: MouseEvent) => {
                      handleIconClick('chat', e);
                    }}
                    ref={chatTriggerRef}
                  />
                )}
              </>
            )}
            <QuickfillButton
              isActive={'quickfill' === selectedIcon}
              onClick={(e: MouseEvent) => handleIconClick('quickfill', e)}
              ref={quickfillTriggerRef}
            />
            <SchedulePulseButton
              isActive={'schedulePulse' === selectedIcon}
              onClick={(e: MouseEvent) => handleIconClick('schedulePulse', e)}
              hasDot={!!scheduleAlertsCount || !!scheduleRequestCount?.total}
              ref={schedulePulseTriggerRef}
            />
          </WeaveActionBar>
        )}
        <Tray
          mountTarget='#app-container'
          width={isTeamChatV2Enabled && selectedIcon === 'chatV2' ? 'xlarge' : 'medium'}
          autoFocusTimeout={3600000 * 2} // prevent autofocusing the modal container for 2hrs
          css={{
            padding: 0,
            borderTop: `1px solid ${theme.colors.neutral20}`,
            ...(isTeamChatV2Enabled && selectedIcon === 'chatV2' && { width: 'fit-content' }),
          }}
          {...modalProps}
        >
          {/* chatv2 uses a different header */}
          {selectedIcon !== 'chatV2' && (
            <PaddedContent css={{ paddingBottom: 0 }}>
              <Tray.Header
                Buttons={
                  <>
                    {selectedIcon === 'notifications' && <BulkNotificationHistoryActions />}
                    <IconButton label='close' onClick={() => closeModal()}>
                      <XIcon />
                    </IconButton>
                  </>
                }
              >
                <Heading
                  level={isMultiLocationFeature ? 2 : 1}
                  css={{ marginBottom: 0, display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
                  data-testid='tray-header'
                >
                  {(selectedIcon !== 'location' || !isMultiLocationFeature) && selectedIcon && labelMap[selectedIcon]}
                  {isMultiLocationFeature && selectedIcon === 'location' && labelMap['multiLocations']}
                  {selectedIcon === 'chat' && (
                    <Chips.LocationChip css={{ fontWeight: theme.font.weight.regular }}>
                      {singleLocationReferenceName}
                    </Chips.LocationChip>
                  )}
                </Heading>
              </Tray.Header>
            </PaddedContent>
          )}
          <div
            ref={containerRef}
            css={css`
              display: flex;
              flex-direction: column;
              position: relative;
              overflow: auto;
              flex-grow: 1;
            `}
          >
            {selectedIcon === 'location' && (
              <PaddedContent css={{ height: '100%', paddingTop: 0 }}>
                <DecoratedLocationPicker closeModal={closeModal} />
              </PaddedContent>
            )}
            {selectedIcon === 'profile' && <Profile user={user} closeModal={closeModal} nextVersion={nextVersion} />}
            {selectedIcon === 'notifications' && (
              <NotificationTray closeModal={closeModal} selectLocationTray={(e) => handleIconClick('location', e)} />
            )}
            {selectedIcon === 'chat' && <ChatTrayContents user={user ?? { userID: '' }} modalProps={modalProps} />}
            {selectedIcon === 'chatV2' && <ChatTray modalProps={modalProps} />}
            {selectedIcon === 'quickfill' && <QuickFillContent closeModal={closeModal} />}
            {selectedIcon === 'schedulePulse' && <ScheduleActionsModalContent closeModal={closeModal} />}
          </div>
        </Tray>
        <PopupBarManager />
        <AccountManagerPopupController onShowPopup={handleOpenProfilePanel} />
      </ChatProviders>
    </>
  );
};

const PaddedContent = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div css={{ padding: theme.spacing(3), display: 'flex', flexDirection: 'column' }} className={className}>
      {children}
    </div>
  );
};

const NotificationButton = ({
  count,
  showLimit,
  isTrayOpen,
  onClick,
  notificationTriggerRef,
  className,
}: {
  count: number;
  showLimit: boolean;
  isTrayOpen: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  notificationTriggerRef: RefObject<HTMLButtonElement>;
  className?: string;
}) => {
  return (
    <>
      {count ? (
        <NotificationBadge
          css={css`
            position: absolute;
            right: 8px;
            top: 12px;
            z-index: 0;
          `}
        >
          {showLimit ? <motion.span layout='position'>{isTrayOpen ? count : '99+'}</motion.span> : <>{count}</>}
        </NotificationBadge>
      ) : null}
      <NakedButton
        css={className}
        onClick={onClick}
        data-testid='notification-tray-trigger'
        trackingId='notify-2.0-tray-trigger'
        ref={notificationTriggerRef}
      >
        <Icon name='notification' />
      </NakedButton>
    </>
  );
};

const ChatButton = forwardRef<HTMLButtonElement, { onClick: MouseEventHandler<HTMLButtonElement>; isActive?: boolean }>(
  ({ onClick, isActive, ...rest }, ref) => {
    const { t } = useTranslation('base');
    const { useUnreadCount } = useStrategy('chat');
    const unreadCount = useUnreadCount();

    return (
      <ActionBar.Action
        label={t('Team Chat')}
        id='team-chat'
        icon='chat'
        isActive={isActive}
        onClick={onClick}
        trackingId={chatTrackingId({ component: 'tray', context: 'trigger' })}
        ref={ref}
        hasCount
        hasDot={unreadCount > 0}
        {...rest}
      />
    );
  }
);
ChatButton.displayName = 'ChatButton';

const ChatV2Button = forwardRef<
  HTMLButtonElement,
  { onClick: MouseEventHandler<HTMLButtonElement>; isActive?: boolean }
>(({ onClick, isActive, ...rest }, ref) => {
  const { t } = useTranslation('base');
  const { totalUnreadCount } = useTeamChatStore(['totalUnreadCount']);

  return (
    <ActionBar.Action
      label={t('Team Chat')}
      id='team-chat-v2'
      icon='chat'
      isActive={isActive}
      onClick={onClick}
      trackingId={'chat-v2-tray-trigger'} // TODO: update tracking id later
      ref={ref}
      hasCount
      hasDot={totalUnreadCount > 0}
      {...rest}
    />
  );
});
ChatV2Button.displayName = 'ChatV2Button';

const QuickfillButton = forwardRef<
  HTMLButtonElement,
  { onClick: MouseEventHandler<HTMLButtonElement>; isActive?: boolean }
>(({ onClick, isActive, ...rest }, ref) => {
  const { t } = useTranslation('base');

  return (
    <ActionBar.Action
      label={t('Quick Fill')}
      id='quickfill'
      icon='quickfill'
      isActive={isActive}
      onClick={onClick}
      trackingId={quickfillTrackingIds.quickfillEventPanelButton}
      ref={ref}
      {...rest}
    />
  );
});
QuickfillButton.displayName = 'QuickfillButton';

const SchedulePulseButton = forwardRef<
  HTMLButtonElement,
  { onClick: MouseEventHandler<HTMLButtonElement>; isActive?: boolean; hasDot?: boolean }
>(({ onClick, isActive, hasDot, ...rest }, ref) => {
  const { t } = useTranslation('base');

  return (
    <ActionBar.Action
      label={t('Schedule Pulse')}
      id='schedule-action'
      icon='schedule-pulse'
      isActive={isActive}
      onClick={onClick}
      trackingId={quickfillTrackingIds.quickfillEventPanelButton}
      ref={ref}
      hasDot={hasDot}
      {...rest}
    />
  );
});
SchedulePulseButton.displayName = 'SchedulePulseButton';

type ProfileSectionProps = {
  user: PortalUser | undefined;
  nextVersion: string | undefined;
  closeModal: () => void;
};
const Profile = ({ user, closeModal, nextVersion }: ProfileSectionProps) => {
  const { data: accountManagerData } = AccountManagerQueries.useAccountManagerDataQuery();
  const hasPhoneSystemAccess = useHasPhoneSystemAccess();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'min-content 1fr min-content' }}>
        {/* Top Section */}
        <Section css={{ display: 'flex', gap: theme.spacing(2), flexDirection: 'column' }}>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div
              css={{
                display: 'grid',
                alignSelf: 'start',
                gridTemplateAreas: '"avatar name" "avatar username"',
                gridTemplateColumns: 'auto 1fr',
                columnGap: theme.spacing(2),
              }}
            >
              <Avatar
                isUser
                css={{ gridArea: 'avatar' }}
                disableClick
                name={user?.firstName + ' ' + user?.lastName}
                size='xl'
              />
              <Heading level={2} css={{ gridArea: 'name' }}>
                {user?.firstName + ' ' + user?.lastName}
              </Heading>
              <Text color='light' css={{ gridArea: 'username' }}>
                {user?.username}
              </Text>
            </div>
            <TextLink
              size='medium'
              weight='bold'
              onClick={() => {
                setIsLoading(true);
                closeModal();
                signOut().then(() => {
                  TourGuideHelpers.tourGuideLocalStorageHelper.clearInfo();
                  setIsLoading(false);
                  clearLastVisitedPage();
                  navigate({ to: '/sign-in' });
                });
              }}
            >
              {t('Sign Out')}
            </TextLink>
          </div>
          {nextVersion && <VersionSection nextVersion={nextVersion} />}
        </Section>
        {/* Mid Section */}
        <Section
          css={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            '> :last-child': {
              flexGrow: 1,
            },
          }}
        >
          <Section>{hasPhoneSystemAccess && <DeviceSection closeModal={closeModal} />}</Section>
        </Section>
        {/* Bottom Section */}
        {accountManagerData && (
          <Section>
            <AccountManagerProfile accountManagerData={accountManagerData} />
          </Section>
        )}
        <LinkSection closeModal={closeModal} isLoading={isLoading} />
      </div>
    </>
  );
};

type VersionSectionProps = {
  nextVersion: string | undefined;
};
const VersionSection = ({ nextVersion }: VersionSectionProps) => {
  const { t } = useTranslation('base');
  const shell = useShell();
  const onUpdateClick = () => {
    if (nextVersion) {
      shell?.emit?.('restart', undefined);
    }
  };

  if (!nextVersion) {
    return <></>;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
      <Text color='subdued' size='small' css={{ alignSelf: 'end' }}>
        {t('Version {{currentVersion}}', { currentVersion: shell.version ?? 'Unknown' })}
      </Text>
      <div
        style={{
          borderRadius: theme.borderRadius.medium,
          boxShadow: theme.shadows.floating,
          display: 'grid',
          gridTemplateRows: `repeat(4, minmax(${theme.spacing(2)}, auto))`,
          padding: theme.spacing(1, 2),
        }}
      >
        <Text size='medium' weight='bold' as='span' css={{ gridArea: '1/1' }}>
          {t('A new version is available: {{version}}', { version: nextVersion })}
        </Text>
        <Text size='medium' color='subdued' as='span' css={{ gridArea: '2/1' }}>
          {t('Please restart the app to update')}
        </Text>
        <PrimaryButton css={{ gridArea: '4/1', width: 'max-content' }} onClick={onUpdateClick}>
          {t('Restart Now')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export const Section = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <section
      css={{
        width: '100%',
        padding: theme.spacing(3),
        '&:not(:first-of-type)': {
          borderTop: `1px solid ${theme.colors.neutral40}`,
        },
      }}
      className={className}
    >
      {children}
    </section>
  );
};

const LinkSection = ({ closeModal, isLoading }: { closeModal: () => void; isLoading: boolean }) => {
  const { t } = useTranslation();
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const { FeedbackModalTrigger, FeedbackModalFlow } = useFeedback({
    feedbackId: 'weave-2-dot-0-feedback',
  });
  const { isEnabled: accessibilityModeEnabled } = useAccessibilityModeEnabled('isEnabled');

  const links = [
    // TODO: Uncomment when we have a personal settings page
    // { label: t('Personal Settings'), onClick: () => {}, href: '/' },
    {
      label: t('Weave Help'),
      href: 'https://weavehelp.com/',
      target: 'blank',
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    {
      label: t('Refer Weave'),
      href: 'https://www.getweave.com/refer',
      target: 'blank',
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    {
      label: t('Weave Training Camp'),
      href: 'https://trainingcamp.weavehelp.com/page/new-weave-experience-training',
      target: 'blank',
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    {
      label: t('Accessibility Preferences'),
      hasDot: accessibilityModeEnabled,
      onClick: () => {
        settingsNavigate({ to: '/personal/preferences/accessibility' });
        closeModal();
      },
    },
    {
      label: t('Privacy Policy'),
      href: 'https://www.getweave.com/legal/privacy/',
      target: 'blank',
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    {
      label: t('Personal Preferences'),
      onClick: () => {
        settingsNavigate({ to: '/personal' });
        closeModal();
      },
    },
    {
      label: <FeedbackModalTrigger onClick={closeModal}>{t('Feedback')}</FeedbackModalTrigger>,
    },
  ];
  return (
    <Section css={{ alignSelf: 'end' }}>
      <ContentLoader message={t('Signing out')} show={isLoading} />

      <NakedUl css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        {links.map(({ label, onClick, href, isExternal, hasDot, ...rest }) => (
          <li key={`link-key-${label}`} css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            {typeof label === 'string' ? (
              <TextLink
                href={href}
                onClick={!href ? onClick : undefined}
                css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
                {...rest}
              >
                {label} {isExternal && <Icon name='external-link' size={12} />}
              </TextLink>
            ) : (
              label
            )}
            {hasDot && <Dot color='critical' css={{ position: 'relative' }} />}
          </li>
        ))}
      </NakedUl>
      {FeedbackModalFlow}
    </Section>
  );
};
