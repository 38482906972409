import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { AudioScrubber, SpinningLoader, Text } from '@frontend/design-system';

export const CachedAudioScrubber = ({
  onPlay,
  customWidth,
  filePath,
  mediaId,
  singlePlayer,
  tabIndex = 0,
}: {
  onPlay?: () => void;
  customWidth?: number;
  filePath: string;
  mediaId: string;
  singlePlayer?: boolean;
  tabIndex?: number;
}) => {
  const { t } = useTranslation('phone');
  /**
   * Why fetch the resource instead of loading it directly into the audio component?
   *
   * Doing this allows us to cache the resource, so that the audio component doesn't re-fetch it every time it's rendered.
   */
  const { data, isLoading, isError } = useScopedQuery({
    queryKey: ['cached-audio', mediaId],
    queryFn: async () => {
      const response = await fetch(filePath);
      if (!response.ok) {
        throw new Error('Failed to fetch audio');
      }
      return response.blob().then((myBlob) => {
        const objectURL = URL.createObjectURL(myBlob);
        return objectURL;
      });
    },
    enabled: !!mediaId,
    staleTime: Infinity,
  });

  if (isError) {
    return <Text color='error'>{t('Error loading audio')}</Text>;
  }

  if (isLoading) {
    return <SpinningLoader size='small' />;
  }

  return data ? (
    <AudioScrubber
      autoSize
      onPlay={onPlay}
      singlePlayer={singlePlayer}
      src={data}
      css={{ maxWidth: `${customWidth}px` ?? '100%' }}
      tabIndex={tabIndex}
    />
  ) : null;
};
